import { IoTClient, IoTWorkflowClient } from '@frontend/iot/api';
import { IoTStateName, IoTTriggerName } from '@frontend/iot/types';
import { changeView, clearCommandHistory, endUserSession, removeCommandHistory, updateIoTState } from '@frontend/terminal/shared';
import { UserClient } from '@frontend/user/api';
import { IUser } from '@frontend/user/types';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { NavbarProps } from './navbar.component';

interface ViewProps {
    user: IUser | null;
    showHome: boolean;
    showLogout: boolean;
    showHistory: boolean;
    onHome: () => void;
    onLogout: () => void;
    onHistoryCommand: () => void;
}

const useNavbar = (props: NavbarProps): ViewProps => {
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);
    const [user, changeUser] = useState<IUser | null>(null);

    useEffect(() => {
        if (navigationState.user) UserClient.fetchUser(navigationState.user.accountId, navigationState.user.id).then(changeUser);
        else changeUser(null);
    }, [navigationState.user]);

    const onHome = () => {
        const iot = navigationState.iot;
        const usr = navigationState.user;
        const userHome = navigationState.userHome;

        if (!iot) return;
        if (userHome) {
            dispatch(changeView({ view: userHome, cache: null }));
            dispatch(updateIoTState({ iot_state: IoTStateName.IOT_LOGGED_IN }));
            IoTClient.patchIoT(iot.account_id, iot.id, { state: IoTStateName.IOT_LOGGED_IN });
        } else {
            if (usr !== null) {
                IoTClient.patchIoT(iot.account_id, iot.id, { state: IoTStateName.IOT_HOME }).then(() => {
                    IoTWorkflowClient.postIoTTrigger(iot.account_id, iot.id, { trigger: IoTTriggerName.IOT_USER_IDENTIFIED });
                });
            } else {
                dispatch(changeView(null));
                dispatch(updateIoTState({ iot_state: IoTStateName.IOT_HOME }));
                changeUser(null);
                dispatch(endUserSession());
            }
        }

        dispatch(clearCommandHistory());
    };

    const onLogout = () => {
        dispatch(endUserSession());
        dispatch(changeView(null));
        dispatch(clearCommandHistory());
        dispatch(updateIoTState({ iot_state: IoTStateName.IOT_HOME }));
        changeUser(null);
    };

    const onHistoryCommand = () => {
        if (navigationState.commandHistory.length > 0) {
            navigationState.commandHistory[navigationState.commandHistory.length - 1]();
            dispatch(removeCommandHistory());
        }
    };

    const showHome = useMemo(() => {
        return (
            navigationState.current !== null &&
            navigationState.iot !== null &&
            navigationState.iot.state !== IoTStateName.IOT_LOGGED_IN &&
            navigationState.iot.state !== IoTStateName.IOT_HOME
        );
    }, [navigationState]);

    return {
        showHome: showHome,
        showLogout: navigationState.user !== null,
        showHistory: navigationState.commandHistory.length > 0,
        onHome,
        onLogout,
        onHistoryCommand,
        user
    };
};

export default useNavbar;
