import { PaginatedResponse } from '@frontend/api-utils';

import { PackageStateName } from './package-state';

export interface Package {
    iot_id: string | null;
    user_id: string | null;
    product_id: string | null;
    variant_id: string | null;
    equipment_id: string | null;
    spot_id: string;
    module_id: string;
    slot_id: string;
    quantity: number;
    state: PackageStateName;
    priority: number;
    budget_id: string | null;
    blocking: boolean;
    update_timestamp: string;
    workflow_id: string;
    type: PackageType;
    id: string;
    account_id: string;
    transaction_id: string;
    creation_timestamp: string;
}

export interface CreatePackage {
    iot_id?: string | null;
    user_id?: string | null;
    product_id?: string | null;
    variant_id?: string | null;
    equipment_id?: string | null;
    spot_id?: string;
    module_id?: string;
    slot_id?: string;
    quantity?: number;
    state?: PackageStateName;
    priority?: number;
    budget_id?: string | null;
    blocking?: boolean | null;
    workflow_id?: string;
    type: PackageType;
}

export interface UpdatePackage {
    iot_id?: string | null;
    user_id?: string | null;
    product_id?: string | null;
    variant_id?: string | null;
    equipment_id?: string | null;
    spot_id?: string | null;
    module_id?: string | null;
    slot_id?: string | null;
    quantity?: number | null;
    state?: PackageStateName | null;
    priority?: number | null;
    budget_id?: string | null;
    blocking?: boolean | null;
}

export type PackageListResponse = PaginatedResponse<Package>;

export enum PackageQueryParams {
    IOT_ID = 'iot_id',
    PRODUCT_ID = 'product_id',
    STATE = 'state',
    WORKFLOW_ID = 'workflow_id',
    SPOT_ID = 'spot_id',
    MODULE_ID = 'module_id',
    SLOT_ID = 'slot_id',
    TRANSACTION_ID = 'transaction_id',
    PACKAGE_ID = 'package_id',
    TYPE = 'type'
}

export enum PackageType {
    DROP_OFF = 'drop_off',
    PICK_UP = 'pick_up',
    PASS_THROUGH = 'pass_through'
}

export interface UpdatePackageState {
    state?: PackageStateName;
}
