import { IoTEnumClient } from '@frontend/iot/api';
import { IoTAction, IoTActionName } from '@frontend/iot/types';
import { useEffect, useMemo, useState } from 'react';
import { IoTActionSelectProps } from './iot-action-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: IoTActionName | string;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useIoTActionSelect = (props: IoTActionSelectProps): ViewProps => {
    const [actions, changeActions] = useState<string[]>([]);

    useEffect(() => {
        IoTEnumClient.fetchIoTActions().then(changeActions);
    }, []);

    const options = useMemo(() => {
        if (!actions) return [];
        return actions.map((action) => {
            const found = IoTAction.ALL.find((t) => t.value.toString() === action);
            if (found) return { value: found.value.toString(), label: found.displayName };
            else
                return {
                    value: action,
                    label: action.replace(/_/g, ' ')
                };
        });
    }, [actions]);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useIoTActionSelect;
