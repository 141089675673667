import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreatePackageVariable, PackageVariable, PackageVariableListResponse, UpdatePackageVariable } from '@frontend/package-variables/types';

const endpoint = '/package-api/v1';

export class PackageVariableClient extends APIClient {
    public static async fetchVariables(
        accountId: string,
        transactionId: string,
        packageId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<PackageVariableListResponse> {
        return await this.apiPaginated<PackageVariableListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/variables`,
            queryParams
        );
    }

    public static async postPackageVariable(
        accountId: string,
        transactionId: string,
        packageId: string,
        body: CreatePackageVariable
    ): Promise<PackageVariable> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/variables`, body);
        return await this.handleResponse<PackageVariable>(response);
    }

    public static async fetchAccountTransactionPackageVariable(
        accountId: string,
        transactionId: string,
        packageId: string,
        variableId: string
    ): Promise<PackageVariable> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/variables/${variableId}`);
        return await this.handleResponse<PackageVariable>(response);
    }

    public static async patchAccountTransactionPackageVariable(
        accountId: string,
        transactionId: string,
        packageId: string,
        variableId: string,
        body: UpdatePackageVariable
    ): Promise<PackageVariable> {
        const response = await this.patch(
            `${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/variables/${variableId}`,
            body
        );
        return await this.handleResponse<PackageVariable>(response);
    }

    public static async deleteAccountTransactionPackageVariable(
        accountId: string,
        transactionId: string,
        packageId: string,
        variableId: string
    ): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/variables/${variableId}`);
        return await this.handleVoidResponse(response);
    }
}
