import { Logger } from '@frontend/Logger';
import { useLocalStorageState } from '@frontend/common';
import { PinCodeClient } from '@frontend/package-pin/api';
import { useEffect, useState } from 'react';

import { PinCodeFormProps } from './pin-code-form.component';

interface PinAttempt {
    pin: string;
    timestamp: number;
}

interface ViewProps {
    invalidCode: boolean;
    loading: boolean;
    maxPinLength: number;
    pinValues: string[];
    lockedOutTime: number;
    pinInputEnabled: boolean;
    onInput: (value: string) => void;
    onSubmit: (e: any) => void;
    onBackSpace: () => void;
}

const usePinCodeForm = (props: PinCodeFormProps): ViewProps => {
    const maxPinLength = props.maxPinLength !== undefined ? props.maxPinLength : 4;
    const [isLoading, changeIsLoading] = useState<boolean>(false);
    const [pinAttempts, changePinAttempts] = useLocalStorageState<PinAttempt[]>('term_pin_attempts', []);
    const [lockedOutTime, changeLockedOutTime] = useState<number>(0);
    const [pin, changePin] = useState<string>('');
    const [invalidPin, changeInvalidPin] = useState<boolean>(false);

    useEffect(() => {
        pinCheck(changeLockedOutTime, pinAttempts);
    }, [pinAttempts]);

    const onInput = (value: string) => {
        changePin(pin + value);
    };

    const onBackSpace = () => {
        if (pin.length > 0) {
            changePin(pin.slice(0, -1));
        }
    };
    const onSubmit = (e: any) => {
        e.preventDefault();
        changeIsLoading(true);
        Logger.log('Pin code entered: ', {}, pin);
        PinCodeClient.fetchPinCodes({ code: pin })
            .then((result) => {
                let attempts = [...pinAttempts];
                const validResults = result.results;
                if (validResults.length === 0) {
                    if (attempts.length >= 3) attempts.shift();
                    attempts = [...attempts, { timestamp: Date.now(), pin }];
                    Logger.log('Invalid pin code attempt: ' + pin);
                    changeInvalidPin(true);
                } else {
                    attempts = [];
                    Logger.log('Pin ' + pin + ' was recognized as a valid pin code.');
                    changeInvalidPin(false);
                    props.callback(validResults);
                }
                changePinAttempts(attempts);
            })
            .finally(() => {
                changePin('');
                changeIsLoading(false);
            });
    };

    return {
        invalidCode: invalidPin,
        loading: isLoading,
        maxPinLength,
        pinValues: pin.split(''),
        pinInputEnabled: pin.length < maxPinLength,
        lockedOutTime,
        onInput,
        onSubmit,
        onBackSpace
    };
};

export default usePinCodeForm;

function pinCheck(changeLockedOutTime: React.Dispatch<React.SetStateAction<number>>, pinState: PinAttempt[]) {
    const time = enterPinAllowed(pinState);
    changeLockedOutTime(Math.round(time));
    if (time > 0) {
        setTimeout(() => {
            pinCheck(changeLockedOutTime, pinState);
        }, 1000);
    }
}

function enterPinAllowed(attempts: PinAttempt[]): number {
    if (attempts.length > 2) {
        const time = attempts[2].timestamp - (Date.now() - 60 * 1000);
        if (time > 0) {
            return time / 1000;
        }
    }
    return 0;
}
