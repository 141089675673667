import { PaginatedResponse } from '@frontend/api-utils';
import { Dimension, Location } from '@frontend/common';

export interface Slot {
    number: string | null;
    display: string | null;
    index: number;
    note: string | null;
    location: Location | null;
    absolute: Location | null;
    dimension: Dimension | null;
    type: SlotType | null;
    facing: SlotFacing | null;

    id: string;
    account_id: string;
    spot_id: string;
    module_id: string;

    update_timestamp: string;
    creation_timestamp: string;
}

export interface CreateSlot {
    number?: string | null;
    display?: string | null;
    index?: number;
    note?: string | null;
    location?: Location | null;
    absolute?: Location | null;
    dimension?: Dimension | null;
    type?: SlotType | null;
    facing?: SlotFacing | null;
}

export interface UpdateSlot {
    number?: string | null;
    display?: string | null;
    index?: number | null;
    note?: string | null;
    location?: Location | null;
    absolute?: Location | null;
    dimension?: Dimension | null;
    type?: SlotType | null;
    facing?: SlotFacing | null;
}

export type SlotListResponse = PaginatedResponse<Slot>;

export enum SlotQueryParams {
    account_id = 'account_id',
    SPOT_ID = 'spot_id',
    MODULE_ID = 'module_id',
    FACING = 'facing',
    TYPE = 'type'
}

export enum SlotType {
    DEFAULT = 'default',
    LAUNDRY = 'laundry',
    PASS_THROUGH = 'pass_through'
}

export enum SlotFacing {
    UNSPECIFIED = 'unspecified',
    INSIDE = 'inside',
    OUTSIDE = 'outside'
}
