import { APIClient } from "@frontend/api-utils";
import { IoTStateName, IoTTriggerName, PostTrigger } from "@frontend/iot/types";

const endpoint = '/iot-api/v1';
export class IoTWorkflowClient extends APIClient {
    public static async updateIoTState(accountId: string, iotId: string, state: IoTStateName): Promise<void> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/iots/${iotId}/change-state`, {state: state});
        return await this.handleVoidResponse(response);
    }

    public static async postIoTTrigger(accountId: string, iotId: string, trigger: PostTrigger<IoTTriggerName>): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }
}