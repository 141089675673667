import { Logger } from '@frontend/Logger';
import { Token } from '@frontend/authentication-v2';
import { Device } from '@frontend/device/types';
import { IoT, IoTProvision, IoTStateName } from '@frontend/iot/types';
import { PubSubMessage } from '@frontend/pub-sub';
import { SettingListResponse } from '@frontend/setting/types';
import { UserInterfaceType } from '@frontend/user-interface/types';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UserInterfaceNavigationState {
    provision: IoTProvision | null;
    iot: IoT | null;
    device: Device | null;
    settings: SettingListResponse | null;
    root: string | null;
    current: string | null;
    cache: WorkflowStepTriggeredEvent | PubSubMessage | null;
    user: {
        id: string;
        accountId: string;
        auth: Token;
    } | null;
    history: string[];
    commandHistory: (() => void)[];
    userHome: string | null;
}

const initialState: UserInterfaceNavigationState = {
    provision: null,
    iot: null,
    device: null,
    settings: null,
    root: null,
    current: null,
    cache: null,
    user: null,
    history: [],
    commandHistory: [],
    userHome: null
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setProvisioning: (state, action: PayloadAction<IoTProvision | null>) => {
            state.provision = action.payload;
        },
        setDeviceInfo: (state, action: PayloadAction<{ device?: Device | null; iot?: IoT | null } | null>) => {
            if (action.payload == null) {
                state.iot = null;
                state.device = null;
                return;
            }
            if (action.payload.iot !== undefined) state.iot = action.payload.iot;
            if (action.payload.device !== undefined) state.device = action.payload.device;
        },
        initialiseSettings: (state, action: PayloadAction<SettingListResponse | null>) => {
            state.settings = action.payload;
        },
        initialiseNavigation: (state, action: PayloadAction<string | null>) => {
            if (action.payload == null) {
                state.root = UserInterfaceType.BUTTON_DASHBOARD;
            } else state.root = action.payload;
        },
        changeView: (state, action: PayloadAction<{ view: string; cache: WorkflowStepTriggeredEvent | PubSubMessage | null } | null>) => {
            Logger.log('View changed', {}, action.payload);
            state.current = action.payload?.view || null;
            state.cache = action.payload?.cache || null;
        },
        startUserSession: (state, action: PayloadAction<{ id: string; accountId: string; auth: Token }>) => {
            Logger.log('User session started', {}, action.payload);
            state.user = action.payload;
        },
        endUserSession: (state) => {
            Logger.log('User session ended');
            state.userHome = null;
            state.user = null;
        },
        addCommandHistory: (state, action: PayloadAction<() => void>) => {
            state.commandHistory.push(action.payload);
        },
        removeCommandHistory: (state) => {
            state.commandHistory.pop();
        },
        clearCommandHistory: (state) => {
            state.commandHistory = [];
        },
        updateIoTState: (state, action: PayloadAction<{ iot_state: IoTStateName }>) => {
            if (state.iot) {
                state.iot.state = action.payload.iot_state;
            }
        },
        setUserHome: (state, action: PayloadAction<string | null>) => { 
            state.userHome = action.payload;
        }
    }
});

export const navigationStore = { navigation: navigationSlice.reducer };
export const {
    initialiseNavigation,
    initialiseSettings,
    changeView,
    setProvisioning,
    setDeviceInfo,
    startUserSession,
    endUserSession,
    addCommandHistory,
    removeCommandHistory,
    clearCommandHistory,
    updateIoTState,
    setUserHome
} = navigationSlice.actions;
