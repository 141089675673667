import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum IoTStateName {
    IOT_HOME = 'iot_home',
    IOT_ADMINISTRATION = 'iot_administration',
    IOT_REPLENISHMENT = 'iot_replenishment',
    IOT_PRODUCT_REPLENISHMENT = 'iot_product_replenishment',
    IOT_LEND_EQUIPMENT = 'iot_lend_equipment',
    IOT_RETURN_EQUIPMENT = 'iot_return_equipment',
    IOT_EQUIPMENT_NOT_IN_USE = 'iot_equipment_not_in_use',
    IOT_TRANSACTION_PROCESSING = 'iot_transaction_processing',
    IOT_PACKAGE_PROCESSING = 'iot_package_processing',
    IOT_RECENTLY_USED_PRODUCTS = 'iot_recently_used_products',
    IOT_BROWSE_PRODUCTS = 'iot_browse_products',
    IOT_STOCK_ADJUSTMENT = 'iot_stock_adjustment',
    IOT_STOCK = 'iot_stock',
    IOT_BROWSE_PRODUCTS_BY_CATEGORIES = 'iot_browse_products_by_categories',
    IOT_DROP_OFF_PACKAGE = 'iot_drop_off_package',
    IOT_PICK_UP_PACKAGE = 'iot_pick_up_package',
    IOT_OPEN_DROP_OFF_PACKAGE = 'iot_open_drop_off_package',
    IOT_ON_DUTY_PHARMACIST = 'iot_on_duty_pharmacist',
    IOT_ON_DUTY_PHARMACIST_PICK_UP = 'iot_on_duty_pharmacist_pick_up',
    IOT_ON_DUTY_PHARMACIST_DROP_OFF = 'iot_on_duty_pharmacist_drop_off',
    IOT_ON_DUTY_PHARMACIST_ACTION = 'iot_on_duty_pharmacist_action',
    IOT_ON_DUTY_CUSTOMER = 'iot_on_duty_customer',
    IOT_ON_DUTY_CUSTOMER_ACTION = 'iot_on_duty_customer_action',
    IOT_ON_DUTY_CUSTOMER_PICK_UP = 'iot_on_duty_customer_pick_up',
    IOT_ON_DUTY_CUSTOMER_DROP_OFF = 'iot_on_duty_customer_drop_off',
    IOT_MAINTAIN_EQUIPMENT = 'iot_maintain_equipment',
    IOT_REPORT_EQUIPMENT = 'iot_report_equipment',
    IOT_LOGGED_IN = 'iot_logged_in',
    IOT_PHARMACY_ON_DUTY = 'iot_pharmacy_on_duty'
}

export class IoTState {
    static readonly IOT_HOME = new IoTState(
        IoTStateName.IOT_HOME,
        (
            <FormattedMessage
                id='IoTState.home'
                description='The displayed value for the IoTState home'
                defaultMessage='Home'
            />
        )
    );

    static readonly IOT_ADMINISTRATION = new IoTState(
        IoTStateName.IOT_ADMINISTRATION,
        (
            <FormattedMessage
                id='IoTState.administration'
                description='The displayed value for the IoTState administration'
                defaultMessage='Administration'
            />
        )
    );

    static readonly IOT_REPLENISHMENT = new IoTState(
        IoTStateName.IOT_REPLENISHMENT,
        (
            <FormattedMessage
                id='IoTState.replenishment'
                description='The displayed value for the IoTState replenishment'
                defaultMessage='Replenishment'
            />
        )
    );

    static readonly IOT_PRODUCT_REPLENISHMENT = new IoTState(
        IoTStateName.IOT_PRODUCT_REPLENISHMENT,
        (
            <FormattedMessage
                id='IoTState.product_replenishment'
                description='The displayed value for the IoTState product_replenishment'
                defaultMessage='Product replenishment'
            />
        )
    );

    static readonly IOT_LEND_EQUIPMENT = new IoTState(
        IoTStateName.IOT_LEND_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTState.lend_equipment'
                description='The displayed value for the IoTState lend_equipment'
                defaultMessage='Lend equipment'
            />
        )
    );

    static readonly IOT_RETURN_EQUIPMENT = new IoTState(
        IoTStateName.IOT_RETURN_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTState.return_equipment'
                description='The displayed value for the IoTState return_equipment'
                defaultMessage='Return equipment'
            />
        )
    );

    static readonly IOT_EQUIPMENT_NOT_IN_USE = new IoTState(
        IoTStateName.IOT_EQUIPMENT_NOT_IN_USE,
        (
            <FormattedMessage
                id='IoTState.equipment_not_in_use'
                description='The displayed value for the IoTState equipment_not_in_use'
                defaultMessage='Equipment not in use'
            />
        )
    );

    static readonly IOT_TRANSACTION_PROCESSING = new IoTState(
        IoTStateName.IOT_TRANSACTION_PROCESSING,
        (
            <FormattedMessage
                id='IoTState.transaction_processing'
                description='The displayed value for the IoTState transaction_processing'
                defaultMessage='Transaction processing'
            />
        )
    );

    static readonly IOT_PACKAGE_PROCESSING = new IoTState(
        IoTStateName.IOT_PACKAGE_PROCESSING,
        (
            <FormattedMessage
                id='IoTState.package_processing'
                description='The displayed value for the IoTState package_processing'
                defaultMessage='Package processing'
            />
        )
    );

    static readonly IOT_RECENTLY_USED_PRODUCTS = new IoTState(
        IoTStateName.IOT_RECENTLY_USED_PRODUCTS,
        (
            <FormattedMessage
                id='IoTState.recently_used_products'
                description='The displayed value for the IoTState recently_used_products'
                defaultMessage='Recently used products'
            />
        )
    );

    static readonly IOT_BROWSE_PRODUCTS = new IoTState(
        IoTStateName.IOT_BROWSE_PRODUCTS,
        (
            <FormattedMessage
                id='IoTState.browse_products'
                description='The displayed value for the IoTState browse_products'
                defaultMessage='Browse products'
            />
        )
    );

    static readonly IOT_STOCK_ADJUSTMENT = new IoTState(
        IoTStateName.IOT_STOCK_ADJUSTMENT,
        (
            <FormattedMessage
                id='IoTState.stock_adjustment'
                description='The displayed value for the IoTState stock_adjustment'
                defaultMessage='Stock adjustment'
            />
        )
    );

    static readonly IOT_STOCK = new IoTState(
        IoTStateName.IOT_STOCK,
        (
            <FormattedMessage
                id='IoTState.stock'
                description='The displayed value for the IoTState stock'
                defaultMessage='Stock'
            />
        )
    );

    static readonly IOT_BROWSE_PRODUCTS_BY_CATEGORIES = new IoTState(
        IoTStateName.IOT_BROWSE_PRODUCTS_BY_CATEGORIES,
        (
            <FormattedMessage
                id='IoTState.browse_products_by_categories'
                description='The displayed value for the IoTState browse_products_by_categories'
                defaultMessage='Browse products by categories'
            />
        )
    );

    static readonly IOT_DROP_OFF_PACKAGE = new IoTState(
        IoTStateName.IOT_DROP_OFF_PACKAGE,
        (
            <FormattedMessage
                id='IoTState.drop_off_package'
                description='The displayed value for the IoTState drop_off_package'
                defaultMessage='Drop off package'
            />
        )
    );

    static readonly IOT_PICK_UP_PACKAGE = new IoTState(
        IoTStateName.IOT_PICK_UP_PACKAGE,
        (
            <FormattedMessage
                id='IoTState.pick_up_package'
                description='The displayed value for the IoTState pick_up_package'
                defaultMessage='Pick up package'
            />
        )
    );

    static readonly IOT_OPEN_DROP_OFF_PACKAGE = new IoTState(
        IoTStateName.IOT_OPEN_DROP_OFF_PACKAGE,
        (
            <FormattedMessage
                id='IoTState.open_drop_off_package'
                description='The displayed value for the IoTState open_drop_off_package'
                defaultMessage='Open drop off package'
            />
        )
    );

    static readonly IOT_ON_DUTY_PHARMACIST = new IoTState(
        IoTStateName.IOT_ON_DUTY_PHARMACIST,
        (
            <FormattedMessage
                id='IoTState.on_duty_pharmacist'
                description='The displayed value for the IoTState on_duty_pharmacist'
                defaultMessage='On duty pharmacist'
            />
        )
    );
    static readonly IOT_MAINTAIN_EQUIPMENT = new IoTState(
        IoTStateName.IOT_MAINTAIN_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTState.maintain_equipment'
                description='The displayed value for the IoTState maintain_equipment'
                defaultMessage='Maintain equipment'
            />
        )
    );

    static readonly IOT_ON_DUTY_PHARMACIST_PICK_UP = new IoTState(
        IoTStateName.IOT_ON_DUTY_PHARMACIST_PICK_UP,
        (
            <FormattedMessage
                id='IoTState.on_duty_pharmacist_pick_up'
                description='The displayed value for the IoTState on_duty_pharmacist_pick_up'
                defaultMessage='On duty pharmacist pick up'
            />
        )
    );

    static readonly IOT_ON_DUTY_PHARMACIST_DROP_OFF = new IoTState(
        IoTStateName.IOT_ON_DUTY_PHARMACIST_DROP_OFF,
        (
            <FormattedMessage
                id='IoTState.on_duty_pharmacist_drop_off'
                description='The displayed value for the IoTState on_duty_pharmacist_drop_off'
                defaultMessage='On duty pharmacist drop off'
            />
        )
    );

    static readonly IOT_ON_DUTY_CUSTOMER = new IoTState(
        IoTStateName.IOT_ON_DUTY_CUSTOMER,
        (
            <FormattedMessage
                id='IoTState.on_duty_customer'
                description='The displayed value for the IoTState on_duty_customer'
                defaultMessage='On duty customer'
            />
        )
    );

    static readonly IOT_ON_DUTY_CUSTOMER_ACTION = new IoTState(
        IoTStateName.IOT_ON_DUTY_CUSTOMER_ACTION,
        (
            <FormattedMessage
                id='IoTState.on_duty_customer_action'
                description='The displayed value for the IoTState on_duty_customer_action'
                defaultMessage='On duty customer action'
            />
        )
    );

    static readonly IOT_ON_DUTY_CUSTOMER_PICK_UP = new IoTState(
        IoTStateName.IOT_ON_DUTY_CUSTOMER_PICK_UP,
        (
            <FormattedMessage
                id='IoTState.on_duty_customer_pick_up'
                description='The displayed value for the IoTState on_duty_customer_pick_up'
                defaultMessage='On duty customer pick up'
            />
        )
    );

    static readonly IOT_ON_DUTY_CUSTOMER_DROP_OFF = new IoTState(
        IoTStateName.IOT_ON_DUTY_CUSTOMER_DROP_OFF,
        (
            <FormattedMessage
                id='IoTState.on_duty_customer_drop_off'
                description='The displayed value for the IoTState on_duty_customer_drop_off'
                defaultMessage='On duty customer drop off'
            />
        )
    );
    static readonly IOT_REPORT_EQUIPMENT = new IoTState(
        IoTStateName.IOT_REPORT_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTState.report_equipment'
                description='The displayed value for the IoTState report_equipment'
                defaultMessage='Report equipment'
            />
        )
    );

    static readonly IOT_LOGGED_IN = new IoTState(
        IoTStateName.IOT_LOGGED_IN,
        (
            <FormattedMessage
                id='IoTState.logged_in'
                description='The displayed value for the IoTState logged_in'
                defaultMessage='Logged in'
            />
        )
    );

    static readonly IOT_PHARMACY_ON_DUTY = new IoTState(
        IoTStateName.IOT_PHARMACY_ON_DUTY,
        (
            <FormattedMessage
                id='IoTState.pharmacy_on_duty'
                description='The displayed value for the IoTState pharmacy_on_duty'
                defaultMessage='Pharmacy on duty'
            />
        )
    );

    static readonly IOT_ON_DUTY_PHARMACIST_ACTION = new IoTState(
        IoTStateName.IOT_ON_DUTY_PHARMACIST_ACTION,
        (
            <FormattedMessage
                id='IoTState.on_duty_pharmacist_action'
                description='The displayed value for the IoTState on_duty_pharmacist_action'
                defaultMessage='On duty pharmacist action'
            />
        )
    );

    static readonly ALL: IoTState[] = [
        IoTState.IOT_HOME,
        IoTState.IOT_ADMINISTRATION,
        IoTState.IOT_REPLENISHMENT,
        IoTState.IOT_PRODUCT_REPLENISHMENT,
        IoTState.IOT_LEND_EQUIPMENT,
        IoTState.IOT_RETURN_EQUIPMENT,
        IoTState.IOT_EQUIPMENT_NOT_IN_USE,
        IoTState.IOT_TRANSACTION_PROCESSING,
        IoTState.IOT_PACKAGE_PROCESSING,
        IoTState.IOT_RECENTLY_USED_PRODUCTS,
        IoTState.IOT_BROWSE_PRODUCTS,
        IoTState.IOT_STOCK_ADJUSTMENT,
        IoTState.IOT_STOCK,
        IoTState.IOT_BROWSE_PRODUCTS_BY_CATEGORIES,
        IoTState.IOT_DROP_OFF_PACKAGE,
        IoTState.IOT_PICK_UP_PACKAGE,
        IoTState.IOT_OPEN_DROP_OFF_PACKAGE,
        IoTState.IOT_ON_DUTY_PHARMACIST,
        IoTState.IOT_ON_DUTY_PHARMACIST_PICK_UP,
        IoTState.IOT_ON_DUTY_PHARMACIST_DROP_OFF,
        IoTState.IOT_ON_DUTY_CUSTOMER,
        IoTState.IOT_ON_DUTY_CUSTOMER_ACTION,
        IoTState.IOT_ON_DUTY_CUSTOMER_PICK_UP,
        IoTState.IOT_ON_DUTY_CUSTOMER_DROP_OFF,
        IoTState.IOT_MAINTAIN_EQUIPMENT,
        IoTState.IOT_REPORT_EQUIPMENT,
        IoTState.IOT_LOGGED_IN,
        IoTState.IOT_PHARMACY_ON_DUTY,
        IoTState.IOT_ON_DUTY_PHARMACIST_ACTION
    ];

    private constructor(public readonly value: IoTStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: IoTStateName): IoTState | undefined {
        return IoTState.ALL.find((state) => state.value === value);
    }
}
