// import { useAppSelector } from '@frontend/common';
// import { fetchSpotModules, moduleStore } from '@frontend/module/redux';
import { Module } from '@frontend/module/types';
import { Spot } from '@frontend/spot/types';

// import { useEffect, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import { SpotLayoutProps } from './spot-layout.component';

interface ViewProps {
    spot?: Spot;
    data: Module[];
}

/**
 * @deprecated
 */
const useSpotLayout = (props: SpotLayoutProps): ViewProps => {
    //const spotState = useAppSelector(useSelector, spotStore);
    // const moduleState = useAppSelector(useSelector, moduleStore);
    // const spotId = typeof props.spot === 'string' ? props.spot : props.spot.id;

    // const data = useMemo(() => {
    //     if (!moduleState || !moduleState.spotModules || !moduleState.spotModules[spotId]) return [];
    //     else return moduleState.spotModules[spotId].results;
    // }, [moduleState]);

    // const spot = useMemo(() => {
    //     if (typeof props.spot !== 'string') return props.spot;
    //     else {
    //         const found = spotState.unordered.find((s) => s.id == spotId);
    //         if (found) return found;
    //         else props.dispatch(fetchSpot(spotId));
    //     }
    //     return undefined;
    // }, [spotState]);

    // useEffect(() => {
    //     props.dispatch(fetchSpotModules({ spotId: spotId, queryParams: { index: '0', size: '100' } }));
    // }, []);

    return {
        spot: undefined,
        data: []
    };
};

export default useSpotLayout;
