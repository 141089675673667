import { useAppSelector } from '@frontend/common';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageType } from '@frontend/package/types';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { OnDutyOpenSlotButtonProps } from './on-duty-open-slot.component';

interface ViewProps {
    onClick: () => void;
}

const useOnDutyOpenSlotButton = (props: OnDutyOpenSlotButtonProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [pkg, changePkg] = useState<Package | null>(null);

    useEffect(() => {
        const spotId = navigationState.settings?.results.find((setting) => setting.key === 'spot_id')?.value;
        PackageClient.fetchPackages({ type: PackageType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((result) => {
            if (result.results.length === 1) {
                changePkg(result.results[0]);
            }
        });
    }, []);

    const onClick = () => {
        if (!navigationState.iot || !pkg) return;
        PackageWorkflowClient.updatePackageState(pkg.account_id, pkg.transaction_id, pkg.id, PackageStateName.PACKAGE_PASS_THROUGH_STARTED).then(() =>
            props.callback()
        );
    };

    return { onClick };
};

export default useOnDutyOpenSlotButton;
