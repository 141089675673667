import { APBClient } from '@frontend/apb/api';
import { PharmacyOnDuty } from '@frontend/apb/types';
import { useAppSelector } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    pharmacistList: PharmacyOnDuty[] | null;
    isLoading: boolean;
}
const useOnDutyList = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [pharmacistList, changePharmacistList] = useState<PharmacyOnDuty[] | null>(null);
    useEffect(() => {
        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_PHARMACY_ON_DUTY }).then((iot) => {
                props.dispatch(updateIoTState({ iot_state: iot.state }));
            });
        }
        APBClient.getPharmacyOnDuty().then(changePharmacistList).catch(ErrorHandler.handleError);
    }, []);

    return {
        pharmacistList,
        isLoading: !pharmacistList
    };
};

export default useOnDutyList;
