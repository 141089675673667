import { useAppSelector } from '@frontend/common';
import { IoTEventListener } from '@frontend/iot/events';
import { navigationStore } from '@frontend/terminal/shared';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SlotStatusProps } from './slot-status.component';

interface ViewProps {
    view: View;
}

const useSlotStatus = (props: SlotStatusProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [view, changeView] = useState<View>(View.OPENING);

    const callback = (event: MessageEvent<string>) => {
        const payload = JSON.parse(event.data) as { message: string; data: any };
        if (payload.message === 'inform') {
            const data = payload.data as WorkflowStepTriggeredEvent;
            if (data.data.trigger) {
                if (data.data.trigger === 'iot_slot_opened') changeView(View.OPENED);
                if (data.data.trigger === 'iot_slot_closed') {
                    changeView(View.CLOSED);
                    props.closedCallBack && props.closedCallBack();
                }
            }
        }
    };

    useEffect(() => {
        if (navigationState.iot) {
            IoTEventListener.getInstance(props.dispatch).addCallback('slot_processing', callback);
        }

        return () => {
            IoTEventListener.getInstance(props.dispatch).removeCallback('slot_processing');
        };
    }, []);

    return {
        view
    };
};

export default useSlotStatus;

export enum View {
    OPENING = 'opening',
    OPENED = 'opened',
    CLOSED = 'closed'
}
