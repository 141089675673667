import { useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageType } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    slot: Slot | null;
    isSlotOpening: boolean;
    changeIsSlotOpening: React.Dispatch<React.SetStateAction<boolean>>;
    isSlotOpen: boolean;
}

const useOnDutyPharmacistDropOff = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [isSlotOpening, changeIsSlotOpening] = useState<boolean>(false);
    const [isSlotOpen, changeIsSlotOpen] = useState<boolean>(false);
    const [pkg, changePkg] = useState<Package | null>(null);

    useEffect(() => {
        const spotId = navigationState.settings?.results.find((setting) => setting.key === 'spot_id')?.value;
        SlotClient.fetchSlots({ type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((res) => {
            if (res.results.length === 1) {
                changeSlot(res.results[0]);
            }
        });
        PackageClient.fetchPackages({
            type: PackageType.PASS_THROUGH,
            spot_id: spotId as string | undefined,
            state: PackageStateName.PACKAGE_PASS_THROUGH_PENDING
        }).then((result) => {
            if (result.results.length === 1) {
                changePkg(result.results[0]);
            }
        });

        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_PHARMACIST_DROP_OFF });
        }
    }, []);

    useEffect(() => {
        if (!navigationState.iot || !pkg) return;
        PackageWorkflowClient.updatePackageState(pkg.account_id, pkg.transaction_id, pkg.id, PackageStateName.PACKAGE_PASS_THROUGH_STARTED);
    }, [pkg]);

    return {
        slot,
        changeIsSlotOpening,
        isSlotOpening,
        isSlotOpen
    };
};

export default useOnDutyPharmacistDropOff;
