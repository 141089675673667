import { EntityType, useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageType } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { TransactionStateName, TransactionType } from '@frontend/transaction/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../../types/user-interface';

interface ViewProps {
    slot: Slot | null;
    pkg: Package | null;
}

const useOnDutyPharmacistDropOff = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [pkg, changePkg] = useState<Package | null>(null);
    const [transationWasCreated, changeTransactionWasCreated] = useState<boolean>(false);

    useEffect(() => {
        if (!navigationState.iot) return;
        const spotId = navigationState.iot.spot_id;
        SlotClient.fetchSlots({ type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((res) => {
            if (res.results.length === 1) {
                changeSlot(res.results[0]);
            }
        });

        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_PHARMACIST_DROP_OFF });
            props.dispatch(updateIoTState({ iot_state: IoTStateName.IOT_ON_DUTY_PHARMACIST_DROP_OFF }));
        }
    }, []);

    useEffect(() => {
        if (!navigationState.iot || !slot || !!transationWasCreated) return;
        const spotId = navigationState.iot.spot_id;
        changeTransactionWasCreated(true);
        TransactionClient.fetchTransactions({
            type: TransactionType.PASS_THROUGH,
            account_id: navigationState.iot.account_id,
            spot_id: spotId as string | undefined,
            state: TransactionStateName.TRANSACTION_PROCESSING
        }).then((transactions) => {
            if (transactions.results.length === 0) {
                TransactionClient.postTransaction(navigationState.iot!.account_id, {
                    type: TransactionType.PASS_THROUGH,
                    iot_id: navigationState.iot!.id,
                    workflow_id: props.userInterface.data.transactionWorkflowId
                }).then((transaction) => {
                    PackageClient.postPackage(transaction.account_id, transaction.id, {
                        type: PackageType.PASS_THROUGH,
                        workflow_id: props.userInterface.data.packageWorkflowId,
                        spot_id: slot.spot_id,
                        module_id: slot.module_id,
                        slot_id: slot.id,
                        priority: 1
                    }).then((_package) => {
                        const variables = [];
                        const user = navigationState.user;
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                workflow_id: _package.workflow_id,
                                name: 'from_entity_id',
                                type: 'entity_id',
                                value: user!.id
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                workflow_id: _package.workflow_id,
                                name: 'from_entity_type',
                                type: 'entity_type',
                                value: EntityType.USER
                            })
                        );

                        Promise.all(variables).then(() => {
                            TransactionWorkflowClient.updateTransactionState(
                                transaction.account_id,
                                transaction.id,
                                TransactionStateName.TRANSACTION_PROCESSING
                            ).then(() => {
                                changePkg(_package);
                            });
                        });
                    });
                });
            } else {
                PackageClient.fetchPackages({
                    type: PackageType.PASS_THROUGH,
                    spot_id: spotId as string | undefined,
                    state: PackageStateName.PACKAGE_PASS_THROUGH_PENDING,
                    transaction_id: transactions.results[0].id
                }).then((result) => {
                    if (result.results.length === 1) {
                        changePkg(result.results[0]);
                    }
                });
            }
        });
    }, [slot]);

    useEffect(() => {
        if (!navigationState.iot || !pkg) return;
        PackageWorkflowClient.updatePackageState(pkg.account_id, pkg.transaction_id, pkg.id, PackageStateName.PACKAGE_PASS_THROUGH_STARTED);
    }, [pkg]);

    return {
        slot,
        pkg
    };
};

export default useOnDutyPharmacistDropOff;
