import { useAppSelector } from '@frontend/common';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageType } from '@frontend/package/types';
import { changeView, navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    onConfirm: () => void;
    onCancel: () => void;
}

const useResetPharmacy = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [_packages, changePackages] = useState<Package[] | undefined>(undefined);

    useEffect(() => {
        if (!navigationState.iot) return;
        const spotId = navigationState.settings?.results.find((s) => s.key === 'spot_id')?.value;

        PackageClient.fetchPackages({
            account_id: navigationState.iot.account_id,
            spot_id: spotId as string | undefined,
            type: PackageType.PASS_THROUGH,
            state: [PackageStateName.PACKAGE_PASS_THROUGH_PENDING, PackageStateName.PACKAGE_PASS_THROUGH_STARTED]
        }).then((pack) => {
            changePackages(pack.results);
        });
    }, []);

    const onConfirm = async () => {
        if (!_packages) return;
        const packages: Promise<Package>[] = [];
        _packages.forEach((p) => {
            packages.push(PackageWorkflowClient.updatePackageState(p.account_id, p.transaction_id, p.id, PackageStateName.PACKAGE_PASS_THROUGH_DONE));
        });

        await Promise.all(packages).then(() => onCancel());
    };

    const onCancel = () => {
        const userHome = navigationState.userHome;
        const defaultView = navigationState.settings?.results.find((s) => s.key === 'default_user_interface_id');
        if (!userHome) {
            props.dispatch(changeView({ view: defaultView!.value as string, cache: null }));
        } else {
            props.dispatch(changeView({ view: userHome, cache: null }));
        }
    };

    return {
        onCancel,
        onConfirm
    };
};

export default useResetPharmacy;
