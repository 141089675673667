import { EntityType } from '@frontend/common';
import { WorkflowTrigger, WorkflowTriggerName } from '@frontend/workflow/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum IoTTriggerName {
    IOT_STATE_CHANGED = 'iot_state_changed',
    IOT_USER_IDENTIFIED = 'iot_user_identified',
    IOT_EQUIPMENT_IDENTIFIED = 'iot_equipment_identified',
    IOT_TRANSACTION_PROCESSING = 'iot_transaction_processing',
    IOT_PACKAGE_PROCESSING = 'iot_package_processing',
    IOT_PACKAGE_PROCESSED = 'iot_package_processed',
    IOT_TRANSACTION_PROCESSED = 'iot_transaction_processed',
    IOT_PACKAGE_IDENTIFIED = 'iot_package_identified',
    IOT_SLOT_OPENED = 'iot_slot_opened',
    IOT_SLOT_CLOSED = 'iot_slot_closed',
    IOT_ADJUST_STOCK = 'iot_adjust_stock',
    IOT_ARBITRATION = 'iot_arbitration',
    IOT_VIEW_STOCK = 'iot_view_stock',
    IOT_ADMINISTER = 'iot_administer',
    IOT_REPLENISH = 'iot_replenish',
    IOT_DROP_STATION = 'iot_drop_station',
    IOT_OPEN_HATCH = 'iot_open_hatch',
    IOT_ACTIVATE_HATCH = 'iot_activate_hatch',
    IOT_TWIN_SLOT_CLOSED = 'iot_twin_slot_closed',
    IOT_MAINTAIN_EQUIPMENT = 'iot_maintain_equipment',
    IOT_REPORT_EQUIPMENT = 'iot_report_equipment',
    IOT_NO_MATCHING_IDENTIFICATION_FOUND = 'iot_no_matching_identification_found'
}

export class IoTTrigger extends WorkflowTrigger {
    static readonly IOT_STATE_CHANGED = new IoTTrigger(
        IoTTriggerName.IOT_STATE_CHANGED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_state_changed'
                description='The displayed value for the IoTTrigger iot_state_changed'
                defaultMessage='IOT state changed'
            />
        )
    );

    static readonly IOT_USER_IDENTIFIED = new IoTTrigger(
        IoTTriggerName.IOT_USER_IDENTIFIED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_user_identified'
                description='The displayed value for the IoTTrigger iot_user_identified'
                defaultMessage='IOT user identified'
            />
        )
    );

    static readonly IOT_EQUIPMENT_IDENTIFIED = new IoTTrigger(
        IoTTriggerName.IOT_EQUIPMENT_IDENTIFIED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_equipment_identified'
                description='The displayed value for the IoTTrigger iot_equipment_identified'
                defaultMessage='IOT equipment identified'
            />
        )
    );

    static readonly IOT_TRANSACTION_PROCESS = new IoTTrigger(
        IoTTriggerName.IOT_TRANSACTION_PROCESSING,
        (
            <FormattedMessage
                id='IoTTrigger.iot_transaction_process'
                description='The displayed value for the IoTTrigger iot_transaction_process'
                defaultMessage='IOT transaction process'
            />
        )
    );

    static readonly IOT_PACKAGE_PROCESSING = new IoTTrigger(
        IoTTriggerName.IOT_PACKAGE_PROCESSING,
        (
            <FormattedMessage
                id='IoTTrigger.iot_package_process'
                description='The displayed value for the IoTTrigger iot_package_process'
                defaultMessage='IOT package process'
            />
        )
    );

    static readonly IOT_PACKAGE_PROCESSED = new IoTTrigger(
        IoTTriggerName.IOT_PACKAGE_PROCESSED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_package_processed'
                description='The displayed value for the IoTTrigger iot_package_processed'
                defaultMessage='IOT package processed'
            />
        )
    );

    static readonly IOT_TRANSACTION_PROCESSED = new IoTTrigger(
        IoTTriggerName.IOT_TRANSACTION_PROCESSED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_transaction_processed'
                description='The displayed value for the IoTTrigger iot_transaction_processed'
                defaultMessage='IOT transaction processed'
            />
        )
    );

    static readonly IOT_PACKAGE_IDENTIFIED = new IoTTrigger(
        IoTTriggerName.IOT_PACKAGE_IDENTIFIED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_package_identified'
                description='The displayed value for the IoTTrigger iot_package_identified'
                defaultMessage='IOT package identified'
            />
        )
    );

    static readonly IOT_SLOT_OPENED = new IoTTrigger(
        IoTTriggerName.IOT_SLOT_OPENED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_slot_opened'
                description='The displayed value for the IoTTrigger iot_slot_opened'
                defaultMessage='IOT SLOT opened'
            />
        )
    );

    static readonly IOT_SLOT_CLOSED = new IoTTrigger(
        IoTTriggerName.IOT_SLOT_CLOSED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_slot_closed'
                description='The displayed value for the IoTTrigger iot_slot_closed'
                defaultMessage='IOT SLOT closed'
            />
        )
    );

    static readonly IOT_ADJUST_STOCK = new IoTTrigger(
        IoTTriggerName.IOT_ADJUST_STOCK,
        (
            <FormattedMessage
                id='IoTTrigger.iot_adjust_stock'
                description='The displayed value for the IoTTrigger iot_adjust_stock'
                defaultMessage='IOT adjust stock'
            />
        )
    );


    
    static readonly IOT_ARBITRATION = new IoTTrigger(
        IoTTriggerName.IOT_ARBITRATION,
        (
            <FormattedMessage
                id='IoTTrigger.arbitration'
                description='The displayed value for the IoTTrigger arbitration'
                defaultMessage='Arbitration'
            />
        )
    );

    static readonly IOT_VIEW_STOCK = new IoTTrigger(
        IoTTriggerName.IOT_VIEW_STOCK,
        (
            <FormattedMessage
                id='IoTTrigger.iot_view_stock'
                description='The displayed value for the IoTTrigger iot_view_stock'
                defaultMessage='IOT view stock'
            />
        )
    );

    static readonly IOT_ADMINISTER = new IoTTrigger(
        IoTTriggerName.IOT_ADMINISTER,
        (
            <FormattedMessage
                id='IoTTrigger.iot_administer'
                description='The displayed value for the IoTTrigger iot_administer'
                defaultMessage='IOT administer'
            />
        )
    );

    static readonly IOT_REPLENISH = new IoTTrigger(
        IoTTriggerName.IOT_REPLENISH,
        (
            <FormattedMessage
                id='IoTTrigger.iot_replenish'
                description='The displayed value for the IoTTrigger iot_replenish'
                defaultMessage='IOT replenish'
            />
        )
    );

    static readonly IOT_DROP_STATION = new IoTTrigger(
        IoTTriggerName.IOT_DROP_STATION,
        (
            <FormattedMessage
                id='IoTTrigger.iot_drop_station'
                description='The displayed value for the IoTTrigger iot_drop_station'
                defaultMessage='IOT DropStation'
            />
        )
    );

    static readonly IOT_OPEN_HATCH = new IoTTrigger(
        IoTTriggerName.IOT_OPEN_HATCH,
        (
            <FormattedMessage
                id='IoTTrigger.iot_open_hatch'
                description='The displayed value for the IoTTrigger iot_open_hatch'
                defaultMessage='IOT open hatch'
            />
        )
    );

    static readonly IOT_MAINTAIN_EQUIPMENT = new IoTTrigger(
        IoTTriggerName.IOT_MAINTAIN_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTTrigger.iot_maintain_equipment'
                description='The displayed value for the IoTTrigger iot_maintain_equipment'
                defaultMessage='IOT maintain equipment'
            />
        )
    );

    static readonly IOT_ACTIVATE_HATCH = new IoTTrigger(
        IoTTriggerName.IOT_ACTIVATE_HATCH,
        (
            <FormattedMessage
                id='IoTTrigger.iot_activate_hatch'
                description='The displayed value for the IoTTrigger iot_activate_hatch'
                defaultMessage='IOT activate hatch'
            />
        )
    );

    static readonly IOT_TWIN_SLOT_CLOSED = new IoTTrigger(
        IoTTriggerName.IOT_TWIN_SLOT_CLOSED,
        (
            <FormattedMessage
                id='IoTTrigger.iot_twin_slot_closed'
                description='The displayed value for the IoTTrigger iot_twin_slot_closed'
                defaultMessage='IOT twin SLOT closed'
            />
        )
    );
    static readonly IOT_REPORT_EQUIPMENT = new IoTTrigger(
        IoTTriggerName.IOT_REPORT_EQUIPMENT,
        (
            <FormattedMessage
                id='IoTTrigger.iot_report_equipment'
                description='The displayed value for the IoTTrigger iot_report_equipment'
                defaultMessage='IOT report equipment'
            />
        )
    );

    static readonly IOT_NO_MATCHING_IDENTIFICATION_FOUND = new IoTTrigger(
        IoTTriggerName.IOT_NO_MATCHING_IDENTIFICATION_FOUND,
        (
            <FormattedMessage
                id='IoTTrigger.iot_no_matching_identification_found'
                description='The displayed value for the IoTTrigger iot_no_matching_identification_found'
                defaultMessage='IOT no matching identification found'
            />
        )
    );

    static override readonly ALL = [
        IoTTrigger.IOT_STATE_CHANGED,
        IoTTrigger.IOT_USER_IDENTIFIED,
        IoTTrigger.IOT_EQUIPMENT_IDENTIFIED,
        IoTTrigger.IOT_TRANSACTION_PROCESS,
        IoTTrigger.IOT_PACKAGE_PROCESSING,
        IoTTrigger.IOT_PACKAGE_PROCESSED,
        IoTTrigger.IOT_TRANSACTION_PROCESSED,
        IoTTrigger.IOT_PACKAGE_IDENTIFIED,
        IoTTrigger.IOT_SLOT_OPENED,
        IoTTrigger.IOT_SLOT_CLOSED,
        IoTTrigger.IOT_ADJUST_STOCK,
        IoTTrigger.IOT_ARBITRATION,
        IoTTrigger.IOT_VIEW_STOCK,
        IoTTrigger.IOT_ADMINISTER,
        IoTTrigger.IOT_REPLENISH,
        IoTTrigger.IOT_DROP_STATION,
        IoTTrigger.IOT_OPEN_HATCH,
        IoTTrigger.IOT_ACTIVATE_HATCH,
        IoTTrigger.IOT_TWIN_SLOT_CLOSED,
        IoTTrigger.IOT_MAINTAIN_EQUIPMENT,
        IoTTrigger.IOT_REPORT_EQUIPMENT,
        IoTTrigger.IOT_NO_MATCHING_IDENTIFICATION_FOUND,
        ...super.ALL
    ];

    static override getByValue(trigger: IoTTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((t) => t.value === trigger);
    }
}

export interface PostTrigger<T> {
    source?: TriggerEntity;
    target?: TriggerEntity;
    trigger: T;
}

export interface TriggerEntity {
    entity_type: EntityType;
    entity_id: string;
}
