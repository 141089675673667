import { CommonMessage } from '@frontend/lang';
import { Package } from '@frontend/package/types';
import { SlotBadge } from '@frontend/slot/badge';
import { WorkflowVariableBadge } from '@frontend/workflow-variable/badge';
import React from 'react';

import { PackageInfo } from '../drop-off/pudo-drop-list.controller';

export interface PackageCardProps {
    packageInfo: PackageInfo;
    isSelected?: boolean;
    onDropOff?: (_package: Package) => void;
    onPickUp?: (_package: Package) => void;
}

const PackageCard = (props: PackageCardProps) => {
    return (
        <div
            className={`card ms-3 mt-3 ${props.isSelected ? 'w-100' : 'w-48'}`}
            key={props.packageInfo.package.id}>
            <div className='card-body'>
                <h5 className='card-title mb-3'>{props.packageInfo.transaction.tracking_number}</h5>
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-row justify-content-between mb-3'>
                        <p className='text-lg font-weight-bolder'>{CommonMessage.OBJECTS.SLOT.DEFAULT}</p>
                        <SlotBadge
                            className='badge badge-secondary text-lg'
                            id={props.packageInfo.package.slot_id}
                        />
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <p className='text-lg font-weight-bolder'>{CommonMessage.OBJECTS.TRANSACTION.RECEIVER}</p>
                        {props.packageInfo.variables
                            .filter((variable) => variable.name !== 'sender')
                            .map((variable) => (
                                <WorkflowVariableBadge
                                    type={variable.type}
                                    value={variable.value}
                                    key={variable.id}
                                    className='badge badge-secondary text-lg'
                                />
                            ))}
                    </div>
                </div>
            </div>
            <div className='card-footer'>
                <button
                    className='btn btn-success w-100'
                    onClick={() => {
                        props.onDropOff && props.onDropOff(props.packageInfo.package);
                        props.onPickUp && props.onPickUp(props.packageInfo.package);
                    }}>
                    {props.onDropOff ? CommonMessage.BUTTONS.DROP_OFF : CommonMessage.BUTTONS.PICK_UP}
                </button>
            </div>
        </div>
    );
};

export default PackageCard;
