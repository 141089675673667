import { VERSION } from '../../../version';

const ID = 'footer';

const Footer = () => {
    return (
        <div
            id={ID}
            className='fixed-bottom d-flex flex-row justify-content-between align-items-center w-100 min-height-20'
            onClick={() => window.location.reload()}>
            <span>{VERSION}</span>
        </div>
    );
};

export default Footer;
