import { EntityType } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useContact:request': CustomEvent<string>;
    }
}

const REQUEST_CONTACT_EVENT = 'useContact:request';
const STORAGE_KEY = 'CTS_REQ';

export function contactRequest(contactId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_CONTACT_EVENT, { detail: contactId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ContactResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_CONTACT_EVENT, EntityType.CONTACT, (ids) => ContactClient.resolveContacts(ids), props.dispatch);
    return <></>;
}
