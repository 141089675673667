import { DefaultSelectProps, SelectInput } from '@frontend/basic-forms';
import { IoTBrand } from '@frontend/iot/types';
import React from 'react';

const ID = 'iot-brand-select';
export const IoTBrandSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            isClearable={props.isClearable}
            className={props.className}
            options={Object.values(IoTBrand).map((c) => ({ label: c, value: c }))}
            value={props.value}
            label={props.label || 'IoT brand'}
            submitted={props.submitted}
            required={props.required}
            onChange={(value) => props.onChange && props.onChange(value as { label: string; value: string })}
        />
    );
};
