import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import useOnDutyPharmacist from './on-duty-pharmacist.controller';

export const OnDutyPharmacist = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyPharmacist(props);
    return (
        <div className='d-flex flex-column align-content-center h-100'>
            <div className='d-flex flex-row justify-content-center'>
                <h4>
                    {
                        <FormattedMessage
                            id='WorkflowStepTrigger.on_duty_pharmacist'
                            description='Wait message for pharmacist'
                            defaultMessage='Please wait, external hatch is open'
                        />
                    }
                </h4>
            </div>
            {/* Implement this when cancel is on the BE */}
            {/* <div className='d-flex flex-row justify-content-center'>
                <div className='w-md-50 w-xl-25 ratio ratio-1x1'>
                    <div className='p-3'>
                        <div
                            className={`card shadow-lg overflow-hidden h-100`}
                            onClick={() => undefined}>
                            <div className='d-flex flex-column h-100'>
                                <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                                    <FaXmark
                                        size={190}
                                        className='text-primary'
                                    />
                                </div>
                                <div className='d-flex align-items-center justify-content-center h-25 bg-primary'>
                                    <h4 className='text-white placeholder-glow'>
                                        <strong>{CommonMessage.BUTTONS.CANCEL}</strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};
