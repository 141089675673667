import { APIClient, PaginatedResponse } from "@frontend/api-utils";
import { SlotRendering } from "@frontend/slot/types";

const endpoint = '/slot-api/v1';

export class SlotRenderingClient extends APIClient {
    public static async fetchSlotRendering(accountId: string, spotId: string, moduleId: string): Promise<PaginatedResponse<SlotRendering>> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/rendering`);
        return await this.handleResponse<PaginatedResponse<SlotRendering>>(response);
    }

}