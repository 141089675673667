import { Spinner } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SlotOpening from '../../slot-status/slot-opening/slot-opening.component';
import { UserInterfaceProps } from '../../types/user-interface';
import PackageCard from '../package-card/package-card.component';
import usePudoDropList from './pudo-drop-list.controller';

export const PudoDropList = (props: UserInterfaceProps) => {
    const viewProps = usePudoDropList(props);

    if (viewProps.isLoading) {
        return (
            <div className='card'>
                <div className='card-body'>
                    <Spinner />
                </div>
            </div>
        );
    }

    if (viewProps.isDropping && viewProps.dropPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.dropPackage.account_id,
                    spot_id: viewProps.dropPackage.spot_id,
                    module_id: viewProps.dropPackage.module_id,
                    slot_id: viewProps.dropPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.packages.length === 0) {
        return (
            <div className='d-flex justify-content-center'>
                <h3>
                    <FormattedMessage
                        id='pudo-list.no-drop-off'
                        defaultMessage='No DropOff found'
                    />
                </h3>
            </div>
        );
    }
    return (
        <>
            <div className='d-flex justify-content-center'>
                <h3>
                    <FormattedMessage id='pudo-drop-list.drop-off-list' defaultMessage='DropOff list' />
                </h3>
            </div>
            <div className='d-flex row'>
                {viewProps.packages
                    .sort((a, b) => a.package.creation_timestamp.localeCompare(b.package.creation_timestamp))
                    .map((pack) => (
                        <PackageCard
                            packageInfo={pack}
                            key={pack.package.id}
                            onDropOff={viewProps.onDropOff}
                        />
                    ))}
            </div>
        </>
    );
};
