import { Logger } from '@frontend/Logger';
import { DeviceClient } from '@frontend/device/api';
import { useEffect } from 'react';

import { useAppSelector } from './redux/store';

export const useHeartbeat = () => {
    const navigationState = useAppSelector((state) => state.navigation);

    useEffect(() => {
        const interval = setInterval(() => {
            if (navigationState.iot == null) return Logger.warn('Could not send heartbeat, iot is null');
            if (navigationState.device == null) return Logger.warn('Could not send heartbeat, device is null');
            return DeviceClient.postHeartbeat(navigationState.device.account_id, navigationState.iot.id, navigationState.device.id)
                .then(() => Logger.debug('Heartbeat sent successfully.'))
                .catch(() => Logger.warn('Failed to send a haertbeat to the server.'));
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, []);
};
