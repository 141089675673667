import React from 'react';
import { FormattedMessage } from "react-intl";

export enum IoTActionName {
    "iot_change_state",
    "iot_show_user_interface",
    "iot_verify_equipment_state",
    "iot_notify"
}

export class IoTAction {
    static readonly IOT_CHANGE_STATE = new IoTAction(IoTActionName.iot_change_state, (
        <FormattedMessage
            id='IoTAction.change_state'
            description='The displayed value for the IoTAction change_state'
            defaultMessage='Change state'
        />
    ));

    static readonly IOT_SHOW_USER_INTERFACE = new IoTAction(IoTActionName.iot_show_user_interface, (
        <FormattedMessage
            id='IoTAction.show_user_interface'
            description='The displayed value for the IoTAction show_user_interface'
            defaultMessage='Show user interface'
        />
    ));

    static readonly IOT_VERIFY_EQUIPMENT_STATE = new IoTAction(IoTActionName.iot_verify_equipment_state, (
        <FormattedMessage
            id='IoTAction.verify_equipment_state'
            description='The displayed value for the IoTAction verify_equipment_state'
            defaultMessage='Verify equipment state'
        />
    ));

    static readonly IOT_NOTIFY = new IoTAction(IoTActionName.iot_notify, (
        <FormattedMessage
            id='IoTAction.notify'
            description='The displayed value for the IoTAction notify'
            defaultMessage='Notify'
        />
    ));

    static readonly ALL = [
        IoTAction.IOT_CHANGE_STATE,
        IoTAction.IOT_SHOW_USER_INTERFACE,
        IoTAction.IOT_VERIFY_EQUIPMENT_STATE,
        IoTAction.IOT_NOTIFY
    ];

    private constructor(public readonly value: IoTActionName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: IoTActionName): IoTAction | undefined {
        return IoTAction.ALL.find(action => action.value === value);
    }
}
