import { EntityType, useAppSelector } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageVariable } from '@frontend/package-variables/types';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageTriggerName, PackageType } from '@frontend/package/types';
import { navigationStore } from '@frontend/terminal/shared';
import { TransactionClient } from '@frontend/transaction/api';
import { Transaction, TransactionStateName } from '@frontend/transaction/types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    packages: PackageInfo[];
    isLoading: boolean;
    isPicking: boolean;
    changeIsPicking: React.Dispatch<React.SetStateAction<boolean>>;
    onPickUp: (_package: Package) => void;
    pickPackage: Package | null;
}

const usePudoPickList = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const iotDevice = navigationState.iot;
    const [packages, changePackages] = useState<PackageInfo[]>([]);
    const [isLoading, changeIsLoading] = useState<boolean>(true);
    const [isPicking, changeIsPicking] = useState<boolean>(false);
    const [pickPackage, changePickPackage] = useState<Package | null>(null);

    useEffect(() => {
        if (!navigationState.user || !navigationState.iot) return;
        changeIsLoading(true);
        IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_PICK_UP_PACKAGE });
        TransactionClient.fetchTransactions({ account_id: navigationState.user.accountId, state: TransactionStateName.TRANSACTION_PROCESSING }).then((res) => {
            const packagePromise: Promise<PackageInfo | undefined>[] = [];
            res.results.forEach((transaction) => {
                packagePromise.push(
                    PackageClient.fetchPackages({
                        account_id: transaction.account_id,
                        transaction_id: transaction.id,
                        state: PackageStateName.PACKAGE_PICK_UP_PENDING
                    }).then((res) => {
                        const _package = res.results.filter((p) => p.type === PackageType.PICK_UP)[0];
                        if (!_package) return undefined;
                        return PackageVariableClient.fetchVariables(res.results[0].account_id, transaction.id, res.results[0].id).then((res) => {
                            return {
                                transaction: transaction,
                                package: _package,
                                variables: res.results
                            };
                        });
                    })
                );
            });

            Promise.all(packagePromise).then((res) => {
                changePackages(res.filter((p) => p !== undefined) as PackageInfo[]);
                changeIsLoading(false);
            });
        });
    }, []);

    const onPickUp = (_package: Package) => {
        PackageWorkflowClient.triggerPackageState(_package.account_id, _package.transaction_id, _package.id, {
            trigger: PackageTriggerName.PACKAGE_CODE_SCANNED,
            source: { entity_id: iotDevice?.id, entity_type: EntityType.IOT }
        })
            .then(() => {
                changeIsPicking(true);
                changePickPackage(_package);
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        packages,
        isLoading,
        changeIsPicking,
        isPicking,
        onPickUp,
        pickPackage
    };
};

export default usePudoPickList;

export interface PackageInfo {
    transaction: Transaction;
    package: Package;
    variables: PackageVariable[];
}
