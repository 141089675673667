import { useEffect, useState } from 'react';

import { PackageCardProps } from './package-card.component';

const usePackageCard = (props: PackageCardProps) => {
    const [sender, changeSender] = useState<any>(undefined);
    const [receiver, changeReceiver] = useState<any>(undefined);

    useEffect(() => {
        if (props.packageInfo.variables) {
            const senderType = props.packageInfo.variables.find((v) => v.name === 'from_entity_type');
            const senderId = props.packageInfo.variables.find((v) => v.name === 'from_entity_id');

            const receiverType = props.packageInfo.variables.find((v) => v.name === 'to_entity_type');
            const receiverId = props.packageInfo.variables.find((v) => v.name === 'to_entity_id');

            if (senderType && senderId) {
                changeSender({ type: senderType?.value, id: senderId?.value });
            }

            if (receiverType && receiverId) {
                changeReceiver({ type: receiverType?.value, id: receiverId?.value });
            }
        }
    }, []);

    return {
        sender,
        receiver
    };
};

export default usePackageCard;
