import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateIoTFromUnknown, Unknown, UnknownListResponse } from '@frontend/iot/types';

const endpoint = '/iot-api/v1';

export class UnknownClient extends APIClient {
    public static async fetchUnknowns(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<UnknownListResponse> {
        return this.apiPaginated<UnknownListResponse, DefaultQueryParams>(`${endpoint}/unknowns`, queryParams);
    }

    public static async resolveUnknowns(ids: string[]): Promise<UnknownListResponse> {
        const response = await this.post(`${endpoint}/unknowns-resolve`, { ids: ids });
        return this.handleResponse<UnknownListResponse>(response);
    }

    public static async fetchUnknown(unknownId: string): Promise<Unknown> { 
        const response = await this.fetch(`${endpoint}/unknowns/${unknownId}`);
        return this.handleResponse<Unknown>(response);
    }

    public static async deleteUnknowns(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/unknowns-delete`, { ids: ids });
        return this.handleVoidResponse(response);
    }

    public static async deleteUnknown(unknownId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/unknowns/${unknownId}`);
        return this.handleVoidResponse(response);
    }

    public static async createIoTFromUnknown(unknownId: string, body: CreateIoTFromUnknown): Promise<void> {
        const response = await this.post(`${endpoint}/unknowns/${unknownId}/create-iot`, body);
        return this.handleVoidResponse(response);
    }
}
