import { APIClient } from "@frontend/api-utils";

const endpoint = '/iot-api/v1';

export class IoTEnumClient extends APIClient {
    public static async fetchIoTStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/iot-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchIoTActions(): Promise<string[]>{
        const response = await this.fetch(`${endpoint}/iot-actions`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchIoTTriggers(): Promise<string[]>{ 
        const response = await this.fetch(`${endpoint}/iot-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchIoTTypes(): Promise<string[]>{
        const response = await this.fetch(`${endpoint}/iot-types`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchIoTBrands(): Promise<string[]>{
        const response = await this.fetch(`${endpoint}/iot-brands`);
        return await this.handleResponse<string[]>(response);
    }
}