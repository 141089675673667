import React from 'react';
import { FormattedMessage } from 'react-intl';

import useOnDutyCustomer from './on-duty-customer.controller';

export const OnDutyCustomer = () => {
    const viewProps = useOnDutyCustomer();
    return (
        <div className='h-100'>
            <div className='d-flex flex-column align-content-center justify-content-center h-70'>
                <div className='d-flex flex-row justify-content-center'>
                    <h4>
                        {
                            <FormattedMessage
                                id='WorkflowStepTrigger.on_duty_customer'
                                description='Wait message for customer'
                                defaultMessage='Waiting for pharmacist to open the hatch'
                            />
                        }
                    </h4>
                </div>
            </div>
        </div>
    );
};
