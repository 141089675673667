import { StringPlaceholder } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useSlotOpened from './slot-opened.controller';

export interface SlotOpenedProps {
    data: { account_id: string; spot_id: string; module_id: string; slot_id: string };
    type: 'drop' | 'pick';
    callback?: () => void;
}

const SlotOpened = (props: SlotOpenedProps) => {
    const viewProps = useSlotOpened(props);
    return (
        <div
            id='slot-opening'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>
                {CommonMessage.OBJECTS.SLOT.DEFAULT}{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                <FormattedMessage
                    id='slot-opened.opened-message'
                    defaultMessage='is open'
                />
            </h3>
            <h3>
                {props.type === 'drop' ? (
                    <FormattedMessage
                        id='slot-opened.drop-off-message'
                        defaultMessage='You can now drop off your item.'
                    />
                ) : (
                    <FormattedMessage
                        id='slot-opened.pick-up-message'
                        defaultMessage='You can now pick up your item.'
                    />
                )}
            </h3>
            <h3 className='pb-4'>
                <FormattedMessage
                    id='slot-opened.close-message'
                    defaultMessage='Please close the door when you are done.'
                />
            </h3>
        </div>
    );
};

export default SlotOpened;
