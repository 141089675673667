import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { CreateGroupMember, GroupMember, GroupMemberListResponse, GroupMemberQueryParams } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class GroupMemberClient extends APIClient {
    public static async fetchGroupMembers(queryParams?: ApiQueryParams<DefaultQueryParams | GroupMemberQueryParams>): Promise<GroupMemberListResponse> {
        return await this.apiPaginated<GroupMemberListResponse, DefaultQueryParams>(`${endpoint}/group-members`, queryParams);
    }

    public static async resolveGroupMembers(groupMemberIds: string[]): Promise<GroupMemberListResponse> {
        const response = await this.post(`${endpoint}/group-members-resolve`, { ids: groupMemberIds });
        return await this.handleResponse<GroupMemberListResponse>(response);
    }

    public static async deleteGroupMembers(groupMemberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/group-members-delete`, { ids: groupMemberIds });
        return await this.handleVoidResponse(response);
    }

    public static async postGroupMember(accountId: string, groupId: string, groupMember: CreateGroupMember): Promise<GroupMember> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/groups/${groupId}/members`, groupMember);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async postGroupMemberList(accountId: string, groupId: string, entity_type: EntityType, groupMemberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/groups/${groupId}/${entity_type}s/members-list`, { ids: groupMemberIds});
        return await this.handleVoidResponse(response);
    }

    public static async fetchGroupMember(accountId: string, groupId: string, groupMemberId: string): Promise<GroupMember> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/groups/${groupId}/members/${groupMemberId}`);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async deleteGroupMember(accountId: string, groupId: string, groupMemberId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/groups/${groupId}/members/${groupMemberId}`);
        return await this.handleVoidResponse(response);
    }

}