import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { PackageClient, PackageEnumClient } from '@frontend/package/api';
import { Package, PackageListResponse, PackageQueryParams } from '@frontend/package/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

interface PackageState {
    unordered: Package[];
    packages: PackageListResponse | null;
    packageByTransaction: { [accountId: string]: PackageListResponse } | null;
    packageStates: string[] | null;
    packageTriggers: string[] | null;
    packageActions: string[] | null;
    status: SliceStatus;
}

const initialState: PackageState = {
    unordered: [],
    packages: null,
    packageByTransaction: null,
    packageStates: null,
    packageTriggers: null,
    packageActions: null,
    status: SliceStatus.INIT
};

const packageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        seedPackages(state, action: PayloadAction<Package[]>) {
            state.unordered = [...state.unordered.filter((pack) => action.payload.find((p) => p.id == pack.id) == undefined), ...action.payload];
        },
        updatePackage(state, action: PayloadAction<Package>) {
            state.unordered = state.unordered.map((p) => (p.id == action.payload.id ? action.payload : p));
            if (state.packages != null) {
                state.packages.results = state.packages.results.map((p) => (p.id == action.payload.id ? action.payload : p));
            }
        },
        addPackage(state, action: PayloadAction<Package>) {
            state.unordered.push(action.payload);
            if (state.packages != null) {
                state.packages.count++;
                state.packages.results.splice(0, 0, action.payload);
            }
        },
        removePackage(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((p) => p.id != action.payload);
            if (state.packages != null) {
                state.packages.count--;
                state.packages.results = state.packages.results.filter((p) => p.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPackages.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPackages.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const startPos = toNumber(action.meta.arg.index) * toNumber(action.meta.arg.size);
                if (state.packages == null) {
                    state.packages = { ...action.payload, results: new Array(action.payload.count) };
                    state.packages.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.packages.results.length != action.payload.count) {
                        state.packages.count = action.payload.count;
                        state.packages.results = new Array(action.payload.count);
                    }
                    state.packages.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (action.meta.arg.transaction_id && !isArray(action.meta.arg.transaction_id)) {
                    if (!state.packageByTransaction) {
                        state.packageByTransaction = { [action.meta.arg.transaction_id]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.packageByTransaction[action.meta.arg.transaction_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else if (state.packageByTransaction && !state.packageByTransaction[action.meta.arg.transaction_id]) {
                        state.packageByTransaction[action.meta.arg.transaction_id] = { ...action.payload, results: new Array(action.payload.count) };
                        state.packageByTransaction[action.meta.arg.transaction_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else {
                        if (state.packageByTransaction[action.meta.arg.transaction_id].results.length != action.payload.count) {
                            state.packageByTransaction[action.meta.arg.transaction_id].count = action.payload.count;
                            state.packageByTransaction[action.meta.arg.transaction_id].results = new Array(action.payload.count);
                        }
                        state.packageByTransaction[action.meta.arg.transaction_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    }
                }

                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
            })
            .addCase(fetchPackage.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPackage.fulfilled, (state, action) => {
                const found = state.unordered.find((w) => w.id === action.meta.arg.packageId);
                if (found) {
                    state.unordered[state.unordered.indexOf(found)] = action.payload;
                } else {
                    state.unordered = [...state.unordered, action.payload];
                }
            })
            .addCase(fetchPackageStates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPackageStates.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.packageStates = action.payload;
            })
            .addCase(fetchPackageTriggers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPackageTriggers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.packageTriggers = action.payload;
            })
            .addCase(fetchPackageActions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchPackageActions.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.packageActions = action.payload;
            });
    }
});

export const fetchPackages = createAsyncThunk<PackageListResponse, ApiQueryParams<DefaultQueryParams | PackageQueryParams>>(
    'fetchPackages',
    async (queryParams: ApiQueryParams<DefaultQueryParams | PackageQueryParams>, { rejectWithValue }) => {
        try {
            return await PackageClient.fetchPackages(queryParams);
        } catch (e) {
            if ((e as ApiError).json) rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchPackage = createAsyncThunk<Package, { accountId: string; transactionId: string; packageId: string }>(
    'fetchPackage',
    async (variables: { accountId: string; transactionId: string; packageId: string }, { rejectWithValue }) => {
        try {
            return await PackageClient.fetchPackage(variables.accountId, variables.transactionId, variables.packageId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

//ENUM

export const fetchPackageStates = createAsyncThunk<string[]>('fetchPackageStates', async (_, { rejectWithValue }) => {
    try {
        return await PackageEnumClient.fetchPackageStates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchPackageTriggers = createAsyncThunk<string[]>('fetchPackageTriggers', async (_, { rejectWithValue }) => {
    try {
        return await PackageEnumClient.fetchPackageTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchPackageActions = createAsyncThunk<string[]>('fetchPackageActions', async (_, { rejectWithValue }) => {
    try {
        return await PackageEnumClient.fetchPackageActions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const packageStore = { packages: packageSlice.reducer };
export const { seedPackages, updatePackage, addPackage, removePackage } = packageSlice.actions;
