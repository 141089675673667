import React from "react";
import { FormattedMessage } from "react-intl";

export enum PackageActionName {
    PACKAGE_CHANGE_STATE = 'package_change_state',
    PACKAGE_ACKNOWLEDGE_PROCESSED = 'package_acknowledge_processed',
    PACKAGE_ADD_TO_STOCK = 'package_add_to_stock',
    PACKAGE_REMOVE_FROM_STOCK = 'package_remove_from_stock',
    PACKAGE_RETURN_PRODUCT = 'package_return_product',
    PACKAGE_WITHDRAW_PRODUCT = 'package_withdraw_product',
    PACKAGE_CREATE_PIN_CODE = 'package_create_pin_code',
    PACKAGE_RETIRE_PIN_CODE = 'package_retire_pin_code',
    PACKAGE_OPEN_SLOT = 'package_open_slot',
    PACKAGE_NOTIFY_SENDER = 'package_notify_sender',
    PACKAGE_NOTIFY_RECEIVER = 'package_notify_receiver'
}

export class PackageAction {
    static readonly PACKAGE_CHANGE_STATE = new PackageAction(
        PackageActionName.PACKAGE_CHANGE_STATE,
        (
            <FormattedMessage
                id='packageAction.change_state'
                description='package action change state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly PACKAGE_ACKNOWLEDGE_PROCESSED = new PackageAction(
        PackageActionName.PACKAGE_ACKNOWLEDGE_PROCESSED,
        (
            <FormattedMessage
                id='packageAction.acknowledge_processed'
                description='package action acknowledge processed'
                defaultMessage='Acknowledge processed'
            />
        )
    );

    static readonly PACKAGE_ADD_TO_STOCK = new PackageAction(
        PackageActionName.PACKAGE_ADD_TO_STOCK,
        (
            <FormattedMessage
                id='packageAction.add_to_stock'
                description='package action add to stock'
                defaultMessage='Add to stock'
            />
        )
    );

    static readonly PACKAGE_REMOVE_FROM_STOCK = new PackageAction(
        PackageActionName.PACKAGE_REMOVE_FROM_STOCK,
        (
            <FormattedMessage
                id='packageAction.remove_from_stock'
                description='package action remove from stock'
                defaultMessage='Remove from stock'
            />
        )
    );

    static readonly PACKAGE_RETURN_PRODUCT = new PackageAction(
        PackageActionName.PACKAGE_RETURN_PRODUCT,
        (
            <FormattedMessage
                id='packageAction.return_product'
                description='package action return product'
                defaultMessage='Return product'
            />
        )
    );

    static readonly PACKAGE_WITHDRAW_PRODUCT = new PackageAction(
        PackageActionName.PACKAGE_WITHDRAW_PRODUCT,
        (
            <FormattedMessage
                id='packageAction.withdraw_product'
                description='package action withdraw product'
                defaultMessage='Withdraw product'
            />
        )
    );

    static readonly PACKAGE_CREATE_PIN_CODE = new PackageAction(
        PackageActionName.PACKAGE_CREATE_PIN_CODE,
        (
            <FormattedMessage
                id='packageAction.create_pin_code'
                description='package action create pin code'
                defaultMessage='Create PIN code'
            />
        )
    );

    static readonly PACKAGE_RETIRE_PIN_CODE = new PackageAction(
        PackageActionName.PACKAGE_RETIRE_PIN_CODE,
        (
            <FormattedMessage
                id='packageAction.retire_pin_code'
                description='package action retire pin code'
                defaultMessage='Retire PIN code'
            />
        )
    );

    static readonly PACKAGE_OPEN_SLOT = new PackageAction(
        PackageActionName.PACKAGE_OPEN_SLOT,
        (
            <FormattedMessage
                id='packageAction.open_slot'
                description='package action open slot'
                defaultMessage='Open SLOT'
            />
        )
    );

    static readonly PACKAGE_NOTIFY_SENDER = new PackageAction(
        PackageActionName.PACKAGE_NOTIFY_SENDER,
        (
            <FormattedMessage
                id='packageAction.notify_sender'
                description='package action notify sender'
                defaultMessage='Notify sender'
            />
        )
    );

    static readonly PACKAGE_NOTIFY_RECEIVER = new PackageAction(
        PackageActionName.PACKAGE_NOTIFY_RECEIVER,
        (
            <FormattedMessage
                id='packageAction.notify_receiver'
                description='package action notify receiver'
                defaultMessage='Notify receiver'
            />
        )
    );

    static readonly ALL = [
        PackageAction.PACKAGE_CHANGE_STATE,
        PackageAction.PACKAGE_ACKNOWLEDGE_PROCESSED,
        PackageAction.PACKAGE_ADD_TO_STOCK,
        PackageAction.PACKAGE_REMOVE_FROM_STOCK,
        PackageAction.PACKAGE_RETURN_PRODUCT,
        PackageAction.PACKAGE_WITHDRAW_PRODUCT,
        PackageAction.PACKAGE_CREATE_PIN_CODE,
        PackageAction.PACKAGE_RETIRE_PIN_CODE,
        PackageAction.PACKAGE_OPEN_SLOT,
        PackageAction.PACKAGE_NOTIFY_SENDER,
        PackageAction.PACKAGE_NOTIFY_RECEIVER
    ];
    private constructor(public readonly value: PackageActionName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: PackageActionName): PackageAction | undefined {
        return PackageAction.ALL.find(action => action.value === value);
    }
}