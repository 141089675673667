import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import useResetPharmacy from './reset-pharmacy.controller';

const ResetPharmacy = (props: UserInterfaceProps) => {
    const viewProps = useResetPharmacy(props);
    return (
        <div className='h-100'>
            <div className='d-flex flex-column h-70 align-content-center justify-content-center'>
                <div className='d-flex flex-row justify-content-center'>
                    <h2>
                        <FormattedMessage
                            id='reset-pharmacy.message'
                            defaultMessage='Are you sure you want to reset?'
                        />
                    </h2>
                </div>
                <div className='d-flex flex-row justify-content-center'>
                    <button
                        className='btn btn-danger fs-2 btn-lg me-2'
                        onClick={viewProps.onConfirm}>
                        <FormattedMessage
                            id='reset-pharmacy.reset'
                            defaultMessage='Yes'
                        />
                    </button>
                    <button
                        className='btn btn-secondary fs-2 btn-lg'
                        onClick={viewProps.onCancel}>
                        <FormattedMessage
                            id='reset-pharmacy.cancel'
                            defaultMessage='No'
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResetPharmacy;
