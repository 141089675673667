export interface Actuator {
    type: ActuatorType;
    actions: {locker_id: string, data: object}[];
}

export interface PostActuator extends Actuator {}

export enum ActuatorType {
    OPEN = 'open',
    QUERY = 'query',
    SERVICE = 'service',
    OPEN_WAIT_CLOSE = 'open_wait_close'
}
