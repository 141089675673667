import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateUserInterface, UpdateUserInterface, UserInterface, UserInterfaceListResponse, UserInterfaceQueryParams } from '@frontend/user-interface/types';

const endpoint = '/user-interface-api/v1';

export class UserInterfaceClient extends APIClient {
    public static async fetchUserInterfaces(queryParams?: ApiQueryParams<DefaultQueryParams & UserInterfaceQueryParams>): Promise<UserInterfaceListResponse> {
        return await this.apiPaginated<UserInterfaceListResponse, DefaultQueryParams & UserInterfaceQueryParams>(`${endpoint}/user-interfaces`, queryParams);
    }

    public static async resolveUserInterfaces(userInterfaceIds: string[]): Promise<UserInterfaceListResponse> {
        const response = await this.post(`${endpoint}/user-interfaces-resolve`, { ids: userInterfaceIds });
        return await this.handleResponse<UserInterfaceListResponse>(response);
    }

    public static async deleteUserInterfaces(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/user-interfaces-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountUserInterfaces(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<UserInterfaceListResponse> {
        return await this.apiPaginated<UserInterfaceListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/user-interfaces`, queryParams);
    }

    public static async postAccountUserInterface(accountId: string, userInterface: CreateUserInterface): Promise<UserInterface> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/user-interfaces`, userInterface);
        return await this.handleResponse<UserInterface>(response);
    }

    public static async fetchAccountUserInterface(accountId: string, userInterfaceId: string): Promise<UserInterface> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}`);
        return await this.handleResponse<UserInterface>(response);
    }

    public static async patchAccountUserInterface(accountId: string, userInterfaceId: string, userInterface: UpdateUserInterface): Promise<UserInterface> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}`, userInterface);
        return await this.handleResponse<UserInterface>(response);
    }

    public static async deleteAccountUserInterface(accountId: string, userInterfaceId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/user-interfaces/${userInterfaceId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
