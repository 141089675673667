import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { CreateRequirement, Requirement, RequirementListResponse, RequirementQueryParams, UpdateRequirement } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class RequirementClient extends APIClient {
    public static async fetchRequirements(queryParams?: ApiQueryParams<RequirementQueryParams>): Promise<RequirementListResponse> {
        return await this.apiPaginated<RequirementListResponse, RequirementQueryParams>(`${endpoint}/requirements`, queryParams);
    }

    public static async resolveRequirements(requirementIds: string[]): Promise<RequirementListResponse> {
        const response = await this.post(`${endpoint}/requirements-resolve`, { ids: requirementIds });
        return await this.handleResponse<RequirementListResponse>(response);
    }

    public static async deleteRequirements(requirementIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/requirements-delete`, { ids: requirementIds });
        return await this.handleVoidResponse(response);
    }

    public static async postRequirement(accountId: string, spotId: string, requirement: CreateRequirement): Promise<Requirement> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots/${spotId}/requirements`, requirement);
        return await this.handleResponse<Requirement>(response);
    }

    public static async fetchSlotRequirement(accountId: string,spotId: string, requirementId: string): Promise<Requirement> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/requirements/${requirementId}`);
        return await this.handleResponse<Requirement>(response);
    }

    public static async patchRequirement(accountId: string,spotId: string, requirementId: string, body: UpdateRequirement): Promise<Requirement> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/spots/${spotId}/requirements/${requirementId}`, body);
        return await this.handleResponse<Requirement>(response);
    }

    public static async deleteRequirement(accountId: string,spotId: string, requirementId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/spots/${spotId}/requirements/${requirementId}`);
        return await this.handleVoidResponse(response);
    }
}
