import { ClassType } from '@frontend/common';
import { useEffect, useState } from 'react';

import { LayoutItemProps } from './layout-item.component';

interface ViewProps {
    style: React.CSSProperties;
    itemClass: string;
    label?: string;
    onClick?: () => void;
}

const useLayoutItem = (props: LayoutItemProps): ViewProps => {
    const [itemClass, changeItemClass] = useState<ClassType>(ClassType.SECONDARY);
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * props.slotRendering.location.x) / props.fullWidth}%`,
        right: `${100 - (100 * (props.slotRendering.location.x + props.slotRendering.dimension.width)) / props.fullWidth}%`,
        bottom: `${(100 * props.slotRendering.location.y) / props.fullHeight}%`,
        top: `${100 - (100 * (props.slotRendering.location.y + props.slotRendering.dimension.height)) / props.fullHeight}%`
    };

    const label = props.label ? props.label : props.slots.length > 0 && props.slots[0].number ? props.slots[0].number : props.slotRendering.id.toString();

    const onClick = () => {
        props.onClick && props.onClick(props.slotRendering, props.slots);
    };

    useEffect(() => {
        if (props.slotClassMap) {
            const slotIds = props.slots.map((slot) => slot.id);
            const found = Array.from(props.slotClassMap.keys()).find((k) => slotIds.includes(k));
            if (found) changeItemClass(props.slotClassMap.get(found)!);
            else changeItemClass(ClassType.SECONDARY);
            if (props.selectedSlot && props.slots.map((slot) => slot.id).includes(props.selectedSlot)) {
                changeItemClass(ClassType.SUCCESS);
            }
        }
    }, [props.slotClassMap, props.selectedSlot]);

    return {
        style,
        itemClass,
        label,
        onClick
    };
};

export default useLayoutItem;
