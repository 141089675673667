import { useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { changeView, navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../../types/user-interface';

interface ViewProps {
    slot: Slot | null;
    isSlotOpening: boolean;
    changeIsSlotOpening: React.Dispatch<React.SetStateAction<boolean>>;
    isSlotOpen: boolean;
}

const useOnDutyCustomerDropOff = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [isSlotOpening, changeIsSlotOpening] = useState<boolean>(false);
    const [isSlotOpen, changeIsSlotOpen] = useState<boolean>(false);
    const timeout = props.userInterface.data.timeout;
    const userInterfaceId = props.userInterface.data.user_interface_id;
    const iot_state = props.userInterface.data.iot_state;

    useEffect(() => {
        const spotId = navigationState.settings?.results.find((setting) => setting.key === 'spot_id')?.value;
        SlotClient.fetchSlots({ type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((res) => {
            if (res.results.length === 1) {
                changeSlot(res.results[0]);
            }
        });

        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_CUSTOMER_DROP_OFF });
        }
    }, []);

    useEffect(() => {
        let timerId: number | undefined = undefined;

        if (timeout && userInterfaceId && navigationState.iot) {
            timerId = window.setTimeout(() => {
                if (!isSlotOpening) {
                    props.dispatch(changeView({ view: userInterfaceId, cache: null }));
                    if (iot_state) {
                        IoTClient.patchIoT(navigationState.iot!.account_id, navigationState.iot!.id, { state: iot_state });
                    }
                }
            }, timeout * 1000);
        }

        return () => {
            if (timerId) {
                window.clearTimeout(timerId);
            }
        };
    }, [isSlotOpening, timeout, userInterfaceId, navigationState.iot]);

    return {
        slot,
        changeIsSlotOpening,
        isSlotOpening,
        isSlotOpen
    };
};

export default useOnDutyCustomerDropOff;
