import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ProductClient } from '@frontend/product/api';
import { IProduct, ProductListResponse, ProductQueryParams } from '@frontend/product/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

interface ProductState {
    unordered: IProduct[];
    products: ProductListResponse | null;
    productsByAccount: { [accountId: string]: ProductListResponse } | null;
    status: SliceStatus;
}

const initialState: ProductState = {
    unordered: [],
    products: null,
    productsByAccount: null,
    status: SliceStatus.INIT
};

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        seedProducts(state, action: PayloadAction<IProduct[]>) {
            state.unordered = [...state.unordered.filter((product) => action.payload.find((w) => w.id == product.id) == undefined), ...action.payload];
        },
        updateProduct(state, action: PayloadAction<IProduct>) {
            state.unordered = state.unordered.map((product) => (product.id == action.payload.id ? action.payload : product));
            if (state.products) {
                state.products.results = state.products.results.map((product) => (product.id == action.payload.id ? action.payload : product));
            }
            if (state.productsByAccount != null && state.productsByAccount[action.payload.account_id] != null) {
                state.productsByAccount[action.payload.account_id].results = state.productsByAccount[action.payload.account_id].results.map((product) =>
                    product.id == action.payload.id ? action.payload : product
                );
            }
        },
        addProduct(state, action: PayloadAction<IProduct>) {
            state.unordered.push(action.payload);
            if (state.products != null) {
                state.products.count++;
                state.products.results = [action.payload].concat(state.products.results);
            }
            if (state.productsByAccount != null && state.productsByAccount[action.payload.account_id] != null) {
                state.productsByAccount[action.payload.account_id].results = [action.payload].concat(
                    state.productsByAccount[action.payload.account_id].results
                );
                state.productsByAccount[action.payload.account_id].count++;
            }
        },
        removeProduct(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((w) => w.id != action.payload);
            if (state.products != null) {
                state.products.count--;
                state.products.results = state.products.results.filter((w) => w.id != action.payload);
            }
            if (state.productsByAccount != null) {
                Object.keys(state.productsByAccount).forEach((key) => {
                    state.productsByAccount![key].results = state.productsByAccount![key].results.filter((w) => w.id != action.payload);
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.products == null) {
                    state.products = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.products.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.products.results.length !== action.payload.count) {
                        state.products.count = action.payload.count;
                        state.products.results = new Array(action.payload.count);
                    }
                    state.products.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((w) => action.payload.results.find((res) => res.id == w.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchProduct.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                const found = state.unordered.find((w) => w.id === action.meta.arg.productId);
                if (found) {
                    state.unordered[state.unordered.indexOf(found)] = action.payload;
                } else {
                    state.unordered = [...state.unordered, action.payload];
                }
            })
            .addCase(fetchAccountProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchAccountProducts.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.queryParams?.size) * toNumber(action.meta.arg.queryParams?.index);
                const accountId = action.meta.arg.accountId;
                if (state.productsByAccount == null) {
                    state.productsByAccount = { [accountId]: { ...action.payload, results: new Array(action.payload.count) } };
                    state.productsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state.productsByAccount) {
                    if (state.productsByAccount[accountId] == undefined) {
                        state.productsByAccount[accountId] = { ...action.payload, results: new Array(action.payload.count) };
                        state.productsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    } else {
                        if (state.productsByAccount[accountId].results.length != action.payload.count) {
                            state.productsByAccount[accountId].count = action.payload.count;
                            state.productsByAccount[accountId].results = new Array(action.payload.count);
                        }
                        state.productsByAccount[accountId].results.splice(startPos, action.payload.results.length, ...action.payload.results);
                    }
                }
            })
            .addCase(deleteProducts.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteProducts.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.products == null) return;
                state.products.count = state.products.count - action.meta.arg.length;
                state.products.results = state.products.results.filter((w) => !action.meta.arg.includes(w.id));
            })
    }
});

export const fetchProducts = createAsyncThunk<ProductListResponse, ApiQueryParams<DefaultQueryParams | ProductQueryParams>>(
    'fetchProducts',
    async (queryParams: ApiQueryParams<DefaultQueryParams | ProductQueryParams>, { rejectWithValue }) => {
        try {
            return await ProductClient.fetchProducts(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchProduct = createAsyncThunk<IProduct, { accountId: string; productId: string }>(
    'fetchProduct',
    async (variables: { accountId: string; productId: string }, { rejectWithValue }) => {
        try {
            return await ProductClient.fetchProduct(variables.accountId, variables.productId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchAccountProducts = createAsyncThunk<ProductListResponse, { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }>(
    'fetchAccountProducts',
    async (variables: { accountId: string; queryParams?: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await ProductClient.fetchAccountProducts(variables.accountId, variables.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAccountProduct = createAsyncThunk<void, { accountId: string; productId: string }>(
    'deleteAccountProduct',
    async (params: { accountId: string; productId: string }, { rejectWithValue }) => {
        try {
            return await ProductClient.deleteProduct(params.accountId, params.productId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteProducts = createAsyncThunk<void, string[]>('deleteProducts', async (productIds: string[], { rejectWithValue }) => {
    try {
        return await ProductClient.deleteProducts(productIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const productStore = { products: productSlice.reducer };
export const { seedProducts, updateProduct, addProduct, removeProduct } = productSlice.actions;
