import { DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { Dimension, Location } from '@frontend/common';

export interface Slot {
    number: string | null;
    display: string | null;
    index: number;
    row: number | null;
    column: number | null;
    note: string | null;
    location: Location | null;
    absolute: Location | null;
    dimension: Dimension | null;
    type: SlotType | null;
    drop_station_slot_id: string | null;
    status: SlotStatus | null;

    id: string;
    account_id: string;
    spot_id: string;
    module_id: string;

    update_timestamp: string;
    creation_timestamp: string;
}

export interface CreateSlot {
    number?: string | null;
    display?: string | null;
    index?: number;
    row?: number | null;
    column?: number | null;
    note?: string | null;
    location?: Location | null;
    absolute?: Location | null;
    dimension?: Dimension | null;
    type?: SlotType | null;
    drop_station_slot_id?: string | null;
}

export interface UpdateSlot {
    number?: string | null;
    display?: string | null;
    index?: number | null;
    row?: number | null;
    column?: number | null;
    note?: string | null;
    location?: Location | null;
    absolute?: Location | null;
    dimension?: Dimension | null;
    type?: SlotType | null;
    drop_station_slot_id?: string | null;
    status?: SlotStatus | null;
}

export enum SlotStatus {
    AVAILABLE = 'available',
    BROKEN = 'broken',
    MERGED = 'merged'
}

export type SlotListResponse = PaginatedResponse<Slot>;

export enum QueryParams {
    account_id = 'account_id',
    SPOT_ID = 'spot_id',
    MODULE_ID = 'module_id',
    FACING = 'facing',
    TYPE = 'type'
}

export type SlotQueryParams = DefaultQueryParams & QueryParams;

export enum SlotType {
    DEFAULT = 'default',
    DROP_STATION = 'drop_station',
    PASS_THROUGH = 'pass_through'
}

export enum SlotFacing {
    UNSPECIFIED = 'unspecified',
    INSIDE = 'inside',
    OUTSIDE = 'outside'
}
