import { StringPlaceholder } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import usePudoDropoff from './pudo-dropoff.controller';

export const PudoDropoff = (props: UserInterfaceProps) => {
    const viewProps = usePudoDropoff(props);
    return (
        <div
            id={UserInterfaceType.DROPOFF_UI}
            className='d-flex flex-column justify-content-center'>
            <h3 className='pb-4 text-center'>
                {CommonMessage.OBJECTS.SLOT.DEFAULT}{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                <FormattedMessage
                    id='slot-opened.opened-message'
                    defaultMessage='is open'
                />
            </h3>
            <h3 className='pb-4 text-center'>
                <FormattedMessage
                    id='slot-opened.drop-off-message'
                    defaultMessage='You can now drop off your item.'
                />
            </h3>
            <h3 className='pb-4 text-center'>
                <FormattedMessage
                    id='slot-opened.close-message'
                    defaultMessage='Please close the door when you are done.'
                />
            </h3>
        </div>
    );
};
