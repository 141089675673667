import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { UserInterfaceButton } from '@frontend/user-interface-button/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useUserInterfaceButtonElement from './button-element.controller';

const ID = 'user-interface-button-element';
export interface UserInterfaceButtonElementProps {
    dispatch: ThunkDispatch<any, any, Action>;
    button: UserInterfaceButton;
}

const UserInterfaceButtonElement = (props: UserInterfaceButtonElementProps) => {
    const viewProps = useUserInterfaceButtonElement(props);
    const { locale } = useLocale();

    if (viewProps.icon == null) return <></>;
    return (
        <div
            id={ID}
            className='w-md-50 w-xl-25 ratio ratio-1x1'>
            <div className='p-3 clickable'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={viewProps.onClick}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                            <viewProps.icon
                                size={190}
                                className={`text-${viewProps.iconColor || 'primary'}`}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center h-25 bg-primary'>
                            <h3 className='text-white placeholder-glow'>
                                <strong>{getCorrectTranslation(locale, props.button, 'name')}</strong>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInterfaceButtonElement;
