import { AuthenticationManager } from '@frontend/authentication-v2';
import { DeviceEventListener } from '@frontend/device/events';
import { WebsocketState } from '@frontend/pub-sub';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../redux/store';
import { ConnectionErrorAlertProps } from './connection-error-alert.component';

interface ViewProps {
    status: WebsocketState;
    connectionTimeout: number | null;
}

const useConnectionErrorAlert = (props: ConnectionErrorAlertProps): ViewProps => {
    const dispatch = useAppDispatch();
    const [status, changeStatus] = useState<WebsocketState>(WebsocketState.INIT);
    const [connectionTimeout, changeConnectionTimeout] = useState<number | null>(null);

    useEffect(() => {
        AuthenticationManager.getInstance()
            .waitForToken(1000 * 60 * 60 * 24 * 5)
            .then((token) => {
                let interval: NodeJS.Timer;
                DeviceEventListener.getInstance(dispatch).subscribeToWebsocketState((value) => {
                    changeStatus(value);
                    if (value !== WebsocketState.CONNECTED) {
                        changeConnectionTimeout(DeviceEventListener.getInstance(dispatch).connectionTimeout || null);
                        interval = setInterval(() => {
                            connectionTimeout && changeConnectionTimeout(connectionTimeout - 1);
                        }, 1000);
                    } else {
                        changeConnectionTimeout(null);
                        clearInterval(interval);
                    }
                });
            });
    }, []);

    return {
        status,
        connectionTimeout
    };
};

export default useConnectionErrorAlert;
