import { Spinner } from '@frontend/elements';
import React from 'react';

import { OnDutyOpenSlotButton } from '../../on-duty-open-slot/on-duty-open-slot.component';
import SlotStatus from '../../slot-status/slot-status.component';
import { UserInterfaceProps } from '../../types/user-interface';
import useOnDutyCustomerPickUp from './on-duty-customer-pick-up.controller';

export const OnDutyCustomerPickUp = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyCustomerPickUp();

    if (!viewProps.slot) {
        return <Spinner />;
    }

    if (viewProps.isSlotOpening) {
        return (
            <SlotStatus
                {...props}
                data={{
                    account_id: viewProps.slot.account_id,
                    spot_id: viewProps.slot.spot_id,
                    module_id: viewProps.slot.module_id,
                    slot_id: viewProps.slot.id
                }}
                type={'drop'}
                closedCallBack={() => viewProps.processPackage()}
            />
        );
    }

    return (
        <div
            className='justify-content-center align-content-center'
            style={{ height: '90%' }}>
            <div className='d-flex justify-content-center align-content-center'>
                <OnDutyOpenSlotButton callback={() => viewProps.changeIsSlotOpening(true)} />
            </div>
        </div>
    );
};
