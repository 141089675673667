import React from 'react';
import { RiDoorOpenLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

export const OnDutyPharmacistAction = () => {
    return (
        <div className='h-100'>
            <div className='d-flex flex-column align-content-center justify-content-center h-70'>
                <div className='d-flex flex-row justify-content-center'>
                    <h4>
                        {
                            <FormattedMessage
                                id='WorkflowStepTrigger.on_duty_pharmacist'
                                description='Wait message for pharmacist'
                                defaultMessage='Please wait, external hatch is open'
                            />
                        }
                    </h4>
                </div>
                <div className='d-flex flex-row justify-content-center'>
                    <RiDoorOpenLine size={190} className='text-primary' />
                </div>
            </div>
        </div>
    );
};
