import { useAccount } from '@frontend/account/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ObjectSelectInputProps } from './object-select-input.component';

interface ViewProps<T extends { id: string }> {
    options: { value: string; label: string }[];
    mappedOptions?: { label: string; options: { value: string; label: string }[] }[];
    value?: T | null;
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => void;
    onChange: (value: any) => void;
    overwriteDisabled?: boolean;
    hide: boolean;
}

const pageSize = '100';

const useObjectSelectInput = <T extends { id: string }, D extends string | number>(props: ObjectSelectInputProps<T, D>): ViewProps<T> => {
    const params = useParams();
    const { currentAccount } = useAccount();
    const [urlOverwrite, changeUrlOverwrite] = useState<T | null>(null);
    const [cache, changeCache] = useState<T[]>([]);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const [mappedOptions, changeMappedOptions] = useState<{ label: string; options: { value: string; label: string }[] }[]>([]);
    const { queryParam, onQueryParamChange } = useChangeUrl(props.useQueryParam?.param);
    const onChange = (value: string) => {
        const object = cache.find((o) => o.id === value);
        props.onChange && props.onChange(object ?? null);
    };

    const value: T | null = useMemo(() => {
        !urlOverwrite && onQueryParamChange(props.value?.id ?? null);
        if (options.length === 1 && props.required) {
            const found = cache.find((o) => o.id === options[0].value);
            if (!found) return null;
            props.onChange && props.onChange(found);
        }
        return props.value ?? null;
    }, [props.value, props.idValue, options]);

    useEffect(() => {
        if (props.idValue && props.value == undefined && value == null && props.onChange) {
            const found = cache.find((o) => o.id === props.idValue);
            if (found) props.onChange(found);
        }
    }, [options]);

    useEffect(() => {
        if (props.useUrlOverwrite && !props.disableFetch) {
            const param = params[props.useUrlOverwrite.param];
            if (param !== undefined)
                props.useUrlOverwrite.fetch(param).then((result) => {
                    changeUrlOverwrite(result);
                    props.onChange && props.onChange(result);
                });
        }
    }, [props.useUrlOverwrite]);

    useEffect(() => {
        if (props.useQueryParam && value === null && queryParam !== null && !props.disableFetch) {
            props.useQueryParam.fetch(queryParam).then((result) => {
                props.onChange && props.onChange(result);
            });
        }
    }, [props.useQueryParam, queryParam, value, props.disableFetch]);

    //TODO: this usePrevious is used because the useEffect triggered incorrectly (but should be investigated and fixed properly later when we have the time)
    const previous = usePrevious({ params: props.queryParams, index: index });
    useEffect(() => {
        if ((previous == undefined || JSON.stringify(previous.params) != JSON.stringify(props.queryParams) || previous.index != index) && !props.disableFetch) {
            props.fetch({ index: index.toString(), size: pageSize, account_id: currentAccount, ...props.queryParams }).then((result) => {
                changeCount(result.count);
                if (index == 0) {
                    if (!props.mapOptions) {
                        changeOptions(mapResultSetToOptions(result.results, props.itemLabel));
                        changeCache(result.results);
                    } else {
                        changeMappedOptions(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel));
                    }
                } else {
                    if (!props.mapOptions) {
                        changeOptions((options ?? []).concat(mapResultSetToOptions(result.results, props.itemLabel)));
                        changeCache((cache ?? []).concat(result.results));
                    } else {
                        changeMappedOptions((mappedOptions ?? []).concat(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel)));
                        changeCache((cache ?? []).concat(result.results));
                    }
                }
            });
        }
    }, [index, props.queryParams]);

    useEffect(() => {
        if (props.mapOptions) {
            mapResultSetToMappedOptions(cache, props.mapOptions, props.itemLabel);
        } else {
            changeOptions(mapResultSetToOptions(cache, props.itemLabel));
        }
    }, [props.itemLabel]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            props.fetch({ search: inputValue, index: '0', size: pageSize, ...props.queryParams }).then((result) => {
                callback(mapResultSetToOptions(result.results, props.itemLabel));
                changeCache(result.results);
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    return {
        options,
        value,
        onScrollToBottom,
        search,
        onChange,
        overwriteDisabled:
            urlOverwrite !== null || (!props.hideOnOneOption && options.length === 1 && !props.isClearable && props.required) || props.disableFetch,
        hide: props.hideOnOneOption === true && options.length <= 1 && !props.isClearable,
        mappedOptions
    };
};

export default useObjectSelectInput;

function mapResultSetToOptions<T extends { id: string }>(result: T[], labels: (item: T) => string): { value: any; label: string }[] {
    return result.map((item) => ({
        value: item.id,
        label: labels(item) ?? item.id
    }));
}

function mapResultSetToMappedOptions<T extends { id: string }>(
    result: T[],
    mapKey: keyof T,
    labels: (item: T) => string
): { label: string; options: { value: any; label: string }[] }[] {
    const groupedItems: { label: string; options: { value: any; label: string }[] }[] = [];

    result.forEach((item) => {
        if (item[mapKey] && groupedItems.find((g) => g.label === item[mapKey])) {
            groupedItems.find((g) => g.label === item[mapKey])?.options.push({ value: item.id, label: labels(item) ?? item.id });
        } else {
            groupedItems.push({ label: item[mapKey] as string, options: [{ value: item.id, label: labels(item) ?? item.id }] });
        }
    });

    return groupedItems;
}

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const useChangeUrl = (key?: string): { queryParam: string | null; onQueryParamChange: (value: string | null) => void } => {
    const [searchParams, setSearchParams] = useSearchParams();
    const param = key && searchParams.get(key);
    const onChange = (value: string | null) => {
        if (value && key) {
            setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                if (params.has(key)) {
                    params.set(key, value);
                } else {
                    params.append(key, value);
                }
                return params;
            });
        } else if (key) {
            setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                params.delete(key);
                return params;
            });
        }
    };

    return {
        queryParam: param || null,
        onQueryParamChange: onChange
    };
};
