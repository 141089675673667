import { Spinner, StringPlaceholder } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useSlotOpening from './slot-opening.controller';

export interface SlotOpeningProps {
    data: { account_id: string; spot_id: string; module_id: string; slot_id: string };
}

const SlotOpening = (props: SlotOpeningProps) => {
    const viewProps = useSlotOpening(props);
    return (
        <div
            id='slot-opening'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>
                {CommonMessage.OBJECTS.SLOT.DEFAULT}{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                <FormattedMessage
                    id='slot-opening.opening-message'
                    defaultMessage='is opening'
                />
            </h3>
            <Spinner />
        </div>
    );
};

export default SlotOpening;
