import { Spot } from '@frontend/spot/types';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { ModuleLayout } from '../module-layout/module-layout.component';
import useSpotLayout from './spot-layout.controller';

export interface SpotLayoutProps {
    dispatch: ThunkDispatch<any, any, Action>;
    spot: Spot | string;
    highlighted?: string[]; //slot id array
    disabled?: string[]; //slot id array
    onClick?: (id: string) => void;
}

export const SpotLayout = (props: SpotLayoutProps) => {
    const viewProps = useSpotLayout(props);

    return (
        <Canvas>
            <ambientLight intensity={Math.PI / 2} />
            <spotLight
                position={[10, 10, 10]}
                angle={0.15}
                penumbra={1}
                decay={0}
                intensity={Math.PI}
            />
            <pointLight
                position={[-10, -10, -10]}
                decay={0}
                intensity={Math.PI}
            />
            {viewProps.data.map((m) => (
                <ModuleLayout
                    highlighted={props.highlighted}
                    dispatch={props.dispatch}
                    module={m}
                    key={m.id}
                    onClick={props.onClick}
                />
            ))}
            <OrbitControls />
        </Canvas>
    );
};
