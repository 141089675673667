import { WebsocketState } from '@frontend/pub-sub';
import { MdSignalWifiStatusbarConnectedNoInternet } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import useConnectionErrorAlert from './connection-error-alert.controller';

const ID = 'connection-error-alert';
export interface ConnectionErrorAlertProps {}

const ConnectionErrorAlert = (props: ConnectionErrorAlertProps) => {
    const viewProps = useConnectionErrorAlert(props);

    return (
        <div id={ID}>
            {viewProps.status != WebsocketState.CONNECTED && (
                <div className='alert alert-warning'>
                    <span className='d-flex flex-row align-items-center justify-content-between fs-4'>
                        <span>
                            <MdSignalWifiStatusbarConnectedNoInternet className='me-3 fs-2' />
                            {viewProps.status === WebsocketState.ERROR ? (
                                <FormattedMessage
                                    id='Terminal.Navbar.ConnectionWarning.Error'
                                    description='Message on the terminal telling the user that there is no connection to the network (based on websockets)'
                                    defaultMessage='Network connection error'
                                />
                            ) : (
                                <FormattedMessage
                                    id='Terminal.Navbar.ConnectionWarning.Reconnecting'
                                    description='Message on the terminal telling the user that the terminal is trying to reconect (based on websockets)'
                                    defaultMessage='Trying to connect...'
                                />
                            )}
                        </span>

                        {viewProps.status === WebsocketState.ERROR && viewProps.connectionTimeout !== null && (
                            <span>
                                <FormattedMessage
                                    id='Terminal.Navbar.ConnectionWarning.ReconectTryAgain'
                                    description='Message on the terminal telling the user when the terminal will try to reconnect'
                                    defaultMessage='trying again in'
                                />
                                {' ' + viewProps.connectionTimeout + ' '}
                                <FormattedMessage
                                    id='Terminal.Navbar.ConnectionWarning.Seconds'
                                    description='Literally the word second(s)'
                                    defaultMessage='second(s)'
                                />
                            </span>
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ConnectionErrorAlert;
