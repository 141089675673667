import { AuthenticationManager } from '@frontend/authentication-v2';
import { useAppSelector } from '@frontend/common';
import { DriverType } from '@frontend/edge/types';
import { PackageClient } from '@frontend/package/api';
import { Package } from '@frontend/package/types';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { Transaction, TransactionStateName, TransactionTriggerName, TransactionType } from '@frontend/transaction/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../../redux/user-interface-navigation-slice';
import { WarehouseProductPickDropProps } from './warehouse-product-pick-drop.component';

interface ViewProps {
    loading: boolean;
    checkoutBasket: boolean;
    finished: boolean;
    packages: Package[];
    transaction: Transaction | null;
    changePackages: React.Dispatch<React.SetStateAction<Package[]>>;
    createTransaction: () => Promise<Transaction>;
    changeCheckoutBasket: (value: boolean) => void;
    confirmBasket: () => void;
}

const useWarehouseProductPickDrop = (props: WarehouseProductPickDropProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [transaction, changeTransaction] = useState<Transaction | null>(null);
    const [packages, changePackages] = useState<Package[]>([]);
    const [checkoutBasket, changeCheckoutBasket] = useState<boolean>(false);
    const [finished, changeFinished] = useState<boolean>(false);
    const [loading, changeLoading] = useState<boolean>(false);

    useEffect(() => {
        if (navigationState.user?.id) {
            TransactionClient.fetchTransactions({
                user_id: navigationState.user?.id,
                account_id: props.userInterface.account_id,
                state: TransactionStateName.TRANSACTION_CREATED
            }).then((result) => {
                if (result.results.length > 0) {
                    changeTransaction(result.results[0]);
                    PackageClient.fetchAccountTransactionPackages(result.results[0].account_id, result.results[0].id).then((result) => {
                        changePackages(result.results);
                    });
                }
            });
        }
    }, []);

    const createTransaction = async () => {
        return await TransactionClient.postTransaction(props.userInterface.account_id, {
            user_id: navigationState.user?.id,
            workflow_id: props.userInterface.data.transactionWorkflowId,
            iot_id: null,
            state: TransactionStateName.TRANSACTION_CREATED,
            type: TransactionType.WAREHOUSE
        }).then((result) => {
            changeTransaction(result);
            return result;
        });
    };

    const confirmBasket = () => {
        changeLoading(true);
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((token) => {
                TransactionWorkflowClient.triggerTransactionState(transaction!.account_id, transaction!.id, {
                    trigger: TransactionTriggerName.TRANSACTION_PROCESS,
                    source: { driver_type: DriverType.TERMINAL, driver_id: token.entity_id }
                })
                    .then(() => {
                        changeFinished(true);
                    })
                    .finally(() => {
                        changeLoading(false);
                    });
            });
    };

    return {
        loading,
        checkoutBasket,
        finished,
        packages,
        transaction,
        changePackages,
        changeCheckoutBasket,
        createTransaction,
        confirmBasket
    };
};

export default useWarehouseProductPickDrop;
