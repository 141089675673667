import React from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import useIdleTimer from './idle-timer.controller';

export const IDLE_TIMER_KEY = 'inactivity_timer_enabled';
const ID = 'idle-timer';
export interface IdleTimerProps {
    idleCallback?: () => void;
}

/**
 * Currently tailored towards mobile (but easily updated to a desktop compatible version)
 * @param props
 * @returns
 */
export const IdleTimer = (props: IdleTimerProps) => {
    const viewProps = useIdleTimer(props);

    if (!viewProps.enabled) return <></>;
    if (viewProps.almostInActive) {
        const root = document.getElementById('app-content');
        if (!root) throw new Error('Root node not found. Cannot render modal.');

        return createPortal(
            <div
                className='modal display-block'
                aria-modal='true'
                role='dialog'
                id={ID}>
                <div className='modal-mobile-prompt small'>
                    <div className='d-flex flex-column justify-content-center align-items-center text-center m-2 fs-5'>
                        <span>
                            <FormattedMessage
                                id='IdleTimer.InactivityMessage'
                                description='Message displayed on the screen when the user has been inactive for a while.'
                                defaultMessage='Returning to home screen due to inactivity in {redirectInSeconds} seconds'
                                values={{ redirectInSeconds: viewProps.timeUntilInactive }}
                            />
                        </span>
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-primary w-100'>Stay active</button>
                    </div>
                </div>
            </div>,
            root
        );
    }
    return <></>;
};

export default IdleTimer;
