import { Spinner } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import useOnDutyList from './on-duty-list.controller';
import { PharmacistCard } from './pharmacist-card/pharmacist-card';

export const OnDutyList = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyList(props);

    if (!viewProps.pharmacistList) {
        return <Spinner />;
    }

    return (
        <div className='h-100'>
            <div
                className={`d-flex ${viewProps.pharmacistList.length === 0 ? 'h-80 p-7' : 'mx-3 p-1'} flex-column align-items-center align-content-center justify-content-center align-self-center`}>
                <span className={`text-center  font-weight-bolder ${viewProps.pharmacistList.length === 0 ? 'fs-1' : 'fs-4'}`}>
                    <FormattedMessage
                        defaultMessage='To find the pharmacy on night duty, call between 10 PM and 9 AM at: 078 05 17 33 (local rate).'
                        id='on-duty-list.header'
                    />
                </span>
            </div>
            <div className='d-flex flex-column mx-3'>
                {viewProps.pharmacistList.map((pharmacist) => (
                    <PharmacistCard
                        pharmacist={pharmacist}
                        key={pharmacist.id}
                    />
                ))}
            </div>
            <div className='d-flex justify-content-center mx-3 font-weight-bolder mt-3'>
                <FormattedMessage
                    id='on-duty-list.footer'
                    defaultMessage={'This information is retrieved from {link}'}
                    values={{
                        link: 'www.apotheek.be'
                    }}
                />
            </div>
        </div>
    );
};
