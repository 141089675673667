import { EventListener } from '@frontend/pub-sub';
import { UserInterface } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class UserInterfaceEventListener extends EventListener<UserInterface> {
    private static instance: UserInterfaceEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('user-interface', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): UserInterfaceEventListener {
        if (this.instance == null) {
            this.instance = new UserInterfaceEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<UserInterface> {
        return UserInterfaceEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
