import { PaginatedResponse } from '@frontend/api-utils';

export interface UserInterface {
    id: string;
    name: string;
    platform: 'mobile' | 'terminal';
    type: UserInterfaceType;
    account_id: string;
    data: any;
    update_timestamp?: string;
    creation_timestamp: string;
}

export type UserInterfaceListResponse = PaginatedResponse<UserInterface>;
export enum UserInterfaceQueryParams {
    ACCOUNT_ID = 'account_id',
    TYPE = 'type',
    PLATFORM = 'platform'
}
export interface CreateUserInterface {
    name?: string;
    platform?: 'mobile' | 'terminal';
    type?: UserInterfaceType;
    data?: any;
}

export interface UpdateUserInterface {
    name?: string | null;
    platform?: 'mobile' | 'terminal' | null;
    type?: UserInterfaceType | null;
    data?: any;
}

export enum UserInterfaceType {
    //S4M TERMINAL
    CAROUSEL = 'Carousel',
    LATEST_PRODUCTS_SELECT = 'Latest products select',
    ALL_PRODUCTS_SELECT = 'All products select',
    PRODUCT_BY_CATEGORY_SELECT = 'Product by category select',
    REPLENISHMENT = 'Replenishment',
    TRANSACTION_PROCESSING = 'Transaction processing',
    STOCK_ADJUSTMENTS = 'Stock adjustments',
    STOCK_OVERVIEW = 'Stock overview',
    RETURN_EQUIPMENT = 'Return equipment',
    MAINTAIN_EQUIPEMENT = 'Maintain equipment',
    REPORT_EQUIPMENT = 'Report equipment',

    //TERMINAL
    PUDO_DROP_LIST = 'List of pudo drop transactions',
    PUDO_PICK_LIST = 'List of pudo pick transactions',
    SLOT_OPENED = 'Slot opened',
    OPEN_DROPOFF = 'Open dropoff',
    PACKAGE_PROCESSED = 'Package processed',
    DELIVERY_DROPOFF = 'Delivery DropOff',
    ON_DUTY_PHARMACIST = 'On duty pharmacist',
    ON_DUTY_PHARMACIST_DROP_OFF = 'On duty pharmacist drop off',
    ON_DUTY_PHARMACIST_PICK_UP = 'On duty pharmacist pick up',
    ON_DUTY_CUSTOMER = 'On duty customer',
    ON_DUTY_CUSTOMER_DROP_OFF = 'On duty customer drop off',
    ON_DUTY_CUSTOMER_PICK_UP = 'On duty customer pick up',
    ON_DUTY_LIST = 'On duty list',
    ON_DUTY_PHARMACIST_ACTION = 'On duty pharmacist action',
    RESET_PHARMACY = 'Reset pharmacy',

    //OLD
    BUTTON_DASHBOARD = 'Button dashboard',
    ENTER_PIN = 'Enter pin',
    RETURN_PRODUCT = 'Return product',
    PRODUCT_NOT_IN_USE = 'Product not in use',
    PACKAGE_PROCESSING = 'Package processing',
    SCAN_BADGE = 'Scan badge',
    PICKUP_UI = 'Pickup UI',
    CHOOSE_ACK_SLOT = 'Choose/acknoledge slot',
    DROPOFF_UI = 'Dropoff UI',
    CREATE_TRANSACTION_UI = 'Create transaction UI',
    WAREHOUSE_PRODUCT_PICK_AND_DROP = 'Warehouse product pick and drop',

    //MOBILE
    NAVBAR_BUTTONS = 'Navbar buttons',
    SCAN_CODE = 'Scan code',
    AUTHENTICATION_QR = 'Authentication QR',
    PROFILE = 'Profile'
}

export const mobileInterfaceTypeInformation = new Map<UserInterfaceType, string[]>([
    [
        UserInterfaceType.NAVBAR_BUTTONS,
        ['Used as the main form of navigation on the mobile app. This will always be visible on the mobile app.', 'Will use buttons to navigate.']
    ],
    // [UserInterfaceType.SCAN_CODE, ['A screen that allows the user to scan a code.', 'This will post a scan on the linked scanning device.']],
    [UserInterfaceType.AUTHENTICATION_QR, ['A screen that shows a QR code linked to the user. Allowing the user to authenticate.']]
    // [UserInterfaceType.PROFILE, ['Screen showing the user profile information and allows the user to manage it.']],
]);

export const terminalInterfaceTypeInformation = new Map<UserInterfaceType, string[]>([
    [UserInterfaceType.CAROUSEL, ['A screen that displays a carousel of images and/or videos.']],
    [UserInterfaceType.LATEST_PRODUCTS_SELECT, ['A screen that displays the latest products for a user to select.']],
    [UserInterfaceType.ALL_PRODUCTS_SELECT, ['A screen that displays the all products for a user to select.']],
    [UserInterfaceType.PRODUCT_BY_CATEGORY_SELECT, ['A screen that displays categories and trickles down to products for a user to select.']],
    [UserInterfaceType.BUTTON_DASHBOARD, ['A screen with configurable buttons, used as the main form of navigation.', 'Will use buttons to navigate.']],
    [
        UserInterfaceType.ENTER_PIN,
        [
            'Allows the user to enter a pin code, and if multiple pin codes are found also presents the user to a challenge.',
            'Triggers PACKAGE_CODE_SCANNED when a correct pin code has been entered.'
        ]
    ],
    [UserInterfaceType.PUDO_DROP_LIST, ['A screen that displays a list of pudo transactions in the ready for dropoff state']],
    [UserInterfaceType.REPLENISHMENT, ['A screen that allows the user to replenish the machines stock.']],
    [
        UserInterfaceType.TRANSACTION_PROCESSING,
        ['Informative screen telling the user that the transaction is being processed. Will keep the user informed regarding the status of the slots.']
    ],
    [UserInterfaceType.STOCK_ADJUSTMENTS, ['Table showing current stock by slot that also allows adjustments.']],
    [UserInterfaceType.STOCK_OVERVIEW, ['Table showing current stock.']],
    [
        UserInterfaceType.RETURN_EQUIPMENT,
        ['UI used to return equipment if it is in the correct state. Otherwise it will show basic information about the equipment.']
    ],
    [UserInterfaceType.MAINTAIN_EQUIPEMENT, ['UI used to maintain equipment. Allows the user to take out equipment by status.']],
    [
        UserInterfaceType.REPORT_EQUIPMENT,
        ['UI used to report problems with equipment. This page will list the equipment that is currently linked to the user.']
    ],
    // [UserInterfaceType.RETURN_PRODUCT, ['Allows the user to return a product.', 'Creates a transaction, package and triggers TRANSACTION_PROCESS when submitted.']],
    // [UserInterfaceType.PRODUCT_NOT_IN_USE, ['Informative screen telling the user that the product is not in use. If found it will also show the SLOT where it is located.']],
    // [UserInterfaceType.PACKAGE_PROCESSING, ['Informative screen telling the user that the package is being processed. Should be used as a intermediate screen.']],
    [UserInterfaceType.SCAN_BADGE, ['Screen simply telling the user a badge needs to be scanned before any further actions can be taken.']],
    // [UserInterfaceType.CREATE_TRANSACTION_UI, ['Allows users to create a new (PuDo) transaction (and fill in variables) on the terminal.', 'Creates a transaction and triggers TRANSACTION_PROCESS when submitted.']],
    [UserInterfaceType.PICKUP_UI, ['Informative screen telling the user to pick up the package.']],
    [UserInterfaceType.DROPOFF_UI, ['Informative screen telling the user to dorpoff the package.']],
    [
        UserInterfaceType.CHOOSE_ACK_SLOT,
        [
            'Allows users to acknowledge and/or change the SLOT if allowed.',
            'Updates package state to PACKAGE_DROP_OFF_STARTED or PACKAGE_PICK_UP_STARTED (depending on the current package state) when the user confirms. And will also add and remove SLOT constraints when updating the SLOT of the package.'
        ]
    ],
    [UserInterfaceType.OPEN_DROPOFF, ['A screen where the user selects who they are dropping for from a list of suppliers']],
    [UserInterfaceType.PACKAGE_PROCESSED, ['A screen that displays the package that has been processed']],
    [UserInterfaceType.PUDO_PICK_LIST, ['A screen that displays a list of pudo transactions in the ready for pick state']],
    [UserInterfaceType.DELIVERY_DROPOFF, ['A screen for the dropoff of a delivery person']],
    [UserInterfaceType.ON_DUTY_PHARMACIST, ['A screen for the pharmacist to start the on duty hatch process']],
    [UserInterfaceType.ON_DUTY_PHARMACIST_DROP_OFF, ['A screen for the pharmacist to drop off a package']],
    [UserInterfaceType.ON_DUTY_PHARMACIST_PICK_UP, ['A screen for the pharmacist to pick up a package']],
    [UserInterfaceType.ON_DUTY_CUSTOMER, ['A screen for the customer to follow the on duty process']],
    [UserInterfaceType.ON_DUTY_CUSTOMER_DROP_OFF, ['A screen for the customer to drop off a package']],
    [UserInterfaceType.ON_DUTY_CUSTOMER_PICK_UP, ['A screen for the customer to pick up a package']],
    [UserInterfaceType.ON_DUTY_LIST, ['A screen for the customer to see the on duty list']],
    [UserInterfaceType.ON_DUTY_PHARMACIST_ACTION, ['A screen for the pharmacist to take action on the on duty list']],
    [UserInterfaceType.RESET_PHARMACY, ['A screen for the pharmacist to reset the pharmacy']],
    // [UserInterfaceType.WAREHOUSE_PRODUCT_PICK_AND_DROP, ['Allows the user to select one or more products.', 'Creates a transaction and one package for each product. Triggers TRANSACTION_PROCESS when submitted.']],
]);
