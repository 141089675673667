import { SelectInput } from '@frontend/basic-forms';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { ActionMeta } from 'react-select';
import useIoTActionSelect from './iot-action-select.controller';

const ID = 'IoT-action-select';
export interface IoTActionSelectProps {
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: { value: string; label: any }, actionMeta: ActionMeta<any>) => void;
    value?: string;
    dispatch?: ThunkDispatch<any, any, Action>;
}

export const IoTActionSelect = (props: IoTActionSelectProps) => {
    const viewProps = useIoTActionSelect(props);

    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'IoT action'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(value, actionMeta) => props.onChange(value as { value: string; label: any }, actionMeta)}
        />
    );
};

export default IoTActionSelect;
