import { Logger } from '@frontend/Logger';
import { fetchUserInterfaces } from '@frontend/user-interface/redux';
import { UserInterface } from '@frontend/user-interface/types';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { UiRendererProps } from './ui-renderer.component';

interface ViewProps {
    userInterface: UserInterface | null;
    isLoading: boolean;
}

const useUiRenderer = (props: UiRendererProps): ViewProps => {
    const dispatch = useAppDispatch();
    const UIState = useAppSelector((state) => state.userInterfaces);
    const [userInterface, changeUserInterface] = useState<UserInterface | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (UIState && UIState.userInterfaces && UIState.userInterfaces.results) {
            changeIsLoading(false);
            const found = UIState.userInterfaces.results.find((ui) => ui.id == props.userInterfaceId);
            if (found) {
                Logger.log('Found user interface', {}, found);
                changeUserInterface(found);
            }
        } else changeIsLoading(true);
    }, [props.userInterfaceId, UIState]);

    useEffect(() => {
        dispatch(fetchUserInterfaces({}));
    }, []);

    return {
        userInterface,
        isLoading
    };
};

export default useUiRenderer;
