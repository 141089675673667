import { Spinner } from '@frontend/elements';
import { IdleTimer } from '@frontend/idle-timer';

import useHomePage from './home-page.controller';
import UiRenderer from './ui-renderer/ui-renderer.component';

const ID = 'home-page';

const HomePage = () => {
    const viewProps = useHomePage();

    if (viewProps.isLoading || viewProps.view == null)
        return (
            <div id={ID}>
                <Spinner />
            </div>
        );

    return (
        <>
            <UiRenderer userInterfaceId={viewProps.view} />
            <IdleTimer
                enabled={viewProps.idleTimerEnabled}
                idleCallback={viewProps.idleTimerCallback}
            />
        </>
    );
};

export default HomePage;
