import { EntityType, useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageClient } from '@frontend/package/api';
import { PackageType } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { navigationStore } from '@frontend/terminal/shared';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { TransactionStateName, TransactionType } from '@frontend/transaction/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

const useOnDutyPharmacist = (props: UserInterfaceProps) => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const transactionWorkflowId = props.userInterface.data?.transactionWorkflowId;
    const packageWorkflowId = props.userInterface.data?.packageWorkflowId;
    const [slot, changeSlot] = useState<Slot | undefined>(undefined);
    const [transactionCreated, changeTransactionCreated] = useState<boolean>(false);
    const [foundTransaction, changeFoundTransaction] = useState<boolean>(true);

    useEffect(() => {
        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_PHARMACIST });
            const spotId = navigationState.settings?.results?.find((setting) => setting.key === 'spot_id')?.value;
            SlotClient.fetchSlots({ account_id: navigationState.iot.account_id, type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then(
                (slots) => {
                    if (slots.results.length === 1) {
                        changeSlot(() => slots.results[0]);
                    }
                }
            );
            TransactionClient.fetchTransactions({
                type: TransactionType.PASS_THROUGH,
                account_id: navigationState.iot.account_id,
                spot_id: spotId as string | undefined,
                state: TransactionStateName.TRANSACTION_PROCESSING
            }).then((transactions) => {
                if (transactions.results.length === 0) {
                    changeFoundTransaction(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (slot && transactionWorkflowId && packageWorkflowId && navigationState.iot && !transactionCreated && !foundTransaction) {
            changeTransactionCreated(true);
            TransactionClient.postTransaction(navigationState.iot.account_id, {
                type: TransactionType.PASS_THROUGH,
                iot_id: navigationState.iot.id,
                workflow_id: transactionWorkflowId
            }).then((transaction) => {
                PackageClient.postPackage(transaction.account_id, transaction.id, {
                    type: PackageType.PASS_THROUGH,
                    workflow_id: packageWorkflowId,
                    spot_id: slot.spot_id,
                    module_id: slot.module_id,
                    slot_id: slot.id,
                    priority: 1
                }).then((_package) => {
                    const variables = [];
                    const user = navigationState.user;
                    variables.push(
                        PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                            workflow_id: _package.workflow_id,
                            name: 'from_entity_id',
                            type: 'entity_id',
                            value: user!.id
                        })
                    );
                    variables.push(
                        PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                            workflow_id: _package.workflow_id,
                            name: 'from_entity_type',
                            type: 'entity_type',
                            value: EntityType.USER
                        })
                    );

                    Promise.all(variables).then(() => {
                        TransactionWorkflowClient.updateTransactionState(transaction.account_id, transaction.id, TransactionStateName.TRANSACTION_PROCESSING);
                    });
                });
            });
        }
    }, [slot, transactionCreated, foundTransaction]);
};

export default useOnDutyPharmacist;
