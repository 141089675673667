import { Logger } from '@frontend/Logger';
import { Spinner } from '@frontend/elements';
import {
    ButtonDashboard,
    DeliveryDropoff,
    EnterPin,
    OnDutyCustomer,
    OnDutyCustomerDropOff,
    OnDutyCustomerPickUp,
    OnDutyList,
    OnDutyPharmacist,
    OnDutyPharmacistAction,
    OnDutyPharmacistDropOff,
    OnDutyPharmacistPickUp,
    OpenDropoffCouriers,
    PackageProcessed,
    PudoDropList,
    PudoDropoff,
    PudoPickList,
    PudoPickup
} from '@frontend/terminal/templates';
import { ScanBadge } from '@frontend/user-interface-templates';
import { UserInterfaceType } from '@frontend/user-interface/types';
import ResetPharmacy from 'libs/terminal/templates/src/lib/reset-pharmacy/reset-pharmacy.component';

import { useAppDispatch } from '../../redux/store';
import useUiRenderer from './ui-renderer.controller';

export interface UiRendererProps {
    userInterfaceId: string;
}

const UiRenderer = (props: UiRendererProps) => {
    const dispatch = useAppDispatch();
    const viewProps = useUiRenderer(props);
    if (viewProps.isLoading) return <Spinner />;
    Logger.log('UI found: ' + viewProps.userInterface?.type);
    switch (viewProps.userInterface?.type) {
        case UserInterfaceType.BUTTON_DASHBOARD:
            return (
                <ButtonDashboard
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.SCAN_BADGE:
            return <ScanBadge />;
        case UserInterfaceType.ENTER_PIN:
            return (
                <EnterPin
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.PUDO_DROP_LIST:
            return <PudoDropList />;
        case UserInterfaceType.DROPOFF_UI:
            return (
                <PudoDropoff
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.PICKUP_UI:
            return (
                <PudoPickup
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.PACKAGE_PROCESSED:
            return (
                <PackageProcessed
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.OPEN_DROPOFF:
            return (
                <OpenDropoffCouriers
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.PUDO_PICK_LIST:
            return <PudoPickList />;
        case UserInterfaceType.DELIVERY_DROPOFF:
            return (
                <DeliveryDropoff
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_PHARMACIST:
            return (
                <OnDutyPharmacist
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_CUSTOMER:
            return <OnDutyCustomer />;
        case UserInterfaceType.ON_DUTY_PHARMACIST_PICK_UP:
            return (
                <OnDutyPharmacistPickUp
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_CUSTOMER_DROP_OFF:
            return (
                <OnDutyCustomerDropOff
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_CUSTOMER_PICK_UP:
            return (
                <OnDutyCustomerPickUp
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_PHARMACIST_DROP_OFF:
            return (
                <OnDutyPharmacistDropOff
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_LIST:
            return (
                <OnDutyList
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        case UserInterfaceType.ON_DUTY_PHARMACIST_ACTION:
            return <OnDutyPharmacistAction />;
        case UserInterfaceType.RESET_PHARMACY:
            return (
                <ResetPharmacy
                    dispatch={dispatch}
                    userInterface={viewProps.userInterface}
                />
            );
        default:
            return <div>oops something went wrong</div>;
    }
};

export default UiRenderer;
