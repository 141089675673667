import { Spinner } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SlotOpening from '../../slot-status/slot-opening/slot-opening.component';
import PackageCard from '../package-card/package-card.component';
import usePudoPickList from './pudo-pick-list.controller';

export const PudoPickList = () => {
    const viewProps = usePudoPickList();

    if (viewProps.isLoading) {
        return (
            <div className='card'>
                <div className='card-body'>
                    <Spinner />
                </div>
            </div>
        );
    }

    if (viewProps.isPicking && viewProps.pickPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.pickPackage.account_id,
                    spot_id: viewProps.pickPackage.spot_id,
                    module_id: viewProps.pickPackage.module_id,
                    slot_id: viewProps.pickPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.packages.length === 0) {
        return (
            <div className='d-flex justify-content-center'>
                <h3>
                    <FormattedMessage
                        id='pudo-list.no-pick-up'
                        defaultMessage='No PickUp found'
                    />
                </h3>
            </div>
        );
    }
    return (
        <>
            <div className='d-flex justify-content-center'>
                <h3>
                    <FormattedMessage id='pudo-pick-list.pick-up-list' defaultMessage='PickUp list' />
                </h3>
            </div>
            <div className='d-flex row'>
                {viewProps.packages
                    .sort((a, b) => a.package.creation_timestamp.localeCompare(b.package.creation_timestamp))
                    .map((pack) => (
                        <PackageCard
                            packageInfo={pack}
                            key={pack.package.id}
                            onPickUp={viewProps.onPickUp}
                        />
                    ))}
            </div>
        </>
    );
};
