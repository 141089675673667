import { StringPlaceholder } from "@frontend/elements";
import { UserInterfaceType } from "@frontend/user-interface/types";
import React from "react";
import { UserInterfaceProps } from "../types/user-interface";
import usePudoDropoff from "./pudo-dropoff.controller";

export const PudoDropoff = (props: UserInterfaceProps) => {
    const viewProps = usePudoDropoff(props);
    return (
        <div
            id={UserInterfaceType.DROPOFF_UI}
            className='d-flex flex-column justify-content-center'>
            <h3 className='pb-4 text-center'>
                SLOT{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                is open.
            </h3>
            <h3 className='pb-4 text-center'>You can now drop off your package. Don&apos;t forget to close the door afterwards.</h3>
        </div>
    );
};

