import { TextInput } from '@frontend/basic-forms';
import React, { useEffect, useState } from 'react';

interface AsyncSearchProps {
    id?: string;
    filterValue?: (value: string) => void;
    placeholder?: string;
    label?: React.ReactNode | null;
    resetFilter?: boolean;
    className?: string;
}

export const Filter = (props: AsyncSearchProps) => {
    const [filterValue, changeFilterValue] = useState<string>('');

    useEffect(() => {
        if (props.resetFilter) {
            changeFilterValue('');
        }
    }, [props.resetFilter]);
    useEffect(() => {
        const timeOutId = setTimeout(() => filter(filterValue), 300);
        return () => clearTimeout(timeOutId);
    }, [filterValue]);

    const filter = async (value: string) => {
        props.filterValue && props.filterValue(value);
    };

    return (
        <TextInput
            className={props.className}
            id={props.id ? props.id : 'Search'}
            label={props.label !== undefined ? props.label : 'Search'}
            placeholder={props.placeholder ? props.placeholder : 'Search here...'}
            value={filterValue}
            useConditionedStyling={false}
            onChange={changeFilterValue}
            submitted={false}
        />
    );
};
