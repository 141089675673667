import { Module } from '@frontend/module/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useRef } from 'react';
import { Group } from 'three';

import { ModuleItem } from './module-item/module-item.component';
import useModuleLayout from './module-layout.controller';

const ID = 'module-layout';
export interface ModuleLayoutProps {
    dispatch: ThunkDispatch<any, any, Action>;
    module: Module;
    highlighted?: string[]; //slot id array
    disabled?: string[]; //slot id array
    onClick?: (id: string) => void;
}

export const ModuleLayout = (props: ModuleLayoutProps) => {
    const viewProps = useModuleLayout(props);
    const groupRef = useRef<Group>(null!);

    if (viewProps.data.length === 0 || !viewProps.module) return null;

    return (
        <group
            ref={groupRef}
            position={viewProps.position}
            rotation={viewProps.rotation}>
            {viewProps.data.map((s) => {
                if (!s.location || !s.dimension) return null;

                return (
                    <ModuleItem
                        key={s.index}
                        slot={s}
                        highlighted={props.highlighted}
                        disabled={props.disabled}
                        moduleRotation={viewProps.module!.location.rotation}
                        onClick={props.onClick}
                    />
                );
            })}
        </group>
    );
};
