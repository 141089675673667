import { Slot } from '@frontend/slot/types';
import { Text } from '@react-three/drei';
import React, { useRef } from 'react';
import { Mesh } from 'three';

import useModuleItem from './module-item.controller';

export interface ModuleItemProps {
    slot: Slot;
    highlighted?: string[];
    disabled?: string[];
    moduleRotation: number;
    onClick?: (id: string) => void;
}

export const ModuleItem = (props: ModuleItemProps) => {
    const viewProps = useModuleItem(props);
    const meshRef = useRef<Mesh>(null!);

    return (
        <>
            <Text
                fontSize={0.1}
                anchorY='middle'
                anchorX='center'
                lineHeight={1}
                color={'white'}
                scale={viewProps.scale}
                position={viewProps.position}
                rotation={viewProps.rotation}
                material-toneMapped={false}>
                {viewProps.displayName}
            </Text>
            <mesh
                {...viewProps.mesh}
                ref={meshRef}
                scale={1}
                onClick={(event) => {
                    event.stopPropagation();
                    props.onClick && props.onClick(event.object.uuid);
                }}>
                <boxGeometry args={viewProps.dimension} />
                <meshStandardMaterial color={viewProps.disabled ? 'gray' : viewProps.highlighted ? 'red' : 'black'} />
            </mesh>
        </>
    );
};
