import { EntityType, useAppSelector } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PlaylistClient, PlaylistItemClient } from '@frontend/playlist/api';
import { PlaylistItem } from '@frontend/playlist/types';
import { useDocumentRepository } from '@frontend/repository';
import { navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    url: string | null;
}

const useCarousel = (props: UserInterfaceProps): ViewProps => {
    const documentRepository = useDocumentRepository();
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [playlistItems, changePlaylistItems] = useState<PlaylistItem[] | null>(null);
    const [current, changeCurrent] = useState<PlaylistItem | null>(null);
    const [url, changeUrl] = useState<string | null>(null);

    useEffect(() => {
        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_PAYMENT }).then((iot) => {
                props.dispatch(updateIoTState({ iot_state: iot.state }));
            });
        }
    }, []);

    useEffect(() => {
        const playlist = props.userInterface.data.playlist_id;
        if (!navigationState.iot || !playlist) return;
        PlaylistClient.fetchAccountPlaylist(navigationState.iot.account_id, playlist)
            .then((playlist) => {
                PlaylistItemClient.fetchPlaylistItems({ playlist_id: playlist.id })
                    .then((result) => {
                        const ordered = result.results.filter((i) => i.visible).sort((a, b) => a.sort_order - b.sort_order);
                        changePlaylistItems(ordered);
                        documentRepository
                            .preLoadFiles(
                                EntityType.PLAYLIST_ITEM,
                                ordered.map((i) => i.id)
                            )
                            .then(() => {
                                if (ordered.length > 0) changeCurrent(ordered[0]);
                            });
                    })
                    .catch((error) => {
                        ErrorHandler.handleError({ error: error, entity_type: EntityType.PLAYLIST_ITEM });
                    });
            })
            .catch((error) => {
                ErrorHandler.handleError({ error: error, entity_type: EntityType.PLAYLIST });
            });
    }, []);

    useEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined;
        if (playlistItems && current) {
            documentRepository.getFiles(EntityType.PLAYLIST_ITEM, current.id).then((service) => {
                if (service.getDocuments().length > 0)
                    service
                        .getUrl(service.getDocuments()[0].id)
                        .then(changeUrl)
                        .catch((error) => ErrorHandler.handleError({ error: error }));
            });
            timeout = setTimeout(() => {
                const currentIndex = playlistItems.indexOf(current);
                if (currentIndex !== undefined) {
                    if (currentIndex + 1 < playlistItems.length) {
                        changeCurrent(playlistItems[currentIndex + 1]);
                    } else {
                        changeCurrent(playlistItems[0]);
                    }
                }
            }, current.duration * 1000);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [current]);

    return {
        url
    };
};

export default useCarousel;
