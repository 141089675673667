import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaDoorOpen } from 'react-icons/fa';

import useOnDutyOpenSlotButton from './on-duty-open-slot.controller';

const ID = 'open-slot-button';

export interface OnDutyOpenSlotButtonProps {
    callback: () => void;
}

export const OnDutyOpenSlotButton = (props: OnDutyOpenSlotButtonProps) => {
    const viewProps = useOnDutyOpenSlotButton(props);
    return (
        <div
            id={ID}
            className='w-md-50 w-xl-25 ratio ratio-1x1'>
            <div className='p-3'>
                <div
                    className={`card shadow-lg overflow-hidden h-100`}
                    onClick={viewProps.onClick}>
                    <div className='d-flex flex-column h-100'>
                        <div className='d-flex align-items-center justify-content-center h-75 placeholder-glow'>
                            <FaDoorOpen
                                size={190}
                                className='text-primary'
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center h-25 bg-primary'>
                            <h4 className='text-white placeholder-glow'>
                                <strong>{CommonMessage.BUTTONS.OPEN}</strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
