import { UserClient } from '@frontend/user/api';
import { seedUsers } from '@frontend/user/redux';
import { User } from '@frontend/user/types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { UserEventListener } from './user-event-listener';

declare global {
    interface WindowEventMap {
        'useUserdepr:request': CustomEvent<string>;
    }
}
const REQUEST_USER_EVENT = 'useUserdepr:request';
const STORAGE_KEY = 'CTS_REQ';

/**
 * @deprecated
 * @param userId 
 */
export function userRequestDepr(userId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_EVENT, { detail: userId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    enablePubSub: boolean;
}

/**
 * @deprecated
 * @param props 
 * @returns 
 */
export function useUsers(props: Props) {
    const [pending, changePending] = useState<string[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);
    const [userEventListener, changeUserEventListener] = useState<UserEventListener | null>(null);
    useEffect(() => {
        window.addEventListener(REQUEST_USER_EVENT, listener);
        if (props.enablePubSub) changeUserEventListener(UserEventListener.getInstance(props.dispatch));
    }, []);
    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }, [timeoutFinished]);
    useEffect(() => {
        if (pending.length > 0) {
            getUsers(pending).then((result) => {
                props.dispatch(seedUsers(result));
                changePending([]);
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
            });
        }
    }, [pending]);
    const listener = (event: CustomEvent) => {
        if (typeof event.detail == 'string') {
            if (timeoutSet === false) {
                changeTimeoutSet(true);
                setTimeout(() => {
                    changeTimeoutFinished(true);
                }, 1000);
            }
            const stored = localStorage.getItem(STORAGE_KEY);
            if (stored == null) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([event.detail]));
            } else if (!JSON.parse(stored).includes(event.detail)) {
                localStorage.setItem(STORAGE_KEY, JSON.stringify([...JSON.parse(stored), event.detail]));
            }
        }
    };
    return { userEventListener };
}
async function getUsers(userIds: string[]): Promise<User[]> {
    const response = await UserClient.resolveUsers(userIds);
    return response.results;
}
