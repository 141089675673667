import { Device } from '@frontend/device/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class DeviceEventListener extends EventListener<Device> {
    private static instance: DeviceEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('device', dispatch, variables);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): DeviceEventListener {
        if (this.instance == null) {
            this.instance = new DeviceEventListener(dispatch, variables);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<Device> {
        return DeviceEventListener.getInstance(dispatch, variables);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
