import { ErrorHandler } from '@frontend/error-handler';
import { SlotClient } from '@frontend/slot/api';
import { Slot } from '@frontend/slot/types';
import { useEffect, useState } from 'react';

import { SlotOpenedProps } from './slot-opened.component';

interface ViewProps {
    slot: Slot | null;
}

const useSlotOpened = (props: SlotOpenedProps): ViewProps => {
    const [slot, changeSlot] = useState<Slot | null>(null);
    useEffect(() => {
        SlotClient.fetchSlot(props.data.account_id, props.data.spot_id, props.data.module_id, props.data.slot_id)
            .then(changeSlot)
            .catch(ErrorHandler.handleError);
        props.callback && props.callback();
    }, []);

    return { slot }
};

export default useSlotOpened;
