import { EntityType, useAppSelector } from '@frontend/common';
import { Slot } from '@frontend/slot/types';
import { navigationStore } from '@frontend/terminal/shared';
import { useSelector } from 'react-redux';

import { PackageClient } from '@frontend/package/api';
import { Package } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { useEffect, useState } from 'react';
import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    slot: Slot | null;
}

const usePudoPickup = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const cache = navigationState.cache;
    const [_package, changePackage] = useState<Package | null>(null);
    const [slot, changeSlot] = useState<Slot | null>(null);
    useEffect(() => {
        if (cache && cache.data && cache.data.entity_type && cache.data.entity_type === EntityType.PACKAGE && navigationState.iot) {
            PackageClient.fetchPackages({ id: cache.data.entity_id }).then((res) => {
                const found = res.results.find((p) => p.id === cache.data.entity_id);
                changePackage(found ?? null);
            })
        }
    }, [])

    useEffect(() => {
        if (_package) {
            SlotClient.fetchSlot(_package.account_id, _package.spot_id, _package.module_id, _package.slot_id).then((res) => {
                changeSlot(res)
            })
        }
    }, [_package])

    return { slot }
};

export default usePudoPickup;
