import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { AggregatedStockListResponse, AggregatedStockQueryParams, CreateStock, GroupedAggregatedStockListResponse, GroupedAggregatedStockQueryParams, Stock, StockListResponse, StockQueryParams, UpdateStock } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class StockClient extends APIClient {
    public static async fetchStocks(queryParams?: ApiQueryParams<DefaultQueryParams | StockQueryParams>): Promise<StockListResponse> {
        return await this.apiPaginated<StockListResponse, DefaultQueryParams | StockQueryParams>(`${endpoint}/stocks`, queryParams);
    }

    public static async resolveStocks(stockIds: string[]): Promise<StockListResponse> {
        const response = await this.post(`${endpoint}/stocks-resolve`, { ids: stockIds });
        return await this.handleResponse<StockListResponse>(response);
    }

    public static async deleteStocks(stockIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/stocks-delete`, { ids: stockIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchSlotStocks(
        accountId: string,
        spotId: string,
        moduleId: string,
        slotId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<StockListResponse> {
        return await this.apiPaginated<StockListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/stocks`,
            queryParams
        );
    }

    public static async postStock(accountId: string, spotId: string, moduleId: string, slotId: string, stock: CreateStock): Promise<Stock> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/stocks`, stock);
        return await this.handleResponse<Stock>(response);
    }

    public static async fetchSlotStock(accountId: string, spotId: string, moduleId: string, slotId: string, stockId: string): Promise<Stock> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/stocks/${stockId}`);
        return await this.handleResponse<Stock>(response);
    }

    public static async patchStock(accountId: string, spotId: string, moduleId: string, slotId: string, stockId: string, body: UpdateStock): Promise<Stock> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/stocks/${stockId}`, body);
        return await this.handleResponse<Stock>(response);
    }

    public static async deleteStock(accountId: string, spotId: string, moduleId: string, slotId: string, stockId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/stocks/${stockId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchAggregatedStocks(accountId: string, spotId: string, queryParams?: ApiQueryParams<AggregatedStockQueryParams>): Promise<AggregatedStockListResponse> {
        return await this.apiPaginated<AggregatedStockListResponse, AggregatedStockQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/aggregated-stocks`, queryParams);
    }

    public static async fetchGroupedAggregatedStocks(accountId: string, spotId: string, queryParams?: ApiQueryParams<GroupedAggregatedStockQueryParams>): Promise<GroupedAggregatedStockListResponse> {
        return await this.apiPaginated<GroupedAggregatedStockListResponse, GroupedAggregatedStockQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/grouped-aggregated-stocks`, queryParams);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
