import { useLayoutEffect, useRef, useState } from 'react';

import { LayoutRendererProps } from './layout-renderer.component';

interface ViewProps {
    scale: number;
    layoutBox: React.RefObject<HTMLDivElement>;
    aspectRatio: number;
    containerStyle: {
        width: string;
        height: string;
    };
}

const useLayoutRenderer = (props: LayoutRendererProps): ViewProps => {
    const layoutBox = useRef<HTMLDivElement>(null);
    const [aspectRatio, changeAspectRatio] = useState(0);

    const [width, changeWidth] = useState('100%');
    const height = '100%';
    const containerStyle = {
        width: width,
        height: height
    };
    const currentLayoutBoxWidth = layoutBox ? (layoutBox.current ? layoutBox.current.clientWidth : null) : null;
    const currentLayoutBoxHeight = layoutBox ? (layoutBox.current ? layoutBox.current.clientHeight : null) : null;

    useLayoutEffect(() => {
        if (layoutBox && layoutBox.current && props.containerInfo.aspectRatio !== 0 && aspectRatio === 0) {
            const containerAspectRatio = layoutBox.current.clientWidth / layoutBox.current.clientHeight;
            if (props.containerInfo.aspectRatio >= containerAspectRatio) {
                changeWidth(`${props.containerInfo.aspectRatio * layoutBox.current.clientHeight}px`);
                changeAspectRatio(props.containerInfo.aspectRatio);
            } else {
                changeWidth(`${layoutBox.current.clientWidth}px`);
                changeAspectRatio(props.containerInfo.aspectRatio);
            }
        }
    }, [props.containerInfo.aspectRatio, aspectRatio, currentLayoutBoxWidth, currentLayoutBoxHeight]);

    return {
        layoutBox,
        scale: convertToDecimal(props.containerInfo.maxX / props.containerInfo.maxY),
        aspectRatio,
        containerStyle
    };
};

export default useLayoutRenderer;

function convertToDecimal(num: number) {
    while (num < 1) {
        num *= 10;
    }
    while (num > 1) {
        num /= 10;
    }
    return num;
}
