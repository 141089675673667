import React from 'react';

import { IContact } from '@frontend/contact/types';
import { CommonMessage } from '@frontend/lang';
import { FormattedMessage } from 'react-intl';
import { UserInterfaceProps } from '../types/user-interface';
import useContactList from './contact-list.controller';

export interface ContactListProps extends UserInterfaceProps {
    onClick: (contact: IContact) => void;
}

export const ContactList = (props: ContactListProps) => {
    const viewProps = useContactList(props);

    return (
        <div>
            <div className="d-flex justify-content-center">
                <h3>
                    <FormattedMessage
                        id='contact-list.select-receiver'
                        defaultMessage='Select a receiver'
                    />
                </h3>
            </div>
            <ul className='list-group w-100 m-1'>
                <li className='list-group-item list-group-item-primary'>
                    {CommonMessage.OBJECTS.CONTACT.PLURAL}
                </li>
                {viewProps.contacts.map((contact) => (
                    <li
                        className='list-group-item list-group-item'
                        onClick={() => props.onClick(contact)}
                        key={contact.id}>
                        <span className='d-flex flex-row justify-content-between'>
                            <span className='d-flex flex-row align-items-center'>
                                {contact.first_name} {contact.last_name}
                            </span>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
