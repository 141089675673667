import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { CreateGroup, Group, GroupListResponse, GroupQueryParams, UpdateGroup } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class GroupClient extends APIClient {
    public static async fetchGroups(queryParams?: ApiQueryParams<DefaultQueryParams | GroupQueryParams>): Promise<GroupListResponse> {
        return await this.apiPaginated<GroupListResponse, DefaultQueryParams>(`${endpoint}/groups`, queryParams);
    }

    public static async resolveGroups(groupIds: string[]): Promise<GroupListResponse> {
        const response = await this.post(`${endpoint}/groups-resolve`, { ids: groupIds });
        return await this.handleResponse<GroupListResponse>(response);
    }

    public static async deleteGroups(groupIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/groups-delete`, { ids: groupIds });
        return await this.handleVoidResponse(response);
    }

    public static async postGroup(accountId: string, group: CreateGroup): Promise<Group> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/groups`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async fetchGroup(accountId: string, groupId: string): Promise<Group> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/groups/${groupId}`);
        return await this.handleResponse<Group>(response);
    }

    public static async patchGroup(accountId: string, groupId: string, group: UpdateGroup): Promise<Group> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/groups/${groupId}`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async deleteGroup(accountId: string, groupId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/groups/${groupId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
