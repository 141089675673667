import { useAppSelector } from '@frontend/common';
import { IoTEnumClient } from '@frontend/iot/api';
import { IoTTrigger, IoTTriggerName } from '@frontend/iot/types';
import { fetchGeneralWorkflowTriggers, workflowSharedStore } from '@frontend/workflow/redux';
import { WorkflowTriggerName } from '@frontend/workflow/types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoTTriggerSelectProps } from './iot-trigger-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: IoTTriggerName | WorkflowTriggerName | string;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useIoTTriggerSelect = (props: IoTTriggerSelectProps): ViewProps => {
    const [triggers, changeTriggers] = useState<string[]>([]);
    const workflowSharedState = useAppSelector(useSelector, workflowSharedStore);

    useEffect(() => {
        props.dispatch && props.dispatch(fetchGeneralWorkflowTriggers());
        IoTEnumClient.fetchIoTTriggers().then(changeTriggers);
    }, []);

    const options = useMemo(() => {
        if (!triggers || !workflowSharedState.generalWorkflowTriggers) return [];
        return [...triggers, ...workflowSharedState.generalWorkflowTriggers].map((trigger) => {
            const found = IoTTrigger.ALL.find((t) => t.value === trigger);
            if (found) return { value: found.value, label: found.displayName };
            else
                return {
                    value: trigger,
                    label: trigger.replace(/_/g, ' ')
                };
        });
    }, [triggers, workflowSharedState.generalWorkflowTriggers]);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value };
};

export default useIoTTriggerSelect;
