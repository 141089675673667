import { EventListener } from '@frontend/pub-sub';
import { Slot } from '@frontend/slot/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class SlotEventListener extends EventListener<Slot> {
    private static instance: SlotEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('slot', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): SlotEventListener {
        if (this.instance == null) {
            this.instance = new SlotEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Slot> {
        return SlotEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
