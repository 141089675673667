import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import {
    CreateTransaction,
    PudoTransactionReportListResponse,
    SearchTransactionQueryParams,
    Transaction,
    TransactionListResponse,
    TransactionQueryParams,
    UpdateTransaction
} from '@frontend/transaction/types';

const endpoint = '/transaction-api/v1';
export class TransactionClient extends APIClient {
    public static async fetchTransactions(queryParams?: ApiQueryParams<DefaultQueryParams | TransactionQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams | TransactionQueryParams>(`${endpoint}/transactions`, queryParams);
    }

    public static async searchTransactions(queryParams?: ApiQueryParams<DefaultQueryParams | SearchTransactionQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams | SearchTransactionQueryParams>(
            `${endpoint}/transactions-search`,
            queryParams
        );
    }

    public static async resolveTransactions(transactionIds: string[]): Promise<TransactionListResponse> {
        const response = await this.post(`${endpoint}/transactions-resolve`, { ids: transactionIds });
        return await this.handleResponse<TransactionListResponse>(response);
    }

    public static async fetchAccountTransactions(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<TransactionListResponse> {
        return await this.apiPaginated<TransactionListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/transactions`, queryParams);
    }

    public static async postTransaction(accountId: string, createTransaction: CreateTransaction): Promise<Transaction> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions`, createTransaction);
        return await this.handleResponse<Transaction>(response);
    }

    public static async fetchTransaction(accountId: string, transactionId: string): Promise<Transaction> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}`);
        return await this.handleResponse<Transaction>(response);
    }

    public static async patchTransaction(accountId: string, transactionId: string, updateTransaction: UpdateTransaction): Promise<Transaction> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}`, updateTransaction);
        return await this.handleResponse<Transaction>(response);
    }

    public static async deleteTransaction(account_id: string, transactionId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${account_id}/transactions/${transactionId}`);
        return await this.handleVoidResponse(response);
    }

    public static async deleteTransactions(transactionIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/transactions-delete`, { ids: transactionIds });
        return await this.handleVoidResponse(response);
    }

    // Report
    /**
     * @deprecated
     * @param queryParams
     * @returns
     */
    public static async fetchReportPudoTransactions(
        queryParams?: ApiQueryParams<DefaultQueryParams | TransactionQueryParams>
    ): Promise<PudoTransactionReportListResponse> {
        return await this.apiPaginated<PudoTransactionReportListResponse, DefaultQueryParams | TransactionQueryParams>(
            `${endpoint}/report-pudo-transactions`,
            queryParams
        );
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
