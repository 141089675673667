import { SelectInput } from '@frontend/basic-forms';
import { IoTStateName } from '@frontend/iot/types';
import React from 'react';

import useIoTStateSelect from './iot-state-select.controller';

const ID = 'iot-state-select';
export interface IoTStateSelectProps {
    label?: string;
    helpMessage?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: { value: IoTStateName | string; label: any }) => void;
    value?: string | null;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
}

export const IoTStateSelect = (props: IoTStateSelectProps) => {
    const viewProps = useIoTStateSelect(props);
    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'IoT state'}
            helpMessage={props.helpMessage}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(value) => viewProps.onChange((value as { value: IoTStateName | string; label: any })?.value, props.allowURLChange)}
            useConditionedStyling={props.useConditionedStyling}
            isClearable={props.isClearable}
        />
    );
};
