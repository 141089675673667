// import { moduleStore } from '@frontend/module/redux';
import { Module } from '@frontend/module/types';
import { Slot } from '@frontend/slot/types';
import { Euler, Vector3 } from '@react-three/fiber';
import { useMemo } from 'react';

import { ModuleLayoutProps } from './module-layout.component';

interface ViewProps {
    module?: Module;
    data: Slot[];
    position?: Vector3 | undefined;
    rotation?: Euler | undefined;
}

/**
 * @deprecated no longer works
 * @param props
 * @returns
 */
const useModuleLayout = (props: ModuleLayoutProps): ViewProps => {
    // const moduleState = useAppSelector(useSelector, moduleStore);
    // const slotState = useAppSelector(useSelector, slotStore);

    // useEffect(() => {
    //     props.dispatch(
    //         fetchSpotModuleSlots({
    //             spotId: props.module.spot_id,
    //             moduleId: props.module.id,
    //             queryParams: { index: '0', size: '100' }
    //         })
    //     );
    // }, []);

    const module: Module | undefined = useMemo(() => {
        return undefined;
        // return moduleState.unordered.find((m) => m.id == props.module.id);
    }, []);

    // const data = useMemo(() => {
    //     if (
    //         !slotState ||
    //         !slotState.spotModuleSlots ||
    //         !slotState.spotModuleSlots[props.module.spot_id] ||
    //         !slotState.spotModuleSlots[props.module.spot_id][props.module.id]
    //     )
    //         return [];
    //     else return slotState.spotModuleSlots[props.module.spot_id][props.module.id].results;
    // }, [slotState]);

    return {
        data: [],
        module,
        position: /*module ? [module.location.x / 1000, module.location.y / 1000, module.location.z / 1000] : */ undefined,
        rotation: /*module?.location.rotation != 0 ? [0, Math.PI / -1, 0] : */ [0, 0, 0] as Euler
    };
};

export default useModuleLayout;
