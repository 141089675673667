import { Unknown } from '@frontend/iot/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class UnknownEventListener extends EventListener<Unknown> {
    private static instance: UnknownEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('iot', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): UnknownEventListener {
        if (this.instance == null) {
            this.instance = new UnknownEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Unknown> {
        return UnknownEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
