import { Spinner, StringPlaceholder } from "@frontend/elements";
import React from "react";
import useSlotOpening from "./slot-opening.controller";

export interface SlotOpeningProps {
    data: { account_id: string, spot_id: string, module_id: string, slot_id: string; }
}

const SlotOpening = (props: SlotOpeningProps) => {
    const viewProps = useSlotOpening(props);
    return (
        <div
            id='slot-opening'
            className='d-flex flex-column align-items-center justify-content-center m-3'>
            <h3 className='pb-4'>
                SLOT{' '}
                <StringPlaceholder
                    loaded={!!viewProps.slot}
                    className='col-2 bg-info'>
                    <span className='text-info'>{viewProps.slot?.number}</span>
                </StringPlaceholder>{' '}
                is opening.
            </h3>
            <Spinner />
        </div>
    );
};

export default SlotOpening;