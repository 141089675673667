import useInitialisation from './initialisation.controller';
import Provision from './provision/provision.component';

export const ID = 'initialisation';
interface Props {
    children: React.ReactNode;
}

const Initialisation = (props: Props) => {
    const viewProps = useInitialisation();

    return (
        <div
            id={ID}
            style={{ flex: '1' }}>
            {viewProps.provisioned ? props.children : <Provision provisionDone={viewProps.provisionDoneCallback} />}
        </div>
    );
};

export default Initialisation;
