import { PharmacyOnDuty } from '@frontend/apb/types';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PharmacistCard = (props: { pharmacist: PharmacyOnDuty }) => {
    return (
        <div className='card mt-3'>
            <div className='card-header'>
                <h4>{props.pharmacist.name}</h4>
            </div>
            <div className='card-body'>
                <p>
                    <strong>{CommonMessage.OBJECTS.ADDRESS.DEFAULT}:</strong> {props.pharmacist.address}
                </p>
                <p>
                    <strong>{Distance}:</strong> {props.pharmacist.distance} km
                </p>
                <p>
                    <strong>{CommonMessage.OBJECTS.COMMON_FIELDS.DESCRIPTION}:</strong> {props.pharmacist.description}
                </p>
                <p>
                    <strong>{OnDuty}:</strong> {new Date(props.pharmacist.on_duty_timestamp).toLocaleString()}
                </p>
            </div>
        </div>
    );
};

const Distance = (
    <FormattedMessage
        id='pharmacist-card.distance'
        defaultMessage='Distance'
    />
);
const OnDuty = (
    <FormattedMessage
        id='pharmacist-card.on-duty'
        defaultMessage='On Duty'
    />
);
