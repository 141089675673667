import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum TransactionActionName {
    TRANSACTION_CHANGE_STATE = 'transaction_change_state',
    TRANSACTION_PROCESS_PACKAGES = 'transaction_process_packages',
    TRANSACTION_ACKNOWLEDGE_PROCESSING = 'transaction_acknowledge_processing',
    TRANSACTION_ACKNOWLEDGE_PROCESSED = 'transaction_acknowledge_processed'
}

export class TransactionAction {
    static readonly TRANSACTION_CHANGE_STATE = new TransactionAction(
        TransactionActionName.TRANSACTION_CHANGE_STATE,
        (
            <FormattedMessage
                id='TransactionAction.change_state'
                description='The displayed value for the TransactionAction change_state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly TRANSACTION_PROCESS_PACKAGES = new TransactionAction(
        TransactionActionName.TRANSACTION_PROCESS_PACKAGES,
        (
            <FormattedMessage
                id='TransactionAction.process_packages'
                description='The displayed value for the TransactionAction process_packages'
                defaultMessage='Process packages'
            />
        )
    );

    static readonly TRANSACTION_ACKNOWLEDGE_PROCESSING = new TransactionAction(
        TransactionActionName.TRANSACTION_ACKNOWLEDGE_PROCESSING,
        (
            <FormattedMessage
                id='TransactionAction.acknowledge_processing'
                description='The displayed value for the TransactionAction acknowledge_processing'
                defaultMessage='Acknowledge processing'
            />
        )
    );

    static readonly TRANSACTION_ACKNOWLEDGE_PROCESSED = new TransactionAction(
        TransactionActionName.TRANSACTION_ACKNOWLEDGE_PROCESSED,
        (
            <FormattedMessage
                id='TransactionAction.acknowledge_processed'
                description='The displayed value for the TransactionAction acknowledge_processed'
                defaultMessage='Acknowledge processed'
            />
        )
    );

    static readonly ALL = [
        TransactionAction.TRANSACTION_CHANGE_STATE,
        TransactionAction.TRANSACTION_PROCESS_PACKAGES,
        TransactionAction.TRANSACTION_ACKNOWLEDGE_PROCESSING,
        TransactionAction.TRANSACTION_ACKNOWLEDGE_PROCESSED
    ];
    private constructor(public readonly value: TransactionActionName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: TransactionActionName): TransactionAction | undefined {
        return TransactionAction.ALL.find((action) => action.value === value);
    }
}
