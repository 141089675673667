import { TranslationProvider } from '@frontend/lang';
import { DocumentStoreProvider } from '@frontend/repository';
import { BrowserRouter as Router } from 'react-router-dom';

import Footer from './home-page/footer/footer.component';
import HomePage from './home-page/home-page.component';
import Navbar from './home-page/navbar/navbar.component';
import Initialisation from './initialisation/initialisation.component';
import Resolver from './utils/resolver';

export function App() {
    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <Router>
                    <Resolver>
                        <div
                            className='app-content d-flex flex-column'
                            id='app-content'>
                            <Navbar />
                            <Initialisation>
                                <HomePage />
                            </Initialisation>
                            <Footer />
                        </div>
                    </Resolver>
                </Router>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
