import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Contact, ContactListResponse, ContactParams, CreateContact, UpdateContact } from '@frontend/contact/types';

const endpoint = '/contact-api/v1';

export class ContactClient extends APIClient {
    public static async fetchContacts(queryParams?: ApiQueryParams<ContactParams>): Promise<ContactListResponse> {
        return await this.apiPaginated<ContactListResponse, DefaultQueryParams>(`${endpoint}/contacts`, queryParams);
    }

    public static async postContact(accountId: string, contact: CreateContact): Promise<Contact> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/contacts`, contact);
        return await this.handleResponse<Contact>(response);
    }

    public static async fetchContact(accountId: string, contactId: string): Promise<Contact> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/contacts/${contactId}`);
        return await this.handleResponse<Contact>(response);
    }

    public static async patchContact(accountId: string, contactId: string, contact: UpdateContact): Promise<Contact> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/contacts/${contactId}`, contact);
        return await this.handleResponse<Contact>(response);
    }

    public static async deleteContact(accountId: string, contactId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/contacts/${contactId}`);
        return await this.handleVoidResponse(response);
    }

    public static async resolveContacts(contactIds: string[]): Promise<ContactListResponse> {
        const response = await this.post(`${endpoint}/contacts-resolve`, { ids: contactIds });
        return await this.handleResponse<ContactListResponse>(response);
    }

    public static async deleteContacts(contactIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/contacts-delete`, { ids: contactIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchContactsNotInGroup(accountId: string, groupId: string): Promise<ContactListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/groups/${groupId}/contacts`);
        return await this.handleResponse<ContactListResponse>(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
