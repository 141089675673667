import { ErrorHandler } from '@frontend/error-handler';
import { SlotClient } from '@frontend/slot/api';
import { Slot } from '@frontend/slot/types';
import { useEffect, useState } from 'react';

import { SlotOpeningProps } from './slot-opening.component';

interface ViewProps {
    slot: Slot | null;
}

const useSlotOpening = (props: SlotOpeningProps): ViewProps => {
    const [slot, changeSlot] = useState<Slot | null>(null);
    useEffect(() => {
        SlotClient.fetchSlot(props.data.account_id, props.data.spot_id, props.data.module_id, props.data.slot_id)
            .then(changeSlot)
            .catch(ErrorHandler.handleError);
    }, []);

    return { slot }
};

export default useSlotOpening;
