import { ConfirmationModal } from '@frontend/elements';
import { Layout } from '@frontend/spot-layout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContactList } from '../contact-list/contact-list.component';
import SlotOpening from '../slot-status/slot-opening/slot-opening.component';
import { UserInterfaceProps } from '../types/user-interface';
import useDeliveryDropoff from './delivery-dropoff.controller';

export const DeliveryDropoff = (props: UserInterfaceProps) => {
    const viewProps = useDeliveryDropoff(props);

    if (viewProps.isDropping && viewProps.dropPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.dropPackage.account_id,
                    spot_id: viewProps.dropPackage.spot_id,
                    module_id: viewProps.dropPackage.module_id,
                    slot_id: viewProps.dropPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.selectedContact && viewProps.spot) {
        return (
            <>
                <div className='vh-75'>
                    <Layout
                        spot={viewProps.spot}
                        renderingProps={{
                            moduleRenderingProps: {
                                slotRenderingProps: {
                                    onClick: (sr) => {
                                        const id = sr.slot_ids[0];
                                        if (!viewProps.disabledSlots.find((s) => s.id === id)) viewProps.changeSlot(id);
                                    },
                                    styles: {
                                        div: (sr) => {
                                            if (viewProps.slot && viewProps.slot === sr.slot_ids[0]) {
                                                return `spotlayout-item spotlayout-item-success align-items-center justify-content-center w-100 h-100`;
                                            }
                                            const value = viewProps.slotMap.get(sr.slot_ids[0]);
                                            return `spotlayout-item spotlayout-item-${value ?? 'secondary'} align-items-center justify-content-center w-100 h-100`;
                                        }
                                    },
                                    label: (sr) => {
                                        return viewProps.slotList.find((s) => s.id === sr.slot_ids[0])?.display ?? '';
                                    }
                                }
                            }
                        }}
                    />
                </div>
                {viewProps.slot && (
                    <ConfirmationModal
                        message={
                            <FormattedMessage
                                id='open-dropoff-couriers.drop-off-message'
                                defaultMessage='Are you sure you want to drop off your package in this SLOT?'
                            />
                        }
                        onConfirm={viewProps.onDropOff}
                        handleClose={() => viewProps.changeSlot(null)}
                        show={!!viewProps.slot}
                    />
                )}
            </>
        );
    }
    return (
        <div>
            <ContactList
                onClick={viewProps.changeSelectedContact}
                {...props}
            />
        </div>
    );
};
