import { StringPlaceholder } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useDefaultTitle from './default-title.controller';

const ID = 'default-title';

const DefaultTitle = () => {
    const viewProps = useDefaultTitle();

    return (
        <div
            id={ID}
            className='d-flex flex-column text-center m-3'>
            {!viewProps.user && (
                <StringPlaceholder
                    loaded={!!viewProps.spotTitle}
                    className='h1'>
                    <span className='fs-3'>
                        <FormattedMessage
                            id='default-title.spot-title.welcome'
                            description='Message welcoming the user to the spot'
                            defaultMessage='Welcome to'
                        />
                    </span>
                    <h1>{viewProps.spotTitle}</h1>
                </StringPlaceholder>
            )}
            {viewProps.user && (
                <div>
                    <h1 className='text-7xl'>{viewProps.user.first_name + ' ' + viewProps.user.last_name}</h1>
                    {viewProps.spotTitle && (
                        <p className='fs-3'>
                            <FormattedMessage
                                id='default-title.spot-title.welcome'
                                description='Message welcoming the user to the spot'
                                defaultMessage='Welcome to'
                            />
                            <span className='text-info'>{' ' + viewProps.spotTitle}</span>
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default DefaultTitle;
