import { EntityType } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useIoT:request': CustomEvent<string>;
    }
}

const REQUEST_IOT_EVENT = 'useIoT:request';
const STORAGE_KEY = 'IOT_REQ';

export function iotRequest(iotId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_IOT_EVENT, { detail: iotId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function IoTResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_IOT_EVENT, EntityType.IOT, (ids) => IoTClient.resolveIoTs(ids), props.dispatch);
    return <></>;
}
