import { APIClient, ApiQueryParams, buildApiEndpoint, DefaultQueryParams } from '@frontend/api-utils';
import { AvailableSlotConstraint, SlotListResponse, SlotQueryParams } from '@frontend/slot/types';
const endpoint = '/slot-api/v1';

export class SlotWorkflowClient extends APIClient {
    public static async postAvailableSlots(accountId: string, spotId: string, constraints: AvailableSlotConstraint, queryParams?: ApiQueryParams<DefaultQueryParams | SlotQueryParams>): Promise<SlotListResponse> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/spots/${spotId}/available-slots`, queryParams );
        const response = await this.post(url, constraints);
        return await this.handleResponse<SlotListResponse>(response);
    }
}
