import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateMovement, Movement, MovementListResponse, MovementQueryParams } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class MovementClient extends APIClient {
    static async fetchMovements(queryParams?: ApiQueryParams<DefaultQueryParams | MovementQueryParams>): Promise<MovementListResponse> {
        return this.apiPaginated<MovementListResponse, DefaultQueryParams | MovementQueryParams>(`${endpoint}/movements`, queryParams);
    }

    static async resolveMovements(ids: string[]): Promise<MovementListResponse> {
        const response = await this.post(`${endpoint}/movements-resolve`, { ids: ids });
        return this.handleResponse<MovementListResponse>(response);
    }

    static async fetchSpotModuleSlotMovements(accountId: string, spotId: string, moduleId: string, slotId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<MovementListResponse> {
        return this.apiPaginated<MovementListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements`, queryParams);
    }

    static async postSpotModuleSlotMovement(accountId: string, spotId: string, moduleId: string, slotId: string, movement: CreateMovement): Promise<Movement> { 
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements`, movement);
        return this.handleResponse<Movement>(response);
    }

    static async fetchSpotModuleSlotMovement(accountId: string, spotId: string, moduleId: string, slotId: string, movementId: string): Promise<Movement> { 
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}/movements/${movementId}`);
        return this.handleResponse<Movement>(response);
    }
}
