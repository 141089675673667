import { ModuleClient } from '@frontend/module/api';
import { Module } from '@frontend/module/types';
import { SlotClient, SlotRenderingClient } from '@frontend/slot/api';
import { Slot, SlotRendering } from '@frontend/slot/types';
import { useEffect, useState } from 'react';

import { LayoutProps } from './layout.component';

interface ViewProps {
    slots: Slot[] | null;
    slotRendering: SlotRendering[] | null;
    containerInfo: { maxX: number; maxY: number; aspectRatio: number };
}

const useLayout = (props: LayoutProps): ViewProps => {
    const [modules, changeModules] = useState<Module[] | null>(props.modules || null);
    const [slots, changeSlots] = useState<Slot[] | null>(props.slots || null);
    const [slotRendering, changeSlotRendering] = useState<SlotRendering[] | null>(null);
    const [containerInfo, changeContainerInfo] = useState<{ maxX: number; maxY: number; aspectRatio: number }>({ maxX: 0, maxY: 0, aspectRatio: 1 });

    useEffect(() => {
        if (props.spot) {
            ModuleClient.fetchModules({ spot_id: props.spot.id }).then((res) => changeModules(res.results));
        }
    }, [props.spot]);

    useEffect(() => {
        if (modules !== null) {
            const slotPromises = modules.map((module) => SlotClient.fetchSlots({ module_id: module.id }));
            const allSlots: Slot[] = [];
            Promise.all(slotPromises)
                .then((results) => {
                    results.forEach((slots) => allSlots.push(...slots.results));
                })
                .then(() => {
                    changeSlots(allSlots);
                });
        }
    }, [modules]);

    useEffect(() => {
        if (slots !== null && slots.length > 0) {
            const mods: string[] = [];
            slots.forEach((slot) => {
                if (!mods.includes(slot.module_id)) mods.push(slot.module_id);
            });
            const slotRenderingPromises = mods.map((module) => SlotRenderingClient.fetchSlotRendering(slots[0].account_id, slots[0].spot_id, module));
            const allSlotRendering: SlotRendering[] = [];
            Promise.all(slotRenderingPromises)
                .then((results) => {
                    results.forEach((slots) => allSlotRendering.push(...slots.results));
                })
                .then(() => {
                    changeSlotRendering(allSlotRendering);
                    let maxY = 0;
                    let maxX = 0;
                    allSlotRendering.forEach((slot) => {
                        if (slot.location.y + slot.dimension.height > maxY) {
                            maxY = slot.location.y + slot.dimension.height;
                        }
                        if (slot.location.x + slot.dimension.width > maxX) {
                            maxX = slot.location.x + slot.dimension.width;
                        }
                    });
                    changeContainerInfo({ maxX: maxX, maxY: maxY, aspectRatio: maxX / maxY });
                });
        }
    }, [slots]);

    return {
        slots,
        slotRendering,
        containerInfo
    };
};

export default useLayout;
