import { ConfirmationModal, Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { Layout } from '@frontend/spot-layout';
import { addCommandHistory } from '@frontend/terminal/shared';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SlotOpening from '../slot-status/slot-opening/slot-opening.component';
import { UserInterfaceProps } from '../types/user-interface';
import useOpenDropoffCouriers, { ViewProps } from './open-dropoff-couriers.controller';

export const OpenDropoffCouriers = (props: UserInterfaceProps) => {
    const viewProps = useOpenDropoffCouriers(props);

    if (viewProps.isDropping && viewProps.dropPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.dropPackage.account_id,
                    spot_id: viewProps.dropPackage.spot_id,
                    module_id: viewProps.dropPackage.module_id,
                    slot_id: viewProps.dropPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.selectedContact && viewProps.spot) {
        return (
            <>
                <div className='vh-75'>
                    <Layout
                        spot={viewProps.spot}
                        renderingProps={{
                            moduleRenderingProps: {
                                slotRenderingProps: {
                                    onClick: (sr) => {
                                        const id = sr.slot_ids[0];
                                        if (!viewProps.disabledSlots.find((s) => s.id === id)) viewProps.changeSlot(id);
                                    },
                                    styles: {
                                        div: (sr) => {
                                            if (viewProps.slot && viewProps.slot === sr.slot_ids[0]) {
                                                return `spotlayout-item spotlayout-item-success align-items-center justify-content-center w-100 h-100`;
                                            }
                                            const value = viewProps.slotMap.get(sr.slot_ids[0]);
                                            return `spotlayout-item spotlayout-item-${value ?? 'secondary'} align-items-center justify-content-center w-100 h-100`;
                                        }
                                    },
                                    label: (sr) => {
                                        return viewProps.slotList.find((s) => s.id === sr.slot_ids[0])?.display ?? '';
                                    }
                                }
                            }
                        }}
                    />
                </div>
                {viewProps.slot && (
                    <ConfirmationModal
                        message={
                            <FormattedMessage
                                id='open-dropoff-couriers.drop-off-message'
                                defaultMessage='Are you sure you want to drop off your package in this SLOT?'
                            />
                        }
                        onConfirm={viewProps.onDropOff}
                        handleClose={() => viewProps.changeSlot(null)}
                        show={!!viewProps.slot}
                    />
                )}
            </>
        );
    }

    if (viewProps.selectedSender) {
        return (
            <div className='d-flex flex-column justify-content-between p-1 fs-5 w-100'>
                <div className=''>
                    <div className='d-flex flex-row justify-content-center'>
                        <h3>
                            <FormattedMessage
                                id='open-dropoff-couriers.select-receiver'
                                defaultMessage='Choose a receiver'
                            />
                        </h3>
                    </div>
                    <div className='d-flex flex-row justify-content-center w-100'>
                        <ul className='list-group w-100 m-1'>
                            <li className='list-group-item list-group-item-primary'>{CommonMessage.OBJECTS.CONTACT.PLURAL}</li>
                            {viewProps.contacts.map((contact) => (
                                <li
                                    className='list-group-item list-group-item'
                                    key={contact.id}
                                    onClick={() => {
                                        viewProps.changeSelectedContact(contact);
                                        props.dispatch(addCommandHistory(() => viewProps.changeSelectedContact(null)));
                                    }}>
                                    <span className='d-flex flex-row justify-content-between'>
                                        <span className='d-flex flex-row align-items-center'>
                                            {contact.first_name} {contact.last_name}
                                        </span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return senderSelect(viewProps, props.dispatch);
};

const senderSelect = (viewProps: ViewProps, dispatch: ThunkDispatch<any, any, Action>) => {
    if (viewProps.isLoading) {
        return <Spinner />;
    }
    return (
        <div>
            <div className='d-flex flex-row justify-content-center'>
                <h3>
                    <FormattedMessage
                        id='open-dropoff-couriers.select-courier'
                        defaultMessage='Choose a courier'
                    />
                </h3>
            </div>
            <div className='d-flex flex-column'>
                <div className='d-flex flex-row flex-wrap justify-content-center'>
                    {viewProps.senders.map((sender) => (
                        <div
                            key={sender.id}
                            className='card w-45 m-2'
                            onClick={() => {
                                viewProps.changeSelectedSender(sender);
                                dispatch(addCommandHistory(() => viewProps.changeSelectedSender(null)));
                            }}>
                            <div className='card-body d-flex justify-content-center'>
                                {!sender.url ? (
                                    <span>{sender.alias}</span>
                                ) : (
                                    <img
                                        className='img-fluid border-radius-lg shadow-lg max-height-300'
                                        src={sender.url}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
