import { APIClient } from '@frontend/api-utils';
import { Transaction, TransactionStateName, TransactionTriggerName } from '@frontend/transaction/types';
import { TransactionWorkflowStepTriggerName } from '@frontend/workflow/types';


const endpoint = '/transaction-api/v1';

export class TransactionWorkflowClient extends APIClient {
    public static async updateTransactionState(accountId: string, transactionId: string, state: TransactionStateName): Promise<Transaction> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/change-state`, { state: state });
        return await this.handleResponse<Transaction>(response);
    }
    /**
     *
     * @param accountId
     * @param transactionId
     * @param trigger as WorkflowStepTrigger
     * @returns
     */
    public static async triggerTransactionState(
        accountId: string,
        transactionId: string,
        trigger: { trigger: TransactionTriggerName; source?: any }
    ): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }

    /**
     *
     * @deprecated
     * @param spotId
     * @param transactionId
     * @param trigger as WorkflowStepTrigger
     * @returns
     */
    public static async postTriggerTransactionState(
        spotId: string,
        transactionId: string,
        trigger: { trigger: TransactionWorkflowStepTriggerName; source: any }
    ): Promise<Transaction> {
        const response = await this.post(`${endpoint}/spots/${spotId}/transactions/${transactionId}/trigger`, trigger);
        return await this.handleResponse<Transaction>(response);
    }

    public static async postMoveTransaction(spotId: string, transactionId: string, newSpotId: string): Promise<Transaction> {
        const response = await this.post(`${endpoint}/spots/${spotId}/transactions/${transactionId}/move-spot`, { spot_id: newSpotId });
        return await this.handleResponse<Transaction>(response);
    }
}
