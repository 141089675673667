import { Spinner } from '@frontend/elements';
import React from 'react';

import SlotStatus from '../../slot-status/slot-status.component';
import { UserInterfaceProps } from '../../types/user-interface';
import useOnDutyPharmacistDropOff from './on-duty-pharmacist-drop-off.controller';

export const OnDutyPharmacistDropOff = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyPharmacistDropOff();

    if (!viewProps.slot) {
        return <Spinner />;
    }

    return (
        <SlotStatus
            {...props}
            data={{
                account_id: viewProps.slot.account_id,
                spot_id: viewProps.slot.spot_id,
                module_id: viewProps.slot.module_id,
                slot_id: viewProps.slot.id
            }}
            type={'drop'}
        />
    );
};
