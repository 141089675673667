import { Spinner } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import useCarousel from './carousel.controller';

export const Carousel = (props: UserInterfaceProps) => {
    const viewProps = useCarousel(props);
    return (
        <div className='d-flex flex-column align-content-center align-items-center align-self-center w-100 h-100'>
            <div className='card-header mt-10 mb-8'>
                <h1 className='font-weight-bolder'>
                    <FormattedMessage
                        id='terminal.payment'
                        defaultMessage='Scan the code below to make a payment'
                    />
                </h1>
            </div>
            {viewProps.url ? (
                <img
                    className='img-fluid h-40'
                    src={viewProps.url}
                    alt={viewProps.url}
                />
            ) : (
                <Spinner />
            )}
        </div>
    );
};
