import { ApiError } from '@frontend/api-utils';
import { BadgeClient } from '@frontend/badge/api';
import { SliceStatus } from '@frontend/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface ScannerState {
    lastValue: string | null;
    result: unknown | null;
    status: SliceStatus;
}

const initialState: ScannerState = {
    lastValue: null,
    result: null,
    status: SliceStatus.INIT
};

const scannerSlice = createSlice({
    name: 'scannerState',
    initialState,
    reducers: {
        reset: (state) => {
            state.result = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doScannerLookup.pending, (state) => {
                state.result = null;
                state.status = SliceStatus.LOADING;
            })
            .addCase(doScannerLookup.fulfilled, (state, action) => {
                state.lastValue = action.meta.arg;
                state.result = action.payload[0];
                state.status = SliceStatus.IDLE;
            });
    }
});

export const { reset } = scannerSlice.actions;

export const doScannerLookup = createAsyncThunk<unknown[], string>('scanner:doScannerLookup', async (code: string, { rejectWithValue }) => {
    try {
        const result: unknown[] = [];
        await Promise.all([searchBadges(code, result)]);
        return result;
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

async function searchBadges(value: string, result: unknown[]): Promise<void> {
    const res = await BadgeClient.fetchBadges({ number: value });
    res.results.forEach((r) => result.push(r));
}

export const scannerStore = { scannerState: scannerSlice.reducer };
