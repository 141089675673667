import { PaginatedResponse } from '@frontend/api-utils';

import { TransactionStateName } from './transaction-state';

export interface Transaction {
    iot_id: string | null;
    user_id: string | null;
    id: string;
    state: TransactionStateName;
    notes: string | null;
    account_id: string;
    workflow_id: string;
    creation_timestamp: string;
    update_timestamp: string;
    type: TransactionType;
    tracking_number: string | null;
}

export type TransactionListResponse = PaginatedResponse<Transaction>;

export interface CreateTransaction {
    state?: TransactionStateName;
    user_id?: string;
    iot_id?: string | null;
    workflow_id: string;
    type: TransactionType;
    tracking_number?: string;
    notes?: string | null;
}

export interface UpdateTransaction {
    tracking_number?: string;
    iot_id?: string | null;
    user_id?: string | null;
    state?: TransactionStateName;
    notes?: string | null;
}

export enum SearchTransactionQueryParams {
    PARTIAL_TEXT = 'partial_text'
}

export enum TransactionQueryParams {
    STATE = 'state',
    USER_ID = 'user_id',
    SPOT_ID = 'spot_id',
    ACCOUNT_ID = 'account_id',
    TYPE = 'type',
    WORKFLOW_ID = 'workflow_id'
}

export enum TransactionType {
    AUDIT = 'audit',
    LENDING = 'lending',
    OPEN_DROP_OFF = 'open_drop_off',
    PASS_THROUGH = 'pass_through',
    PUDO = 'pudo',
    VENDING = 'vending',
    WAREHOUSE = 'warehouse'
}

export interface UpdateTransactionState {
    state?: TransactionStateName;
}
