import { ConfirmationModal, Spinner } from '@frontend/elements';
import { Layout } from '@frontend/spot-layout';
import { addCommandHistory } from '@frontend/terminal/shared';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SlotOpening from '../slot-status/slot-opening/slot-opening.component';
import { UserInterfaceProps } from '../types/user-interface';
import useOpenDropoffCouriers, { ViewProps } from './open-dropoff-couriers.controller';

export const OpenDropoffCouriers = (props: UserInterfaceProps) => {
    const viewProps = useOpenDropoffCouriers(props);

    if (viewProps.isDropping && viewProps.dropPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.dropPackage.account_id,
                    spot_id: viewProps.dropPackage.spot_id,
                    module_id: viewProps.dropPackage.module_id,
                    slot_id: viewProps.dropPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.selectedContact) {
        return (
            <>
                <Layout
                    slots={viewProps.slotList}
                    scale={0.5}
                    slotClassMap={viewProps.slotMap}
                    disabledSlots={viewProps.disabledSlots}
                    onClick={viewProps.changeSlot}
                    selectedSlot={viewProps.slot ?? undefined}
                />
                {viewProps.slot && (
                    <ConfirmationModal
                        message={
                            <FormattedMessage
                                id='open-dropoff-couriers.drop-off-message'
                                defaultMessage='Are you sure you want to drop off your package in this SLOT?'
                            />
                        }
                        onConfirm={viewProps.onDropOff}
                        handleClose={() => viewProps.changeSlot(null)}
                        show={!!viewProps.slot}
                    />
                )}
            </>
        );
    }

    if (viewProps.selectedSender) {
        return (
            <div className='d-flex flex-column justify-content-between p-1 fs-5 w-100'>
                <div className=''>
                    <div className='d-flex flex-row justify-content-center'>
                        <h3>Choose a receiver</h3>
                    </div>
                    <div className='d-flex flex-row justify-content-center w-100'>
                        <ul className='list-group w-100 m-1'>
                            <li className='list-group-item list-group-item-primary'>
                                {props.userInterface.data.receiverSelectMethod.charAt(0).toUpperCase() +
                                    props.userInterface.data.receiverSelectMethod.slice(1) +
                                    's'}
                            </li>
                            {viewProps.contacts.map((contact) => (
                                <li
                                    className='list-group-item list-group-item'
                                    key={contact.id}
                                    onClick={() => {
                                        viewProps.changeSelectedContact(contact);
                                        props.dispatch(addCommandHistory(() => viewProps.changeSelectedContact(null)));
                                    }}>
                                    <span className='d-flex flex-row justify-content-between'>
                                        <span className='d-flex flex-row align-items-center'>
                                            {contact.first_name} {contact.last_name}
                                        </span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return senderSelect(props.userInterface.data.senderSelectMethod, viewProps, props.dispatch);
};

const senderSelect = (method: string, viewProps: ViewProps, dispatch: ThunkDispatch<any, any, Action>) => {
    if (viewProps.isLoading) {
        return <Spinner />;
    }
    switch (method) {
        case 'courier':
            return (
                <div>
                    <div className='d-flex flex-row justify-content-center'>
                        <h3>Choose a courier</h3>
                    </div>
                    <div className='d-flex flex-row'>
                        {viewProps.senders.map((sender) => (
                            <div
                                key={sender.id}
                                className='card m-2 w-50'
                                onClick={() => {
                                    viewProps.changeSelectedSender(sender);
                                    dispatch(addCommandHistory(() => viewProps.changeSelectedSender(null)));
                                }}>
                                <div className='card-body d-flex justify-content-center'>
                                    {!sender.url ? (
                                        <span>{sender.alias}</span>
                                    ) : (
                                        <img
                                            className='img-fluid border-radius-lg shadow-lg max-height-300'
                                            src={sender.url}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        default:
            return (
                <div className='d-flex flex-row justify-content-center'>
                    <h3>Oops something went wrong</h3>
                </div>
            );
    }
};
