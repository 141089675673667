import { Spinner } from '@frontend/elements';
import React from 'react';

import { OnDutyOpenSlotButton } from '../../on-duty-open-slot/on-duty-open-slot.component';
import SlotStatus from '../../slot-status/slot-status.component';
import { UserInterfaceProps } from '../../types/user-interface';
import useOnDutyPharmacistPickUp from './on-duty-pharmacist-pick-up.controller';

export const OnDutyPharmacistPickUp = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyPharmacistPickUp();

    if (!viewProps.slot) {
        return <Spinner />;
    }

    if (viewProps.isSlotOpening) {
        return (
            <SlotStatus
                {...props}
                data={{
                    account_id: viewProps.slot.account_id,
                    spot_id: viewProps.slot.spot_id,
                    module_id: viewProps.slot.module_id,
                    slot_id: viewProps.slot.id
                }}
                type={'pick'}
            />
        );
    }
    return (
        <div className='d-flex justify-content-center'>
            <OnDutyOpenSlotButton callback={() => viewProps.changeIsSlotOpening(true)} />
        </div>
    );
};
