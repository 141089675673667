import { Logger } from '@frontend/Logger';
import { ClassType } from '@frontend/common';
import { IconType } from 'react-icons';
import { BsBoxArrowInDown, BsBoxArrowUp } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import { GrPowerReset } from 'react-icons/gr';
import { ImQrcode } from 'react-icons/im';
import { IoKeypadOutline } from 'react-icons/io5';
import { RiDoorOpenLine } from 'react-icons/ri';
import { TfiUnlock } from 'react-icons/tfi';

export enum UserInterfaceButtonType {
    PIN_CODE = 'Enter pin',
    DROP_OFF = 'DropOff',
    PICK_UP = 'PickUp',
    SCAN = 'Scan',
    SELECT_PRODUCT = 'Select product',
    PROFILE = 'Profile',
    ACTIVATE_HATCH = 'Activate hatch',
    OPEN_HATCH = 'Open hatch',
    PHARMACY_ON_DUTY = 'Pharmacy on duty',
    RESET = 'Reset',
    UNKNOWN = ''
}

export function getIconByUIButtonType(type: UserInterfaceButtonType): IconType | null {
    switch (type) {
        case UserInterfaceButtonType.DROP_OFF:
            return BsBoxArrowInDown;
        case UserInterfaceButtonType.PICK_UP:
        case UserInterfaceButtonType.SELECT_PRODUCT:
            return BsBoxArrowUp;
        case UserInterfaceButtonType.PIN_CODE:
            return IoKeypadOutline;
        case UserInterfaceButtonType.SCAN:
            return ImQrcode;
        case UserInterfaceButtonType.PROFILE:
            return FaUser;
        case UserInterfaceButtonType.ACTIVATE_HATCH:
            return TfiUnlock;
        case UserInterfaceButtonType.OPEN_HATCH:
            return RiDoorOpenLine;
        case UserInterfaceButtonType.PHARMACY_ON_DUTY:
            return GiHealthNormal;
        case UserInterfaceButtonType.RESET:
            return GrPowerReset;
        default:
            Logger.error('Unknown workflow button type.', {}, type);
            return null;
    }
}

export function getColorByUIButtonType(type: UserInterfaceButtonType): ClassType | null {
    switch (type) {
        case UserInterfaceButtonType.PHARMACY_ON_DUTY:
            return ClassType.SUCCESS;
        default:
            return null;
    }
}
