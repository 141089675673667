import { useState } from 'react';

interface ViewProps {
    provisioned: boolean;
    provisionDoneCallback: () => void;
}

const useInitialisation = (): ViewProps => {
    const [provisioned, changeProvisioned] = useState<boolean>(false);

    return {
        provisioned,
        provisionDoneCallback: () => changeProvisioned(true)
    };
};

export default useInitialisation;
