import React from 'react';

import { UserInterfaceProps } from '../types/user-interface';
import SlotOpened from './slot-opened/slot-opened.component';
import SlotOpening from './slot-opening/slot-opening.component';
import useSlotStatus, { View } from './slot-status.controller';

export interface SlotStatusProps extends UserInterfaceProps {
    data: { account_id: string; spot_id: string; module_id: string; slot_id: string };
    type: 'drop' | 'pick';
    openedCallback?: () => void;
    openingCallback?: () => void;
    closedCallBack?: () => void;
}

const SlotStatus = (props: SlotStatusProps) => {
    const viewProps = useSlotStatus(props);
    switch (viewProps.view) {
        case View.OPENING:
            return <SlotOpening data={props.data} />;
        case View.OPENED:
            return (
                <SlotOpened
                    data={props.data}
                    type={props.type}
                    callback={props.openedCallback}
                />
            );
        case View.CLOSED:
            return <h4>Slot is closed</h4>;
    }
};

export default SlotStatus;
