import { Spinner } from '@frontend/elements';
import { EnterPinData, UserInterfaceImplementation } from '@frontend/user-interface/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import SlotOpening from '../slot-status/slot-opening/slot-opening.component';
import ChallengeForm from './challenge-form/challenge-form.component';
import useEnterPin, { PinWorkflowState } from './enter-pin.controller';
import PinCodeForm from './pin-code-form/pin-code-form.component';

export interface EnterPinProps {
    dispatch: ThunkDispatch<any, any, Action>;
    userInterface: UserInterfaceImplementation<EnterPinData>;
}

export const EnterPin = (props: EnterPinProps) => {
    const viewProps = useEnterPin(props);
    if (viewProps.state == PinWorkflowState.ENTER_PIN)
        return (
            <PinCodeForm
                maxPinLength={props.userInterface.data.pinLength}
                callback={viewProps.changePinCodes}
            />
        );
    if (viewProps.state == PinWorkflowState.ENTER_CHALLENGE)
        return (
            <ChallengeForm
                pinCodes={viewProps.pinCodes}
                callback={viewProps.changePinCodes}
            />
        );
    if (viewProps.state == PinWorkflowState.DONE && viewProps._package)
        return (
            <SlotOpening
                data={{
                    account_id: viewProps._package.account_id,
                    spot_id: viewProps._package.spot_id,
                    module_id: viewProps._package.module_id,
                    slot_id: viewProps._package.slot_id
                }}
            />
        );

    return <Spinner />;
};
