import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

import { CreateSpot, Spot, SpotListResponse, SpotsQueryParams, UpdateSpot } from '@frontend/spot/types';

const endpoint = '/spot-api/v1';
export class SpotClient extends APIClient {
    static async fetchSpots(queryParams?: ApiQueryParams<SpotsQueryParams | DefaultQueryParams>): Promise<SpotListResponse> {
        return this.apiPaginated<SpotListResponse, DefaultQueryParams>(`${endpoint}/spots`, queryParams);
    }

    static async resolveSpots(spotIds: string[]): Promise<SpotListResponse> {
        const response = await this.post(`${endpoint}/spots-resolve`, { ids: spotIds });
        return await this.handleResponse<SpotListResponse>(response);
    }

    public static async deleteSpots(spotIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/spots-delete`, { ids: spotIds });
        return await this.handleVoidResponse(response);
    }

    static async postSpot(accountId: string, spot: CreateSpot): Promise<Spot> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots`, spot);
        return await this.handleResponse<Spot>(response);
    }

    static async fetchSpot(accountId: string, spotId: string): Promise<Spot> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}`);
        return await this.handleResponse<Spot>(response);
    }

    static async patchSpot(accountId: string, spotId: string, spot: UpdateSpot): Promise<Spot> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/spots/${spotId}`, spot);
        return await this.handleResponse<Spot>(response);
    }

    static async deleteSpot(accountId: string, spotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/spots/${spotId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
