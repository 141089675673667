import { Slot } from '@frontend/slot/types';

export interface SlotSensorEvent extends Slot {
    message: string;
    data: {
        status: string[];
        error: string;
    };
}

export function isSlotSensorEvent(event: any): event is SlotSensorEvent {
    return event.message === 'slot_sensor_received';
}
