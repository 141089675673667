import React from "react";
import { FormattedMessage } from "react-intl";
import { UserInterfaceProps } from "../types/user-interface";
import usePackageProcessed from "./package-processed.controller";



export const PackageProcessed = (props: UserInterfaceProps) => {
    const viewProps = usePackageProcessed(props);
    return (
        <div className='d-flex row justify-content-center'>
            <h3 className='d-flex justify-content-center'>
                <FormattedMessage
                    id='package-processed.message'
                    defaultMessage='Your package has been processed'
                />
            </h3>
            {viewProps.redirectUI && (
                <h5 className='d-flex justify-content-center'>
                    {`You will be redirected in ${viewProps.countdown} seconds`}
                </h5>
            )}
        </div>
    )
}

