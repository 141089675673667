import { ClassType } from '@frontend/common';
import { Slot, SlotRendering } from '@frontend/slot/types';
import React from 'react';

import useLayoutItem from './layout-item.controller';

const ID = 'layout-item';
export interface LayoutItemProps {
    slots: Slot[];
    slotRendering: SlotRendering;
    fullWidth: number;
    fullHeight: number;
    label?: string;
    onClick?: (slotRendering: SlotRendering, slots: Slot[]) => void;
    id?: string;
    slotClassMap?: Map<string, ClassType>;
    selectedSlot?: string;
}

const LayoutItem = (props: LayoutItemProps) => {
    const viewProps = useLayoutItem(props);

    return (
        <div
            style={viewProps.style}
            id={ID + '-' + props.id}
            className={'spotlayout-item spotlayout-item-' + viewProps.itemClass + ' align-items-center justify-content-center'}
            onClick={viewProps.onClick}>
            <div>{viewProps.label}</div>
        </div>
    );
};

export default LayoutItem;
