import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class ErrorBoundary extends Component {
    override state = { error: null };
    constructor(props: any | Readonly<any>) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: any) {
        return { error };
    }

    override render() {
        if (this.state.error) {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='card d-flex flex-column w-100 w-xl-50'>
                        <div className='card-header mb-0'>
                            <h1 className='text-primary'>Oops!</h1>
                        </div>
                        <div className='card-body mt-0'>
                            <p className='text-secondary'>
                                <FormattedMessage
                                    id='GlobalErrorBoundary.Explanation'
                                    description='Explanation for users that encountered a (uncaught) error.'
                                    defaultMessage='It seems like there is something wrong with the page you requested.
                                We are very sorry for the inconvenience. You can go back where you came from and try again. Or if this keeps happening, please contact us.'
                                />
                            </p>
                        </div>
                        <div className='card-footer'>
                            <button
                                className='btn btn-primary'
                                onClick={() => window.history.back()}>
                                Go back
                            </button>
                            <a
                                className='btn btn-secondary ms-2'
                                href={`mailto:support@telloport.com?subject=Platform%20Error&body=Please tell us about what you experienced here.%0A%0Ainfo:{href:${window.location.href}, path:${window.location.pathname}, ${this.state.error}}`}>
                                Contact support
                            </a>
                        </div>
                    </div>
                </div>
            );
        }

        // eslint-disable-next-line react/prop-types
        return (this.props as { children: React.ReactNode }).children;
    }
}

export default ErrorBoundary;
