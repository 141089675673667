export const service_proxy_ports: { [key: string]: number } = {
    'account-api': 8000,
    'apb-api': 8041,
    'authorization-api': 8002,
    'budget-api': 8027,
    'certificate-api': 8021,
    'contact-api': 8004,
    'device-api': 8035,
    'document-api': 8020,
    'event-api': 8005,
    'equipment-api': 8031,
    'group-api': 8028,
    'identification-api': 8018,
    'iot-api': 8019,
    'locker-api': 8036,
    'module-api': 8006,
    'notification-api': 8007,
    'package-api': 8016,
    'problem-api': 8026,
    'product-api': 8008,
    'question-api': 8030,
    'slot-api': 8009,
    'spot-api': 8010,
    'sso-api': 8040,
    'stock-api': 8017,
    'sync-api': 8011,
    'transaction-api': 8012,
    'user-api': 8013,
    'workflow-api': 8014
};
