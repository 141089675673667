import { useChangeUrl } from '@frontend/common';
import { IoTEnumClient } from '@frontend/iot/api';
import { IoTState, IoTStateName } from '@frontend/iot/types';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IoTStateSelectProps } from './iot-state-select.component';


interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value: IoTStateValue | undefined;
    onChange: (value?: string | null, allowURLChange?: boolean) => string | null | undefined;
}

const useIoTStateSelect = (props: IoTStateSelectProps): ViewProps => {
    const [searchParams] = useSearchParams();
    const iot_state = searchParams.get('iot_state');
    const { onChange } = useChangeUrl('iot_state', (value) => {
        props.onChange && props.onChange({ value: value ?? '', label: value });
    });
    const [options, changeOptions] = useState<IoTStateValue[]>([]);

    useEffect(() => {
        IoTEnumClient.fetchIoTStates().then((states) => {
            return changeOptions(
                states.map((state) => {
                    const found = IoTState.ALL.find((t) => t.value === state);
                    if (found) return { value: found.value, label: found.displayName };
                    else
                        return {
                            value: state,
                            label: state.replace(/_/g, ' ')
                        };
                })
            );
        });
    }, []);

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        if (props.allowURLChange) return options.find((o) => o && o.value === iot_state);
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return { options, value, onChange };
};

export default useIoTStateSelect;

interface IoTStateValue {
    value: IoTStateName | string;
    label: React.ReactNode | string;
}
