import { ConfirmationModal } from '@frontend/elements';
import { Layout } from '@frontend/spot-layout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContactList } from '../contact-list/contact-list.component';
import SlotOpening from '../slot-status/slot-opening/slot-opening.component';
import { UserInterfaceProps } from '../types/user-interface';
import useDeliveryDropoff from './delivery-dropoff.controller';

export const DeliveryDropoff = (props: UserInterfaceProps) => {
    const viewProps = useDeliveryDropoff(props);

    if (viewProps.isDropping && viewProps.dropPackage) {
        return (
            <SlotOpening
                data={{
                    account_id: viewProps.dropPackage.account_id,
                    spot_id: viewProps.dropPackage.spot_id,
                    module_id: viewProps.dropPackage.module_id,
                    slot_id: viewProps.dropPackage.slot_id
                }}
            />
        );
    }

    if (viewProps.selectedContact) {
        return (
            <>
                <Layout
                    slots={viewProps.slotList}
                    scale={0.5}
                    slotClassMap={viewProps.slotMap}
                    disabledSlots={viewProps.disabledSlots}
                    onClick={viewProps.changeSlot}
                    selectedSlot={viewProps.slot ?? undefined}
                />
                {viewProps.slot && (
                    <ConfirmationModal
                        message={
                            <FormattedMessage
                                id='open-dropoff-couriers.drop-off-message'
                                defaultMessage='Are you sure you want to drop off your package in this SLOT?'
                            />
                        }
                        onConfirm={viewProps.onDropOff}
                        handleClose={() => viewProps.changeSlot(null)}
                        show={!!viewProps.slot}
                    />
                )}
            </>
        );
    }
    return (
        <div>
            <ContactList
                onClick={viewProps.changeSelectedContact}
                {...props}
            />
        </div>
    );
};
