import { EntityType } from '@frontend/common';
import { UnknownClient } from '@frontend/iot/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useUnknown:request': CustomEvent<string>;
    }
}

const REQUEST_UNKNOWN_EVENT = 'useUnknown:request';
const STORAGE_KEY = 'UNK_REQ';

export function unknownRequest(unknownId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_UNKNOWN_EVENT, { detail: unknownId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function UnknownResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_UNKNOWN_EVENT, EntityType.UNKNOWN, (ids) => UnknownClient.resolveUnknowns(ids), props.dispatch);
    return <></>;
}
