import { UserInterfaceType } from '@frontend/user-interface/types';
import React from 'react';
import { LuNfc } from 'react-icons/lu';
import { FormattedMessage } from 'react-intl';

import DefaultTitle from '../../default-title/default-title.component';

export const ScanBadge = () => {
    return (
        <div
            id={UserInterfaceType.SCAN_BADGE}
            style={{ height: '80%' }}>
            <DefaultTitle />
            <div className='d-flex flex-column align-items-center justify-content-center m-3 h-70 align-content-center'>
                <h3 className='pb-4'>
                    <FormattedMessage
                        id='scan-badge.scan-badge'
                        defaultMessage='Please scan your badge to continue'
                    />
                </h3>
                <h3>
                    <LuNfc size={300} />
                </h3>
            </div>
        </div>
    );
};
