import React from 'react';

import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { slotRequest } from '@frontend/slot/events';
import { Slot } from '@frontend/slot/types';

export const SlotBadge = (props: ObjectBadgeProps<Slot>) => {
    return (
        <ObjectBadge<Slot>
            id={props.id}
            type={EntityType.SLOT}
            mapDisplayName={(slot: Slot) => slot.display ?? ''}
            resolve={slotRequest}
            {...props}
        />
    );
};
