import { WorkflowTrigger, WorkflowTriggerName } from '@frontend/workflow/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum PackageTriggerName {
    PACKAGE_PROCESS = 'package_process',
    PACKAGE_STATE_CHANGED = 'package_state_changed',
    PACKAGE_PIN_CODE_CREATED = 'package_pin_code_created',
    PACKAGE_CODE_SCANNED = 'package_code_scanned',
    PACKAGE_SLOT_OPENED = 'package_slot_opened',
    PACKAGE_SLOT_CLOSED = 'package_slot_closed'
}

export class PackageTrigger extends WorkflowTrigger{
    static readonly PACKAGE_PROCESS = new PackageTrigger(
        PackageTriggerName.PACKAGE_PROCESS,
        (
            <FormattedMessage
                id='packageTrigger.process'
                description='package trigger process'
                defaultMessage='Package process'
            />
        )
    );

    static readonly PACKAGE_STATE_CHANGED = new PackageTrigger(
        PackageTriggerName.PACKAGE_STATE_CHANGED,
        (
            <FormattedMessage
                id='packageTrigger.state_changed'
                description='package trigger state changed'
                defaultMessage='Package state changed'
            />
        )
    );

    static readonly PACKAGE_PIN_CODE_CREATED = new PackageTrigger(
        PackageTriggerName.PACKAGE_PIN_CODE_CREATED,
        (
            <FormattedMessage
                id='packageTrigger.pin_code_created'
                description='package trigger pin code created'
                defaultMessage='PIN code created'
            />
        )
    );

    static readonly PACKAGE_CODE_SCANNED = new PackageTrigger(
        PackageTriggerName.PACKAGE_CODE_SCANNED,
        (
            <FormattedMessage
                id='packageTrigger.code_scanned'
                description='package trigger code scanned'
                defaultMessage='Code scanned'
            />
        )
    );

    static readonly PACKAGE_SLOT_OPENED = new PackageTrigger(
        PackageTriggerName.PACKAGE_SLOT_OPENED,
        (
            <FormattedMessage
                id='packageTrigger.slot_opened'
                description='package trigger slot opened'
                defaultMessage='SLOT opened'
            />
        )
    );

    static readonly PACKAGE_SLOT_CLOSED = new PackageTrigger(
        PackageTriggerName.PACKAGE_SLOT_CLOSED,
        (
            <FormattedMessage
                id='packageTrigger.slot_closed'
                description='package trigger slot closed'
                defaultMessage='SLOT closed'
            />
        )
    );

    static override readonly ALL = [
        PackageTrigger.PACKAGE_PROCESS,
        PackageTrigger.PACKAGE_STATE_CHANGED,
        PackageTrigger.PACKAGE_PIN_CODE_CREATED,
        PackageTrigger.PACKAGE_CODE_SCANNED,
        PackageTrigger.PACKAGE_SLOT_OPENED,
        PackageTrigger.PACKAGE_SLOT_CLOSED,
        ...super.ALL
    ];

    static override getByValue(trigger: PackageTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((a) => a.value == trigger);
    }
}
