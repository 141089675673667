import { Logger } from '@frontend/Logger';
import { DriverAuthenticationManager } from '@frontend/authentication-v2';
import { DeviceClient } from '@frontend/device/api';
import { DeviceType } from '@frontend/device/types';
import { IoTClient } from '@frontend/iot/api';
import { IoTBrand, IoTProvision, IoTType } from '@frontend/iot/types';
import { SettingClient } from '@frontend/setting/api';
import { initialiseNavigation, initialiseSettings, setDeviceInfo, setProvisioning } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../redux/store';
import { Props, ProvisionState } from './provision.component';

interface ViewProps {
    state: ProvisionState;
    message: string;
}

const useProvision = (props: Props): ViewProps => {
    const dispatch = useAppDispatch();
    const [authenticationManager] = useState<DriverAuthenticationManager>(DriverAuthenticationManager.getInstance('token'));
    const [state, changeState] = useState<ProvisionState>(ProvisionState.INIT);
    const [message, changeMessage] = useState<string>('Gathering info');
    const params = new URLSearchParams(window.location.search);
    const [provisionInfo, changeProvisionInfo] = useState<IoTProvision | null>(null);

    useEffect(() => {
        switch (state) {
            case ProvisionState.INIT:
                changeMessage('Gathering info');
                break;
            case ProvisionState.GATHERING_INFO_FAILED:
                changeMessage('Gathering info failed. Make sure the webview is configured correctly.');
                break;
            case ProvisionState.AUTHENTICATING:
                changeMessage('Authenticating');
                break;
            case ProvisionState.AUTHENTICATING_FAILED:
                changeMessage('Authentication failed, make sure to enable this terminal on the platform.');
                break;
            case ProvisionState.PERMISSION_CHECK:
                changeMessage('Provision check');
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                changeMessage(
                    'This device has not been provisioned correctly yet. Make sure it has been given the correct permissions and user interface has been linked.'
                );
                break;
            case ProvisionState.DONE:
                changeMessage('Provisioning done - Redirecting');
                break;
        }
    }, [state]);

    useEffect(() => {
        switch (state) {
            case ProvisionState.INIT:
                if (authenticationManager.loggedIn) {
                    changeProvisionInfo({
                        identifier: params.get('identifier')!,
                        type: IoTType.SERVER,
                        brand: IoTBrand.ANDROID
                    });
                    changeState(ProvisionState.PERMISSION_CHECK);
                } else changeState(ProvisionState.GATHERING_INFO);
                break;
            case ProvisionState.GATHERING_INFO:
                if (params.get('identifier') == null) changeState(ProvisionState.GATHERING_INFO_FAILED);
                else {
                    changeProvisionInfo({
                        identifier: params.get('identifier')!,
                        type: IoTType.SERVER,
                        brand: IoTBrand.ANDROID
                    });
                    changeState(ProvisionState.AUTHENTICATING);
                }
                break;
            case ProvisionState.AUTHENTICATING:
                authenticationManager
                    .authenticate(provisionInfo!)
                    .then(() => changeState(ProvisionState.PERMISSION_CHECK))
                    .catch(() => changeState(ProvisionState.AUTHENTICATING_FAILED));
                break;
            case ProvisionState.AUTHENTICATING_FAILED:
                setRetry(() => changeState(ProvisionState.AUTHENTICATING));
                break;
            case ProvisionState.PERMISSION_CHECK:
                try {
                    authenticationManager.waitForToken().then((token) => {
                        IoTClient.fetchIoT(token.account_id, token.entity_id).then((iot) => {
                            DeviceClient.fetchDevices({ iot_id: iot.id }).then((devices) => {
                                const found = devices.results.find((d) => d.type == DeviceType.TERMINAL);
                                if (found) {
                                    dispatch(setDeviceInfo({ iot, device: found }));
                                    SettingClient.fetchSettings({ entity_id: token.entity_id }).then((settings) => {
                                        dispatch(initialiseSettings(settings));
                                        const found = settings.results.find((s) => s.key == 'default_user_interface_id');
                                        if (found && found.value && typeof found.value == 'string') {
                                            dispatch(initialiseNavigation(found.value));
                                            changeState(ProvisionState.DONE);
                                        } else changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                                    });
                                } else changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                            });
                        });
                    });
                } catch (err) {
                    Logger.warn(err);
                    changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                }
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                setRetry(() => changeState(ProvisionState.PERMISSION_CHECK));
                break;
            case ProvisionState.DONE:
                props.provisionDone && props.provisionDone();
                break;
        }
    }, [state]);

    useEffect(() => {
        dispatch(setProvisioning(provisionInfo));
    }, [provisionInfo]);

    return { state, message };
};

export default useProvision;

function setRetry(callback: () => void) {
    const retry: NodeJS.Timeout = setTimeout(() => {
        callback();
        return clearTimeout(retry);
    }, 60000);
}
