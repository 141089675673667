import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreatePinCode, PinCode, PinCodeListResponse, PinCodeQueryParams } from '@frontend/package-pin/types';


const endpoint = '/package-api/v1';
export class PinCodeClient extends APIClient {
    public static async fetchPinCodes(queryParams?: ApiQueryParams<DefaultQueryParams | PinCodeQueryParams>): Promise<PinCodeListResponse> {
        return await this.apiPaginated<PinCodeListResponse, DefaultQueryParams | PinCodeQueryParams>(`${endpoint}/pin-codes`, queryParams);
    }

    public static async postPinCode(accountId: string, transactionId: string, packageId: string, pinCode: CreatePinCode): Promise<PinCode> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/pin-codes`, pinCode);
        return await this.handleResponse<PinCode>(response);
    }

    public static async fetchPinCode(accountId: string, transactionId: string, packageId: string, pinCodeId: string): Promise<PinCode> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/pin-codes/${pinCodeId}`);
        return await this.handleResponse<PinCode>(response);
    }

    public static async deletePinCode(accountId: string, transactionId: string, packageId: string, pinCodeId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}/pin-codes/${pinCodeId}`);
        return await this.handleVoidResponse(response);
    }
}
