import { ContactBadge } from '@frontend/contact/badge';
import React from 'react';

export interface WorkflowVariableBadgeProps {
    type: string;
    value: string | number | boolean;
    className?: string;
}

export const WorkflowVariableBadge = (props: WorkflowVariableBadgeProps) => {
    switch (props.type) {
        case 'contact_id':
            return <ContactBadge id={props.value as string} className={props.className} />;
        default:
            return <span>Not implemented yet</span>;
    }
};
