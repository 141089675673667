import { EntityType, useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PinCode } from '@frontend/package-pin/types';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageTriggerName } from '@frontend/package/types';
import { navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EnterPinProps } from './enter-pin.component';

interface ViewProps {
    state: PinWorkflowState;
    pinCodes: PinCode[];
    changePinCodes: React.Dispatch<React.SetStateAction<PinCode[]>>;
    _package: Package | null;
}

export enum PinWorkflowState {
    INIT = 'init',
    LOADING = 'loading',
    ENTER_PIN = 'enter_pin',
    ENTER_CHALLENGE = 'enter_challenge',
    DONE = 'done'
}
const useEnterPin = (props: EnterPinProps): ViewProps => {
    const iot = useAppSelector(useSelector, navigationStore).iot;
    const [pinCodes, changePinCodes] = useState<PinCode[]>([]);
    const [state, changeState] = useState<PinWorkflowState>(PinWorkflowState.INIT);
    const [_package, changePackage] = useState<Package | null>(null);

    useEffect(() => {
        changeState(PinWorkflowState.ENTER_PIN);
        if (iot) {
            IoTClient.patchIoT(iot.account_id, iot.id, { state: IoTStateName.IOT_PICK_UP_PACKAGE }).then((iot) => {
                props.dispatch(updateIoTState({ iot_state: iot.state }));
            });
        }
    }, []);

    useEffect(() => {
        if (pinCodes && pinCodes.length > 0) {
            const pin = pinCodes[0];
            if (pinCodes.length > 1) {
                changeState(PinWorkflowState.ENTER_CHALLENGE);
            } else {
                PackageWorkflowClient.triggerPackageState(pin.account_id, pin.transaction_id, pin.package_id, {
                    trigger: PackageTriggerName.PACKAGE_CODE_SCANNED,
                    source: {
                        entity_type: EntityType.IOT,
                        entity_id: iot?.id
                    }
                });
                changeState(PinWorkflowState.DONE);
                PackageClient.fetchPackage(pin.account_id, pin.transaction_id, pin.package_id).then(changePackage);
            }
        }
    }, [pinCodes]);

    return {
        state,
        pinCodes,
        changePinCodes,
        _package
    };
};

export default useEnterPin;
