import { Contact } from '@frontend/contact/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class ContactEventListener extends EventListener<Contact> {
    
    private static instance: ContactEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('contact', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): ContactEventListener {
        if (this.instance == null) {
            this.instance = new ContactEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Contact> {
        return ContactEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}