import { EntityType } from "@frontend/common";
import { useResolver } from "@frontend/repository";
import { SlotClient } from "@frontend/slot/api";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";

declare global {
    interface WindowEventMap {
        'useSlot:request': CustomEvent<string>;
    }
}

const REQUEST_EVENT = 'useSlot:request';
const STORAGE_KEY = 'SLTS_REQ';

export function slotRequest(slotId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_EVENT, { detail: slotId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function SlotResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_EVENT, EntityType.SLOT, (ids) => SlotClient.resolveSlots(ids), props.dispatch);
    return <></>;
}