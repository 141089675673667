import { ClassType, useAppSelector } from '@frontend/common';
import { UserInterfaceButtonAction, getColorByUIButtonType, getIconByUIButtonType } from '@frontend/user-interface-button/types';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { useSelector } from 'react-redux';

import { changeView, navigationStore } from '@frontend/terminal/shared';

import { IoTWorkflowClient } from '@frontend/iot/api';
import { UserInterfaceButtonElementProps } from './button-element.component';

interface ViewProps {
    onClick: () => void;
    icon: IconType | null;
    iconColor: ClassType | null;
}

const useUserInterfaceButtonElement = (props: UserInterfaceButtonElementProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const icon = useMemo(() => getIconByUIButtonType(props.button.type), [props.button.type]);
    const iconColor = useMemo(() => getColorByUIButtonType(props.button.type), [props.button.type]);

    const onClick = () => {
        switch (props.button.action) {
            case UserInterfaceButtonAction.NAVIGATION:
                props.dispatch(changeView({ view: props.button.next_user_interface_id!, cache: null }));
                break;
            case UserInterfaceButtonAction.TRIGGER:
                if (navigationState.iot && props.button.trigger) {
                    IoTWorkflowClient.postIoTTrigger(navigationState.iot.account_id, navigationState.iot.id, {
                        trigger: props.button.trigger,
                        source: undefined,
                    });
                }
                break;
            case UserInterfaceButtonAction.DISPLAY:
            default:
                break;
        }
    };

    return {
        onClick,
        icon,
        iconColor
    };
};

export default useUserInterfaceButtonElement;
