import { ClassType } from '@frontend/common';
import { Slot, SlotRendering } from '@frontend/slot/types';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import LayoutItem from './layout-item/layout-item.component';
import useLayoutRenderer from './layout-renderer.controller';

const ID = 'layout-renderer';
export interface LayoutRendererProps {
    scale?: number;
    slots: Slot[];
    slotRendering: SlotRendering[];
    containerInfo: { maxX: number; maxY: number; aspectRatio: number };
    slotClassMap?: Map<string, ClassType>;
    disabledSlots?: Slot[];
    onClick?: (slotId: string) => void;
    selectedSlot?: string;
}

const LayoutRenderer = (props: LayoutRendererProps) => {
    const viewProps = useLayoutRenderer(props);

    return (
        <div
            id={ID}
            ref={viewProps.layoutBox}>
            <TransformWrapper
                initialScale={props.scale !== undefined ? props.scale : viewProps.scale}
                minScale={viewProps.scale}
                centerOnInit>
                <TransformComponent wrapperClass='w-100 h-100'>
                    <div className='spotlayout-container'>
                        <div
                            style={viewProps.containerStyle}
                            className='spotlayout'>
                            {props.slotRendering.map((i, index) => {
                                return (
                                    <LayoutItem
                                        key={index + 'SPLitem'}
                                        slots={props.slots.filter((slot) => i.slot_ids.includes(slot.id))}
                                        slotRendering={i}
                                        fullHeight={props.containerInfo.maxY}
                                        fullWidth={props.containerInfo.maxX}
                                        slotClassMap={props.slotClassMap}
                                        onClick={() =>
                                            i.slot_ids.map((slot) =>
                                                props.disabledSlots?.map((s) => s.id)?.includes(slot) ? null : props.onClick && props.onClick(slot)
                                            )
                                        }
                                        selectedSlot={props.selectedSlot}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default LayoutRenderer;
