import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateProductAttribute, ProductAttribute, ProductAttributeListResponse, ProductAttributeQueryParams, UpdateProductAttribute } from '@frontend/product/types';

const endpoint = '/attribute-api/v1';
export class ProductAttributeClient extends APIClient {
    public static async fetchProductAttributes(queryParams?: ApiQueryParams<DefaultQueryParams | ProductAttributeQueryParams>): Promise<ProductAttributeListResponse> {
        return await this.apiPaginated<ProductAttributeListResponse, DefaultQueryParams>(`${endpoint}/attributes`, queryParams);
    }

    public static async resolveProductAttributes(ids: string[]): Promise<ProductAttributeListResponse> {
        const response = await this.post(`${endpoint}/product-attributes-resolve`, { ids: ids });
        return await this.handleResponse<ProductAttributeListResponse>(response);
    }

    public static async deleteProductAttributes(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/product-attributes-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountProductAttributes(accountId: string, productId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ProductAttributeListResponse> {
        return await this.apiPaginated<ProductAttributeListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/products/${productId}/product-attributes`, queryParams);
    }

    public static async postProductAttribute(accountId: string, productId: string, body: CreateProductAttribute): Promise<ProductAttribute> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/products/${productId}/product-attributes`, body);
        return await this.handleResponse<ProductAttribute>(response);
    }

    public static async fetchAccountProductAttribute(accountId: string, productId: string, productAttributeId: string): Promise<ProductAttribute> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/products/${productId}/product-attributes/${productAttributeId}`);
        return await this.handleResponse<ProductAttribute>(response);
    }

    public static async patchProductAttribute(accountId: string, productId: string, productAttributeId: string, body: UpdateProductAttribute): Promise<ProductAttribute> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/products/${productId}/product-attributes/${productAttributeId}`, body);
        return await this.handleResponse<ProductAttribute>(response);
    }

    public static async deleteProductAttribute(accountId: string, productId: string, productAttributeId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/products/${productId}/product-attributes/${productAttributeId}`);
        return await this.handleVoidResponse(response);
    }
}
