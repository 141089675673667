import { ClassType, EntityType, useAppSelector } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { IContact } from '@frontend/contact/types';
import { ErrorHandler } from '@frontend/error-handler';
import { ContactGroupClient } from '@frontend/group/api';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageClient } from '@frontend/package/api';
import { Package, PackageType } from '@frontend/package/types';
import { useDocumentRepository } from '@frontend/repository';
import { SlotClient, SlotWorkflowClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { SpotClient } from '@frontend/spot/api';
import { Spot } from '@frontend/spot/types';
import { navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { TransactionStateName, TransactionType } from '@frontend/transaction/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

export interface ViewProps {
    senders: ContactWithDocument[];
    selectedSender: ContactWithDocument | null;
    changeSelectedSender: Dispatch<SetStateAction<ContactWithDocument | null>>;
    contacts: IContact[];
    selectedContact: IContact | null;
    changeSelectedContact: Dispatch<SetStateAction<IContact | null>>;
    isLoading: boolean;
    slotList: Slot[];
    changeSlot: Dispatch<SetStateAction<string | null>>;
    slot: string | null;
    onDropOff: () => void;
    isDropping: boolean;
    dropPackage: Package | null;
    slotMap: Map<string, ClassType>;
    disabledSlots: Slot[];
    spot: Spot | null;
}

const useOpenDropoffCouriers = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const iot = useAppSelector(useSelector, navigationStore).iot;
    const documentRepository = useDocumentRepository();
    const [senders, changeSenders] = useState<ContactWithDocument[]>([]);
    const [selectedSender, changeSelectedSender] = useState<ContactWithDocument | null>(null);
    const [contacts, changeContacts] = useState<IContact[]>([]);
    const [selectedContact, changeSelectedContact] = useState<IContact | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);
    const [slot, changeSlot] = useState<string | null>(null);
    const [slotList, changeSlotList] = useState<Slot[]>([]);
    const [availableSlots, changeAvailableSlots] = useState<Slot[]>([]);
    const [disabledSlots, changeDisabledSlots] = useState<Slot[]>([]);
    const [foundSlot, changeFoundSlot] = useState<Slot | null>(null);
    const [isDropping, changeIsDropping] = useState<boolean>(false);
    const [dropPackage, changeDropPackage] = useState<Package | null>(null);
    const dropPackageWorkflow = props.userInterface.data.dropPackageWorkflowId;
    const pickPackageWorkflow = props.userInterface.data.pickPackageWorkflowId;
    const transactionWorkflow = props.userInterface.data.transactionWorkflowId;
    const senderGroup = props.userInterface.data.senderGroup;
    const receiverGroup = props.userInterface.data.receiverGroup;
    const [spot, changeSpot] = useState<Spot | null>(null);

    const slotMap = useMemo(() => {
        const map = new Map<string, ClassType>();
        availableSlots.map((slot) => map.set(slot.id, ClassType.PRIMARY));

        return map;
    }, [availableSlots]);

    useEffect(() => {
        if (iot && iot.spot_id) {
            SpotClient.fetchSpot(iot.account_id, iot.spot_id).then(changeSpot);
        }
    }, [iot]);

    useEffect(() => {
        if (iot) {
            ContactGroupClient.fetchContactGroup(iot.account_id, receiverGroup).then((group) => {
                ContactGroupClient.fetchContactGroupMembers({ group_id: group.id }).then((members) => {
                    const ids = members.results.map((member) => member.entity_id);
                    ContactClient.resolveContacts(ids).then((contacts) => {
                        changeContacts(contacts.results);
                    });
                });
            });
            ContactGroupClient.fetchContactGroup(iot.account_id, senderGroup)
                .then((group) => {
                    ContactGroupClient.fetchContactGroupMembers({ group_id: group.id }).then((members) => {
                        const ids = members.results.map((member) => member.entity_id);
                        ContactClient.resolveContacts(ids).then((contacts) => {
                            documentRepository
                                .preLoadFiles(
                                    EntityType.CONTACT,
                                    contacts.results.map((c) => c.id)
                                )
                                .then(() => {
                                    contacts.results.forEach((contact) => {
                                        documentRepository.resolveFile(EntityType.CONTACT, contact.id).then((service) => {
                                            if (service.getDocuments().length > 0) {
                                                service.getUrl(service.getDocuments()[0].id).then((res) => {
                                                    changeSenders((prev) => {
                                                        const foundSender = prev.find((s) => s.id === contact.id);
                                                        if (foundSender) {
                                                            return prev;
                                                        }
                                                        return [...prev, { ...contact, url: res }];
                                                    });
                                                    changeIsLoading(false);
                                                });
                                            }
                                        });
                                    });
                                });
                        });
                    });
                })
                .catch(ErrorHandler.handleError);
            IoTClient.patchIoT(iot.account_id, iot.id, { state: IoTStateName.IOT_OPEN_DROP_OFF_PACKAGE }).then((iot) => {
                props.dispatch(updateIoTState({ iot_state: iot.state }));
            });
            const spotId = navigationState.iot?.spot_id;
            if (!spotId) return;
            SpotClient.fetchSpot(iot.account_id, spotId as string).then((spot) => {
                SlotClient.fetchSlots({ spot_id: spot.id, index: '0', size: '100' }).then((slots) => {
                    SlotWorkflowClient.postAvailableSlots(
                        spot.account_id,
                        spot.id,
                        {
                            account_id: iot.account_id,
                            transaction_type: TransactionType.OPEN_DROP_OFF
                        },
                        { index: '0', size: '100', type: SlotType.DEFAULT }
                    ).then((availableSlots) => {
                        changeSlotList(slots.results);
                        const disabledSlots = slots.results.filter((slot) => !availableSlots.results.map((s) => s.id).includes(slot.id));
                        changeDisabledSlots(disabledSlots);
                        changeAvailableSlots(availableSlots.results);
                    });
                });
            });
        }
    }, []);

    useEffect(() => {
        if (slot) {
            const foundSlot = slotList.find((s) => s.id === slot);
            if (!foundSlot) return;
            changeFoundSlot(foundSlot);
        }
    }, [slot]);

    const onDropOff = () => {
        if (selectedSender && selectedContact && slot && iot) {
            if (!foundSlot) return;
            TransactionClient.postTransaction(iot.account_id, {
                type: TransactionType.OPEN_DROP_OFF,
                workflow_id: transactionWorkflow,
                iot_id: iot.id,
                state: TransactionStateName.TRANSACTION_CREATED
            }).then((transaction) => {
                const promisePackages = [];
                promisePackages.push(
                    PackageClient.postPackage(transaction.account_id, transaction.id, {
                        type: PackageType.DROP_OFF,
                        workflow_id: dropPackageWorkflow,
                        spot_id: foundSlot?.spot_id,
                        module_id: foundSlot?.module_id,
                        slot_id: slot,
                        priority: 1,
                        iot_id: iot.id
                    }).then((res) => {
                        changeDropPackage(res);
                    })
                );
                promisePackages.push(
                    PackageClient.postPackage(transaction.account_id, transaction.id, {
                        type: PackageType.PICK_UP,
                        workflow_id: pickPackageWorkflow,
                        spot_id: foundSlot?.spot_id,
                        module_id: foundSlot?.module_id,
                        slot_id: slot,
                        priority: 0
                    }).then((_package) => {
                        const variables = [];
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'from_entity_type',
                                value: EntityType.CONTACT,
                                type: 'entity_type',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'from_entity_id',
                                value: selectedSender.id,
                                type: 'entity_id',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'to_entity_type',
                                value: EntityType.CONTACT,
                                type: 'entity_type',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'to_entity_id',
                                value: selectedContact.id,
                                type: 'entity_id',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        Promise.all(variables);
                    })
                );

                Promise.all(promisePackages).then(() => {
                    TransactionWorkflowClient.updateTransactionState(transaction.account_id, transaction.id, TransactionStateName.TRANSACTION_PROCESSING);
                    changeIsDropping(true);
                });
            });
        }
    };

    return {
        changeSelectedSender,
        senders: senders.sort((a, b) => a.first_name.localeCompare(b.first_name)),
        selectedSender,
        changeSelectedContact,
        contacts,
        selectedContact,
        isLoading,
        slotList,
        changeSlot,
        slot,
        onDropOff,
        isDropping,
        dropPackage,
        slotMap,
        disabledSlots,
        spot
    };
};

export default useOpenDropoffCouriers;

interface ContactWithDocument extends IContact {
    url: string;
}
