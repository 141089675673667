import { PaginatedResponse } from '@frontend/api-utils';

import { IoTStateName } from './iot-state';

export interface IoT {
    name: string;
    type: IoTType;
    brand: IoTBrand;
    status: string | null;
    workflow_id: string | null;
    heartbeat_timestamp: string | null;
    update_timestamp: string;
    id: string;
    account_id: string;
    creation_timestamp: string;
    state: IoTStateName;
    twin_iot_id: string | null;

    display: string | null;
    domain: string | null;
    spot_id: string | null;
    default_user_interface_id: string | null;
}

export interface CreateIoT {
    name?: string;
    type?: IoTType;
    brand?: IoTBrand;
    status?: string | null;
    heartbeat_timestamp?: string | null;
    workflow_id?: string | null;
    state?: IoTStateName | null;
    twin_iot_id?: string | null;
}

export interface UpdateIoT {
    name?: string;
    type?: IoTType;
    brand?: IoTBrand;
    status?: string | null;
    heartbeat_timestamp?: string | null;
    workflow_id?: string | null;
    state?: IoTStateName | null;
    twin_iot_id?: string | null;
}

export interface UpdateIoTSettings extends UpdateIoT {
    display?: string | null;
    domain?: string | null;
    spot_id?: string | null;
    default_user_interface_id?: string | null;
}

export type IoTListResponse = PaginatedResponse<IoT>;

export enum IoTType {
    ACCESS_CONTROLLER = 'access_controller',
    MOBILE = 'mobile',
    SERVER = 'server'
}

export enum IoTBrand {
    ANDROID = 'android',
    ONLOGIC = 'onlogic',
    RAKINDA = 'rakinda',
    TELLOPORT = 'telloport'
}

export enum IoTQueryParams {
    ACCOUNT_ID = 'account_id',
    IDENTIFIER = 'identifier',
    TYPE = 'type',
    SPOT_ID = 'spot_id',
    WORKFLOW_ID = 'workflow_id'
}

export interface UpdateIoTState {
    state?: IoTStateName;
}
