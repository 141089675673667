import { useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useOnDutyCustomer = () => {
    const navigationState = useAppSelector(useSelector, navigationStore);

    useEffect(() => {
        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_CUSTOMER });
        }
    });
};

export default useOnDutyCustomer;
