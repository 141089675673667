import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { UserInterfaceClient } from '@frontend/user-interface/api';

declare global {
    interface WindowEventMap {
        'useUserInterface:request': CustomEvent<string>;
    }
}

const REQUEST_USER_INTERFACE_EVENT = 'useUserInterface:request';
const STORAGE_KEY = 'UI_REQ';

export function userInterfaceRequest(userInterfaceId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_INTERFACE_EVENT, { detail: userInterfaceId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function UserInterfaceResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_USER_INTERFACE_EVENT, EntityType.USER_INTERFACE, (ids) => UserInterfaceClient.resolveUserInterfaces(ids), props.dispatch);
    return <></>;
}