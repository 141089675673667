import { ClassType } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { Module } from '@frontend/module/types';
import { Slot } from '@frontend/slot/types';
import { Spot } from '@frontend/spot/types';
import React from 'react';

import LayoutRenderer from './layout-renderer/layout-renderer.component';
import useLayout from './layout.controller';

const ID = 'layout';

/**
 * Provide either spot or modules or slots.
 */
export interface LayoutProps {
    /** @description adjust the scale according to the height and width you want you element to have (w-50 -> scale: 0.5) */
    scale?: number;
    spot?: Spot;
    modules?: Module[];
    slots?: Slot[];
    slotClassMap?: Map<string, ClassType>;
    disabledSlots?: Slot[];
    onClick?: (slotId: string) => void;
    selectedSlot?: string;
}

export const Layout = (props: LayoutProps) => {
    const viewProps = useLayout(props);

    return (
        <div id={ID}>
            {viewProps.slots === null ? (
                <Spinner />
            ) : viewProps.slots.length === 0 ? (
                <span>No slots found.</span>
            ) : viewProps.slotRendering === null ? (
                <Spinner />
            ) : (
                <LayoutRenderer
                    scale={props.scale}
                    slots={viewProps.slots}
                    slotRendering={viewProps.slotRendering}
                    containerInfo={viewProps.containerInfo}
                    slotClassMap={props.slotClassMap}
                    disabledSlots={props.disabledSlots}
                    onClick={props.onClick}
                    selectedSlot={props.selectedSlot}
                />
            )}
        </div>
    );
};

export default Layout;
