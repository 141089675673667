import { useAppSelector } from '@frontend/common';
import { navigationStore } from '@frontend/terminal/shared';
import { UserClient } from '@frontend/user/api';
import { User } from '@frontend/user/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    user: User | null;
    spotTitle: string | null;
}

const useDefaultTitle = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [user, changeUser] = useState<User | null>(null);
    const [spotTitle, changeSpotTitle] = useState<string | null>(null);

    useEffect(() => {
        const title = navigationState.settings?.results.find((s) => s.key === 'name')?.value;
        if (title && typeof title === 'string') changeSpotTitle(title);
    }, []);

    useEffect(() => {
        if (!navigationState.user) {
            changeUser(null);
        }
        if (navigationState.user) UserClient.fetchUser(navigationState.user.accountId, navigationState.user.id).then(changeUser);
    }, [navigationState.user]);

    return {
        user,
        spotTitle
    };
};

export default useDefaultTitle;
