import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateIoT, IoT, IoTListResponse, UpdateIoT } from '@frontend/iot/types';

const endpoint = '/iot-api/v1';
export class IoTClient extends APIClient {
    public static async fetchIoTs(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<IoTListResponse> {
        return this.apiPaginated<IoTListResponse, DefaultQueryParams>(`${endpoint}/iots`, queryParams);
    }

    public static async resolveIoTs(ids: string[]): Promise<IoTListResponse> {
        const response = await this.post(`${endpoint}/iots-resolve`, { ids: ids });
        return await this.handleResponse<IoTListResponse>(response);
    }

    public static async deleteIoTs(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/iots-delete`, { ids: ids });
        return await this.handleResponse<void>(response);
    }

    public static async postIoT(accountId: string, body: CreateIoT): Promise<IoT> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots`, body);
        return await this.handleResponse<IoT>(response);
    }

    public static async fetchIoT(accountId: string, iotId: string): Promise<IoT> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/iots/${iotId}`);
        return await this.handleResponse<IoT>(response);
    }

    public static async patchIoT(accountId: string, iotId: string, body: UpdateIoT): Promise<IoT> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/iots/${iotId}`, body);
        return await this.handleResponse<IoT>(response);
    }

    public static async deleteIoT(accountId: string, iotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/iots/${iotId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postHeartbeat(accountId: string, iotId: string): Promise<IoT> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/heartbeat`);
        return await this.handleResponse<IoT>(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
