import { Dimension } from '@frontend/common';
import { Slot } from '@frontend/slot/types';
import { Euler, MeshProps, Vector3 } from '@react-three/fiber';
import { useMemo } from 'react';

import { ModuleItemProps } from './module-item.component';

interface ViewProps {
    displayName: string;
    highlighted: boolean;
    disabled: boolean;
    mesh: MeshProps;
    dimension: [width?: number | undefined, height?: number | undefined, depth?: number | undefined];
    scale: Vector3;
    position?: Vector3;
    rotation: Euler;
}

const useModuleItem = (props: ModuleItemProps): ViewProps => {
    const scale: Vector3 = useMemo(() => {
        return [1, 1, 1];
    }, [props.moduleRotation]);
    const mesh: MeshProps = useMemo(() => {
        return { position: calcPosition(props.slot, props.moduleRotation), uuid: props.slot.id };
    }, [props.slot, props.moduleRotation]);
    const position: Vector3 = useMemo(() => {
        return [(mesh.position! as number[])[0], (mesh.position! as number[])[1], props.moduleRotation != 0 ? 0.1 : 0.55];
    }, [mesh, props.moduleRotation]);

    return {
        displayName: props.slot.display || props.slot.number || '',
        highlighted: props.highlighted?.includes(props.slot.id) || false,
        disabled: props.disabled?.includes(props.slot.id) || false,
        mesh,
        scale,
        position,
        dimension: dimension(props.slot.dimension!, 3),
        rotation: props.moduleRotation != 0 ? [0, Math.PI / -1, 0] : [0, 0, 0]
    };
};

export default useModuleItem;

function dimension(dimension: Dimension, margin: number): [width?: number | undefined, height?: number | undefined, depth?: number | undefined] {
    const width = dimension.width / 1000 - (dimension.width / 1000 / 100) * margin;
    const height = dimension.height / 1000 - (dimension.height / 1000 / 100) * margin;
    const depth = dimension.depth / 1000 - (dimension.depth / 1000 / 100) * margin;
    return [width, height, depth];
}

const calcPosition = (slot: Slot, rotation: number): Vector3 | undefined => {
    if (!slot.dimension || !slot.location) return undefined;
    if (rotation != 0) {
        return [
            slot.location.x / 1000 - slot.dimension.width / 1000 / 2,
            slot.location.y / 1000 + slot.dimension.height / 1000 / 2,
            slot.location.z / 1000 - slot.dimension.depth / 1000 / 2
        ];
    }
    return [
        slot.location.x / 1000 - slot.dimension.width / 1000 / 2,
        slot.location.y / 1000 + slot.dimension.height / 1000 / 2,
        slot.location.z / 1000 + slot.dimension.depth / 1000 / 2
    ];
};
