import { useAppSelector } from '@frontend/common';
import { IoTStateName } from '@frontend/iot/types';
import { changeView, clearCommandHistory, navigationStore, updateIoTState } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    redirectUI: string | null;
    countdown: number | null;
}

const usePackageProcessed = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [redirectUI, changeRedirectUI] = useState<string | null>(null);
    const [countdown, setCountdown] = useState<number | null>(null);
    const state = props.userInterface.data.iot_state;
    const terminalHome = navigationState.settings?.results.find((s) => s.key === 'default_user_interface_id')?.value_type;

    useEffect(() => {
        if (navigationState.cache && navigationState.cache.data.user_interface_id == props.userInterface.id) {
            if (navigationState.cache.data.redirect_user_interface_id) {
                changeRedirectUI(navigationState.cache.data.redirect_user_interface_id);
                setCountdown(3);
            }
        }
    }, []);

    useEffect(() => {
        if (redirectUI && countdown !== null) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown && prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(timer);
                        props.dispatch(changeView({ view: redirectUI, cache: null }));
                        props.dispatch(clearCommandHistory());
                        if (state && terminalHome && redirectUI === terminalHome) {
                            props.dispatch(updateIoTState({ iot_state: IoTStateName.IOT_HOME }));
                        }
                        return 0;
                    }
                });
            }, 1000);

            return () => clearInterval(timer);
        } else return;
    }, [redirectUI, countdown]);

    return { redirectUI, countdown };
};

export default usePackageProcessed;
