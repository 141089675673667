import { PaginatedResponse } from '@frontend/api-utils';
import { IoTTriggerName } from '@frontend/iot/types';

import { UserInterfaceButtonType } from './user-interface-button-type';

export interface UserInterfaceButton {
    next_user_interface_id: string | null;
    name: string;
    name_nl_be: string | null;
    name_en_us: string | null;
    name_fr_fr: string | null;
    name_de_de: string | null;
    type: UserInterfaceButtonType;
    default: boolean;
    order: number;
    action: UserInterfaceButtonAction;
    trigger: IoTTriggerName | null;
    id: string;
    account_id: string;
    user_interface_id: string;
    creation_timestamp: string;
}

export type UserInterfaceButtonListResponse = PaginatedResponse<UserInterfaceButton>;

export interface CreateUserInterfaceButton {
    next_user_interface_id?: string | null;
    name?: string;
    name_nl_be?: string | null;
    name_en_us?: string | null;
    name_fr_fr?: string | null;
    name_de_de?: string | null;
    order?: number;
    action?: UserInterfaceButtonAction;
    type?: UserInterfaceButtonType;
    default?: boolean;
    trigger?: IoTTriggerName;
}

export interface UpdateUserInterfaceButton {
    next_user_interface_id?: string | null;
    name?: string | null;
    name_nl_be?: string | null;
    name_en_us?: string | null;
    name_fr_fr?: string | null;
    name_de_de?: string | null;
    order?: number | null;
    type?: UserInterfaceButtonType | null;
    default?: boolean;
    trigger?: IoTTriggerName | null;
    action?: UserInterfaceButtonAction;
}
export enum UserInterfaceButtonAction {
    DISPLAY = 'display',
    NAVIGATION = 'navigation',
    TRIGGER = 'trigger'
}

export enum UserInterfaceButtonQueryParams {
    ACCOUNT_ID = 'account_id',
    USER_INTERFACE_ID = 'user_interface_id'
}
