import { Logger } from '@frontend/Logger';
import { DriverAuthenticationClient, DriverAuthenticationManager } from '@frontend/authentication-v2';
import { EntityType } from '@frontend/common';
import { DeviceEventListener } from '@frontend/device/events';
import { IoTClient, IoTWorkflowClient } from '@frontend/iot/api';
import { IoTEventListener } from '@frontend/iot/events';
import { IoTStateName } from '@frontend/iot/types';
import { changeView, endUserSession, setUserHome, startUserSession, updateIoTState } from '@frontend/terminal/shared';
import { UserEventListener } from '@frontend/user/events';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';
import { useHeartbeat } from '../useHeartbeat';

interface ViewProps {
    isLoading: boolean;
    view: string | null;
    idleTimerEnabled: boolean;
    idleTimerCallback: () => void;
}

const useHomePage = (): ViewProps => {
    useHeartbeat();
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);

    const callback = (event: MessageEvent<string>) => {
        Logger.log('Terminal received event: ', {}, event);
        const payload = JSON.parse(event.data) as { message: string; data: any };
        if (payload.message === 'show_user_interface') {
            const data = payload.data as WorkflowStepTriggeredEvent;
            if (data.data.entity_type === EntityType.USER && data.data.entity_id) {
                Logger.log('User id provided in last event: ', {}, data.data.entity_id);
                if (navigationState.user?.id !== data.data.entity_id && navigationState.provision) {
                    Logger.log('Authorizing user: ', {}, data.data.entity_id, navigationState.provision, navigationState.cache);
                    DriverAuthenticationManager.getInstance()
                        .waitForToken()
                        .then((token) => {
                            DriverAuthenticationClient.authorizeUser(data.account_id, navigationState.iot!.id, data.data.entity_id!, token)
                                .then((result) => {
                                    dispatch(startUserSession({ id: data.data.entity_id!, accountId: data.account_id, auth: result }));
                                    dispatch(setUserHome(data.data.user_interface_id));
                                })
                                .then(() => {
                                    dispatch(changeView({ view: data.data.user_interface_id, cache: data }));
                                });
                        });
                    return;
                }
            }
            Logger.log('No authorization possible', {}, data, navigationState);
            dispatch(changeView({ view: data.data.user_interface_id, cache: data }));
        }
        if (payload.message === 'notify') {
            const data = payload.data as WorkflowStepTriggeredEvent;
            if (data.data.trigger) {
                IoTWorkflowClient.postIoTTrigger(data.account_id, data.iot_id, {
                    trigger: data.data.trigger
                });
            }
        }

        if (payload.message === 'iot_updated') {
            if (navigationState.iot && navigationState.iot.id === payload.data.id) {
                dispatch(updateIoTState({ iot_state: payload.data.state }));
            }
        }
    };

    useEffect(() => {
        UserEventListener.getInstance(dispatch).addCallback('HOMEPAGE_USER_LISTENER', callback);
        IoTEventListener.getInstance(dispatch).addCallback('HOMEPAGE_IOT_LISTENER', callback);
        return () => {
            UserEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_USER_LISTENER');
            IoTEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_IOT_LISTENER');
        };
    }, []);

    useEffect(() => {
        if (navigationState.device?.id) {
            DeviceEventListener.getInstance(dispatch, [{ device_entity_id: navigationState.device?.id }]).addCallback('HOMEPAGE_DEVICE_LISTENER', callback);
        }
        return () => {
            DeviceEventListener.getInstance(dispatch).removeCallback('HOMEPAGE_DEVICE_LISTENER');
        };
    }, [navigationState]);

    useEffect(() => {
        if (navigationState.iot && navigationState.current == null)
            IoTClient.patchIoT(navigationState.iot!.account_id, navigationState.iot!.id, { state: IoTStateName.IOT_HOME });
    }, [navigationState.current]);

    const idleTimerCallback = () => {
        dispatch(endUserSession());
        dispatch(changeView(null));
    };

    return {
        isLoading: (navigationState.current || navigationState.root) == null,
        view: navigationState.current || navigationState.root,
        idleTimerEnabled: navigationState.user != null || navigationState.current != null,
        idleTimerCallback
    };
};

export default useHomePage;
